import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { getClientById } from '../../services/clients';
import { useEffect } from 'react';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { displayAddress } from '../../utils/helper';
import FormInput from '../../components/forms/formInput';
import CountrySelect from '../../components/selects/countrySelect';
import OverlayLoader from '../../components/overlayLoader';

const ClientInfoModal = ({ display, toggle, clientId }) => {

    const [client, setClient] = useState({})

    const { data, isFetching } = useQuery(['clients', 'details', clientId], () => clientId && getClientById(clientId))

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setClient(result)
        })
    }, [data, clientId])

    const onShow = () => {
    }

    const handleChange = (objValue) => {
        const newClient = { ...client, ...objValue }
        setClient(newClient)
    }

    const handleSelectChange = (prop) => (date) => {
        handleChange({ ...client, [prop]: date })
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            onShow={onShow}
            backdropClassName="level-2"
            size="lg"
            centered
        >
            <Modal.Body>
                <h4 className="text-center">Client Information</h4>
                <hr />
                {/* <div className="my-4">
                    <h5 className="mb-0">{client.firstName} {client.lastName}</h5>
                    <p className="mb-2">{displayAddress(client)}</p>
                    <p className="mb-0"><strong>Email:</strong> {client.email || 'N/A'}</p>
                    <p className="mb-0"><strong>Phone:</strong> {client.phone}</p>
                </div> */

                    <Form id='client-info' className="mb-4" style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="First Name" value={client.firstName ?? ''}
                                    disabled onChange={(e) => handleChange({ firstName: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="Last Name" value={client.lastName ?? ''}
                                    disabled onChange={(e) => handleChange({ lastName: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="Phone Number" value={client.phone ?? ''}
                                    disabled onChange={(e) => handleChange({ phone: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput type="email" className="form-control mb-2" label="Email" value={client.email ?? ''}
                                    disabled onChange={(e) => handleChange({ email: e.target.value })}
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Apartment No." value={client.apartmentNo ?? ''}
                                    disabled onChange={(e) => handleChange({ apartmentNo: e.target.value })}
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Floor" value={client.floor ?? ''}
                                    disabled onChange={(e) => handleChange({ floor: e.target.value })} type="number"
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Intercom Name" value={client.intercomName ?? ''}
                                    disabled onChange={(e) => handleChange({ intercomName: e.target.value })}
                                />
                            </Col>
                            <Col lg="8">
                                <FormInput className="form-control mb-2" label="Street" value={client.street ?? ''}
                                   disabled onChange={(e) => handleChange({ street: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="City" value={client.city ?? ''}
                                    disabled onChange={(e) => handleChange({ city: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="State" value={client.state ?? ''}
                                    disabled onChange={(e) => handleChange({ state: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Zip" value={client.zipCode ?? ''}
                                    disabled onChange={(e) => handleChange({ zipCode: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <CountrySelect className="mb-2" label="Country" value={client.country ?? ''} isDisabled 
                                    onChange={handleSelectChange('country')}
                                    placeholder='Select Country'
                                    required
                                />
                            </Col>

                            <Col lg="12">
                                <FormInput className="form-control mb-2" label="Notes" value={client.notes ?? ''}
                                    disabled onChange={(e) => handleChange({ notes: e.target.value })}
                                    as="textarea"
                                />
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h5 className="mb-0">Total SQM: {client.totalSQM}</h5>
                            </div>
                        </div>

                        {isFetching &&
                            <OverlayLoader />
                        }

                    </Form>

                }
            </Modal.Body>
        </Modal>
    );
};

export default ClientInfoModal;