import React from 'react';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import SetPayRollModal from './payrollSet';
import { deletePayroll, getPaginatedPayrolls } from '../../services/payrolls';
import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import SortableTable from "reactstrap-sortable-table";
import Pages from '../../components/pages';
import OverlayLoader from '../../components/overlayLoader';
import { initColumns, rsdFormat, toLocalDateString } from '../../utils/helper';
import ConfirmationModal from '../../components/modals/confirmation';
import PayrollsFilter from './payrollsFilter';

const PayrollsPage = () => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [setPayrollModal, setSetPayrollModal] = useState(false)

    const [payrolls, setPayrolls] = useState([])
    const [selectedPayroll, setSelectedPayroll] = useState()

    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [filters, setFilters] = useState(['', '', '', ''])

    const { data, isFetching } = useQuery(
        ['payrolls', 'paginated', pageNumber, pageSize, ...filters],
        () => getPaginatedPayrolls(pageNumber, pageSize, ...filters)
    )

    const queryClient = useQueryClient()
    const { mutate, isLoading: isDeleting } = useMutation(deletePayroll, {
        onSuccess: onMutateSuccess((result, id) => {
            if (payrolls.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1)
            }
            queryClient.invalidateQueries(['payrolls', 'paginated'])
        })//, ' Successfully deleted an expense ')
    })

    const setPayrollsModified = (data) => {
        setPayrolls(data.map(x => {
            x.amount = parseFloat(x.amount)
            x.bonus = parseFloat(x.bonus)
            x.deduction = parseFloat(x.deduction)
            x.totalSalary = parseFloat(x.totalSalary)
            return x
        }))
    }

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setPagination(result)
            setPayrollsModified(result.data)
        })
    }, [data])

    const handleSetPayrollModal = (payroll) => {
        setSelectedPayroll(payroll)
        setSetPayrollModal(true)
    }

    const handleConfirmationModal = (payroll) => {
        setSelectedPayroll(payroll)
        setConfirmationModal(true)
    }

    const handleDeletePayroll = () => {
        mutate(selectedPayroll.id)
    }

    return (
        <>
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleDeletePayroll}
                title="Delete Payroll" message="Are you sure you want to delete this payroll?" />
            <SetPayRollModal display={setPayrollModal} toggle={setSetPayrollModal} payrollId={selectedPayroll?.id} />

            <Row>
                <Col>
                    <PayrollsFilter onFilter={setFilters} />

                    <Card className="mt-3 mb-4">
                        <Card.Body>
                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Payroll</h4>
                                </Col>
                                <Col className="text-end">
                                    <div>
                                        <Button onClick={() => handleSetPayrollModal()}><i className="fa-solid fa-plus"></i> Add</Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {payrolls.length > 0 ?
                                            <>
                                                <div>
                                                    <SortableTable
                                                        style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                        responsive
                                                        size="sm"
                                                        data={payrolls}
                                                        setData={setPayrolls}
                                                        columns={initColumns(payrolls, ['id', 'notes', 'companyId', 'employeeId', 'createdById', 'updatedById', 'createdAt', 'updatedAt'])}
                                                        columnRender={[
                                                            { column: 'payStartDate', render: (value) => toLocalDateString(value, 'dd.MM.yyyy') },
                                                            { column: 'payEndDate', render: (value) => toLocalDateString(value, 'dd.MM.yyyy') },
                                                            { column: 'rateType', render: (value) => value.replace('_', ' ') },
                                                            { column: 'amount', render: (value) => rsdFormat(value) },
                                                            { column: 'bonus', render: (value) => rsdFormat(value) },
                                                            { column: 'deduction', render: (value) => rsdFormat(value) },
                                                            { column: 'salary', render: (value) => rsdFormat(value) }
                                                        ]}
                                                        firstColumnRender={(payroll) => <>
                                                            <i className="fa-solid fa-pencil fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleSetPayrollModal(payroll)}></i>
                                                            <i className="fa-solid fa-trash fa-sm" style={{ cursor: 'pointer' }} onClick={() => handleConfirmationModal(payroll)}></i>
                                                        </>}
                                                        withTotalColumns={['amount','bonus','deduction','totalSalary']}
                                                        totalRender={(value) => <strong>{rsdFormat(value)}</strong>}
                                                        addProps={{
                                                            tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                            tData: { className: 'py-2' }
                                                        }}
                                                    />
                                                </div>
                                            </> 
                                            :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no payrolls found</span>
                                            </>
                                        }
                                        { pagination?.totalPages > 0 &&
                                            <Pages paginationData={pagination} changePage={setPageNumber} pageNumber={pageNumber}/>
                                        }

                                        {(isFetching) &&
                                            <OverlayLoader />
                                        }
                                    </div>

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PayrollsPage;