import { useEffect, useState } from "react";
import { Card, Col, Collapse, Form, Row, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import SortableTable from "reactstrap-sortable-table";
import SetTempEditOrderModal from "../../../components/modals/orderEditTemp";
import OverlayLoader from "../../../components/overlayLoader";
import { getServicesReports } from "../../../services/services";
import { onQuerySuccess } from "../../../utils/queryResponse";
import ServicesReportsFilter from "./servicesReportsFilter";
import { initColumns, rsdFormat } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

const ServicesReportsPage = () => {

    const [editJobModal, setEditJobModal] = useState(false)

    const [isGroupedByCategory, setIsGroupedByCategory] = useState(false)

    const [reports, setReports] = useState([])
    const [groupedReports, setGroupedReports] = useState([])
    const [selectedJobId, setSelectedJobId] = useState('')

    const [filters, setFilters] = useState(['', ''])

    let navigate = useNavigate()

    const { data, isFetching } = useQuery(
        ['services-reports', ...filters],
        () => getServicesReports(...filters)
    )

    useEffect(() => {
        onQuerySuccess(data, (result) => {

            let _groupedReports = result.reduce((group, report) => {
                const { category } = report;
                group[category] = group[category] ?? [];
                group[category].push(report);
                return group;
            }, {});

            let _groupedReportsArray = []
            for (const [key, value] of Object.entries(_groupedReports)) {
                let entry = {
                    categoryName: key,
                    categoryEntries: value
                }
                _groupedReportsArray.push(entry)
            }

            setReports(result)
            setGroupedReports(_groupedReportsArray)
        })

    }, [data])

    const getEntriesColumnSum = (entries, columnName) => {
        return entries.reduce((a, b) => a + parseFloat(b[columnName]), 0)
    }

    const getGroupedTotalCompletedItems = (categoryEntries) => {
        var _groupedTotalCompletedItems = categoryEntries.map(x => x.totalCompletedItems.split(' ')[1]).filter((value, index, array) => array.indexOf(value) === index)
       
        _groupedTotalCompletedItems.forEach((x, index) => {
            let total = 0
            categoryEntries.forEach(y => {
                if (x === y.totalCompletedItems.split(' ')[1])
                    total += parseFloat(y.totalCompletedItems.split(' ')[0])
            })
            _groupedTotalCompletedItems[index] = total + ' ' + _groupedTotalCompletedItems[index]
        });

        return _groupedTotalCompletedItems
    }

    return (
        <>
            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJobId} />

            <Row>
                <Col>
                    <ServicesReportsFilter onFilter={setFilters} />

                    <div className="d-flex justify-content-end">
                        <Form.Check className="mt-3"
                            value={isGroupedByCategory}
                            type={'checkbox'}
                            id={`group-by-category-toggle}`}
                            label={`Group by Category`}
                            onChange={(e) => setIsGroupedByCategory(!isGroupedByCategory)}
                        />
                    </div>

                    <Card className="mt-2 mb-4">
                        <Card.Body>

                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Services Reports</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>


                                        {reports.length > 0 ?
                                            <>
                                                <Collapse in={!isGroupedByCategory}>
                                                    <div className="mb-3">
                                                        <SortableTable
                                                            style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                            responsive
                                                            size="sm"
                                                            data={reports}
                                                            setData={setReports}
                                                            columns={initColumns(reports, ['serviceId'])}
                                                            columnRender={[
                                                                { column: 'totalSales', render: (value) => rsdFormat(value) },
                                                            ]}
                                                            firstColumnRender={(service) =>
                                                                <div className="mt-1">
                                                                    <i className="fa-solid fa-eye fa-sm me-1" title="view job history" style={{ cursor: 'pointer' }} onClick={() => navigate(`/reports/jobs-history/?serviceId=${service.serviceId}`)}></i>
                                                                </div>}
                                                            addProps={{
                                                                tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                                tData: { className: 'py-2' }
                                                            }}
                                                        />
                                                    </div>
                                                </Collapse>

                                                <Collapse in={isGroupedByCategory}>
                                                    <div>
                                                        <Table responsive style={{ borderTop: '2px solid var(--color-secondary)' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th className="text-muted">Category</th>
                                                                    <th className="text-muted">Service</th>
                                                                    <th className="text-muted">Total Jobs</th>
                                                                    <th className="text-muted">Completed Jobs</th>
                                                                    <th className="text-muted">Total Sales</th>
                                                                    <th className="text-muted">Total Completed Items</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {groupedReports.map((report, reportIndex) => (
                                                                    <tr key={`groupedreport-${reportIndex}`}>
                                                                        <td></td>
                                                                        <td>{report.categoryName}</td>
                                                                        <td>
                                                                            {report.categoryEntries.map((entry, entryIndex) => (
                                                                                <p key={`report-${reportIndex}-entry-${entryIndex}`} className="mb-0">{entry.service}</p>
                                                                            ))}
                                                                        </td>
                                                                        <td>{getEntriesColumnSum(report.categoryEntries, 'totalJobs')}</td>
                                                                        <td>{getEntriesColumnSum(report.categoryEntries, 'completedJobs')}</td>
                                                                        <td>{rsdFormat(getEntriesColumnSum(report.categoryEntries, 'totalSales'))}</td>
                                                                        <td>
                                                                            {/* {report.categoryEntries.map((entry, entryIndex) => (
                                                                                <p key={`report-${reportIndex}-entry-${entryIndex}`} className="mb-0">{entry.totalCompletedItems}</p>
                                                                            ))} */}
                                                                            {getGroupedTotalCompletedItems(report.categoryEntries).map((entry, entryIndex) => (
                                                                                <p key={`report-${reportIndex}-entry-${entryIndex}`} className="mb-0">{entry}</p>
                                                                            ))}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Collapse>
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no reports found</span>
                                            </>
                                        }


                                        {(isFetching) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ServicesReportsPage;