import React, { useEffect, useState, useRef } from 'react';
import { Card, Col, Row, Button, Form, Dropdown } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { initColumns, debounce, rsdFormat } from '../../utils/helper';
import SortableTable from "reactstrap-sortable-table";
import FormInput from "../../components/forms/formInput";
import Pages from '../../components/pages';
import SetClientModal from './clientsSet';
import { deleteClient, getPaginatedClients } from '../../services/clients';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSheetExport } from "../../utils/queryHooks/sheetExport";
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import OverlayLoader from '../../components/overlayLoader';
import ConfirmationModal from '../../components/modals/confirmation';
import SetTempEditOrderModal from '../../components/modals/orderEditTemp';

const ClientsPage = () => {

    const [setClientModal, setSetClientModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [editJobModal, setEditJobModal] = useState(false)

    const [clients, setClients] = useState([])
    const [clientToEdit, setClientToEdit] = useState()
    const [clientToDelete, setClientToDelete] = useState()
    const [selectedJobId, setSelectedJobId] = useState()

    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)

    const printRef = useRef()
    const { exportSheet: exportExcel, isExporting } = useSheetExport("Clients",
        clients.map(({ id, ...keepAttrs }) => keepAttrs),
        ["Name", "Phone", "Address", "Email", "Notes", "Latest Job", "Total Jobs"])
    const { exportSheet: exportCSV, isExportingCSV } = useSheetExport("Clients",
        clients.map(({ id, ...keepAttrs }) => keepAttrs),
        ["Name", "Phone", "Address", "Email", "Notes", "Latest Job", "Total Jobs"], "csv")

    const { data, isFetching } = useQuery(
        ['clients', 'paginated', pageNumber, pageSize, searchText],
        () => getPaginatedClients(pageNumber, pageSize, searchText)
    )

    const queryClient = useQueryClient()
    const { mutate, isLoading: isDeleting } = useMutation(deleteClient, {
        onSuccess: onMutateSuccess(() => {
            if (clients.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1)
            }
            queryClient.invalidateQueries(['clients', 'paginated'])
        }, ' Successfully deleted a client ')
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setPagination(result)

            setClients(result.data)
        })
    }, [data])

    const handleDeleteClient = () => {
        mutate(clientToDelete.id)
    }

    const handleShowSetClientModal = (client) => {
        setClientToEdit(client)
        setSetClientModal(true)
    }

    const handleShowConfirmation = (client) => {
        setConfirmationModal(true)
        setClientToDelete(client)
    }

    const handleSearchText = debounce((e) => {
        setSearchText(e.target.value)
    })

    const handleSetPageSize = (size) => {
        setPageNumber(1)
        setPageSize(size)
    }

    const handleEditJob = (jobId) => {
        setSelectedJobId(jobId)
        setEditJobModal(true)
    }

    return (
        <>
            <SetClientModal display={setClientModal} toggle={setSetClientModal} clientId={clientToEdit?.id} />
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Client'
                message="Are you sure you want to delete this client?" confirmAction={handleDeleteClient}
            />

            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJobId} />

            <Row>
                <Col>

                    <Card className="mt-3 mb-4">
                        <Card.Body>
                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Clients</h4>
                                </Col>
                                <Col className="text-end">
                                    <div>
                                        <Button onClick={() => handleShowSetClientModal()}><i className="fa-solid fa-plus"></i> Add</Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col lg="6" className="mb-2">
                                    <div className="d-flex">
                                        <div className="w-50">
                                            <FormInput type="search" placeholder="Search" size="sm" onChange={handleSearchText} />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <div className="text-right d-flex justify-content-lg-end me-2">
                                            <Form.Control as="select" className="w-auto d-inline mr-2" size="sm" onChange={(e) => handleSetPageSize(e.target.value)} defaultValue="">
                                                <option value="" disabled>Select Page Size</option>
                                                <option value="10">10 per page</option>
                                                <option value="20">20 per page</option>
                                                <option value="50">50 per page</option>
                                                <option value="100">100 per page</option>
                                                <option value="250">250 per page</option>
                                                <option value="500">500 per page</option>
                                            </Form.Control>
                                        </div>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic" disabled={isFetching}>
                                                    Export
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button">
                                                        <ReactToPrint
                                                            trigger={() => <div><a href='_'>Print</a></div>}
                                                            content={() => printRef.current}
                                                        />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" disabled={isExportingCSV} onClick={() => exportCSV()}>
                                                        {isExportingCSV ? 'Exporting...' : 'CSV'}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" disabled={isExporting} onClick={() => exportExcel()}>
                                                        {isExporting ? 'Exporting...' : 'Excel'}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {clients.length > 0 ?
                                            <>
                                                <div className="print-wrapper" ref={printRef}>
                                                    <SortableTable
                                                        style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                        responsive
                                                        size="sm"
                                                        data={clients}
                                                        setData={setClients}
                                                        columns={initColumns(clients, ['id', 'companyId'])}
                                                        columnRender={[
                                                            { column: 'totalAmount', render: (value) => rsdFormat(+value) },
                                                            { column: 'latestJob', render: (value) => <span style={{ cursor: 'pointer' }} onClick={() => handleEditJob(value.replace('#',''))}>{value}</span> },
                                                        ]}
                                                        firstColumnRender={(client) => <>
                                                            <i className="fa-solid fa-pencil fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleShowSetClientModal(client)}></i>
                                                            <i className="fa-solid fa-trash fa-sm" style={{ cursor: 'pointer' }} onClick={() => handleShowConfirmation(client)}></i>
                                                        </>}
                                                        addProps={{
                                                            tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                            tData: { className: 'py-2' }
                                                        }}
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no clients found</span>
                                            </>
                                        }

                                        {pagination?.totalPages > 0 &&
                                            <Pages paginationData={pagination} changePage={setPageNumber} pageNumber={pageNumber} />
                                        }

                                        {(isFetching || isDeleting) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ClientsPage;