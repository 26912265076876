import React from 'react';
import { Card, Button, Row } from 'react-bootstrap';
import { displayAddress, displayAddress1, getInitials, toLocalDateString } from '../../utils/helper';

const JobsCard = ({ index, job, dateTime, progress, client, onClickEdit, onClickDelete }) => {

    const getVariant = (progress) => {
        if (progress.toLowerCase() === "scheduled" || progress.toLowerCase() === "assigned")
            return "outline-secondary"
        if (progress.toLowerCase() === "pickup_in_progress" || progress.toLowerCase() === "pickup_completed")
            return "outline-primary"
        if (progress.toLowerCase() === "cleaning" || progress.toLowerCase() === "cleaning_completed" || progress.toLowerCase() === "dispatch_in_progress")
            return "outline-warning"
        if (progress.toLowerCase() === "completed")
            return "outline-success"
    }

    return (

        <Card className="job-card mb-3">
            <Card.Body>

                <div onClick={onClickEdit} className="cursor-pointer">
                    <span className="text-muted" style={{ position: 'absolute', right: '10px', top: '8px' }}><small>{index + 1}</small></span>
                    <p className="mb-0"><small># {job?.id}</small></p>
                    <p className="text-bold mb-1">{client?.firstName + " " + client?.lastName}</p>
                    <p className="mb-0">{client && displayAddress1(client)}</p>
                    <p>{client && displayAddress(client)}</p>
                    {
                        dateTime && <p>{toLocalDateString(dateTime)}</p>
                    }

                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div>
                            <Button variant={getVariant(progress)} className="no-pointer-events" size="sm">{progress.replaceAll("_", " ")}</Button>
                        </div>
                        <div className="mt-2">
                            <div className="d-flex">
                                {job.pickupAssignedTo && <span title={`Pickup: ${job?.pickupAssignedTo?.fullName}`} className="userphoto-placeholder small ms-1 d-flex align-items-center justify-content-center"><span>{getInitials(job?.pickupAssignedTo?.fullName)}</span></span>}
                                {job.cleaningAssignedTo && <span title={`Cleaning: ${job?.cleaningAssignedTo?.fullName}`} className="userphoto-placeholder small ms-1 d-flex align-items-center justify-content-center"><span>{getInitials(job?.cleaningAssignedTo?.fullName)}</span></span>}
                                {job.deliveryAssignedTo && <span title={`Delivery: ${job?.deliveryAssignedTo?.fullName}`} className="userphoto-placeholder small ms-1 d-flex align-items-center justify-content-center"><span>{getInitials(job?.deliveryAssignedTo?.fullName)}</span></span>}
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="mb-2" />
                <div>
                    <i onClick={onClickDelete} className="cursor-pointer fa-solid fa-trash text-muted text-muted"></i>
                </div>
            </Card.Body>
        </Card>

    )
}

export default JobsCard;