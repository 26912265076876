import jwtDecode from 'jwt-decode'

const base = 'Auth'

export const logout = () => {
  localStorage.removeItem('cleaning-crm-token')
}

export function getCurrentUser() {
  try {
    const token = localStorage.getItem('cleaning-crm-token')
    return jwtDecode(token)
  } catch (error) {
    return null
  }
}
  
export function getToken() {
  return localStorage.getItem('cleaning-crm-token')
}