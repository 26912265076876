import React, { useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useState } from 'react'
import FormInput from '../../components/forms/formInput'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse'
import { createReminder, deleteReminder, updateReminder } from '../../services/reminder'
import { toLocalDateString } from '../../utils/helper'
import { getUsersAsOptions } from '../../services/companyUsers'
import AssigneeSelect from '../../components/selects/assigneeSelect'
import ParticipantsSelect from '../../components/selects/participantsSelect'

const SetReminderModal = ({
    display,
    toggle,
    reminder
}) => {

    const [participantsOptions, setParticipantsOptions] = useState([])

    const [formData, setFormData] = useState({})
    const queryClient = useQueryClient()

    const { data: dbParticipantsOptions, isLoading: isLoadingParticipantsOptions } = useQuery(['users-option'], () => getUsersAsOptions())

    useEffect(() => {

        if (!dbParticipantsOptions)
            return

        onQuerySuccess(dbParticipantsOptions, (result) => {
            if (result.length > 0) {
                setParticipantsOptions(result.map(d => ({
                    value: d.id, label: d.fullName, roles: d.roles
                })))
            }
        })

    }, [dbParticipantsOptions])

    const { mutate, isLoading } = useMutation(reminder.id ? updateReminder : createReminder, {
        onSuccess: onMutateSuccess((a) => {
            queryClient.invalidateQueries(['reminders', 'monthly'])
            onHide()
        })
    })

    const { mutate: remove, isLoading: deleting } = useMutation(deleteReminder, {
        onSuccess: onMutateSuccess((a) => {
            queryClient.invalidateQueries(['reminders', 'monthly'])
            onHide()
        })
    })

    const handleChange = (update) => {
        const data = { ...formData, ...update }
        setFormData(data)
    }

    const handleParticipantsChange = (participants) => {
        const data = { ...formData, participants: participants ?? [] }

        setFormData(data)
    }

    const onShow = () => {
        const data = reminder.id ? {
            ...reminder,
            participants: reminder.participants.map(p => p.id)
        } : reminder

        setFormData(data)
    }

    const onHide = () => {
        toggle(false)
        setFormData({})
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const payload = { ...formData }
        payload.participants = formData.participants.map(id => ({ id }))

        mutate(payload)
    }

    return (
        <>
            <Modal
                show={display}
                onShow={onShow}
                onHide={onHide}
                centered
            >
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <h4 className="text-center">{formData.id ? 'Edit' : 'Add'} Reminder</h4>
                        <h4 className="text-center">{toLocalDateString(formData.date, 'dd.MM.yyyy')}</h4>

                        <FormInput label="Title"
                            className="mb-2"
                            value={formData?.title ?? ''}
                            onChange={e => handleChange({ title: e.target.value })}
                        />

                        <FormInput label="Notes"
                            className="mb-2"
                            value={formData?.notes ?? ''}
                            onChange={e => handleChange({ notes: e.target.value })}
                            as="textarea"
                        />

                        <ParticipantsSelect label="Participants"
                            isMulti
                            value={formData.participants}
                            onChange={opt => handleParticipantsChange(opt?.map(x => x.value))}
                        />

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <div>
                            {formData.id &&
                                <Button variant="outline-primary" onClick={() => remove(formData.id)} disabled={deleting}>
                                    {deleting ? 'Deleting' : 'Delete'}
                                </Button>
                            }
                        </div>

                        <div>
                            <Button variant="light" onClick={() => onHide(false)}>Cancel</Button>
                            <Button className="ms-2" type="submit" disabled={isLoading}>
                                {isLoading ? 'Saving' : 'Save'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default SetReminderModal