import React, { useEffect } from 'react';
import { Col, Form, Modal, Row, Button } from 'react-bootstrap';
import DatePicker from '../../components/forms/formDatePicker';
import { useState } from 'react';
import EmployeeSelect from '../../components/selects/employeeSelect';
import FormInput from '../../components/forms/formInput';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { createPayroll, getPayrollById, updatePayroll } from '../../services/payrolls';
import { rsdFormat } from '../../utils/helper';
import OverlayLoader from '../../components/overlayLoader';

const initPayroll = {
    payStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    payEndDate: new Date(),
    employeeId: "",
    workingPeriod: "",
    rateType: "HOURLY",
    rate: '',
    amount: '',
    bonus: '',
    deduction: '',
    salary: '',
    notes: ""
}

const SetPayRollModal = ({
    display,
    toggle,
    payrollId,
}) => {

    const [payroll, setPayroll] = useState(initPayroll)

    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(payrollId ? updatePayroll : createPayroll, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['payrolls', 'paginated'])
            onHide()
        })
    })

    const { data, isFetching } = useQuery(['payrolls', 'details', payrollId], () => payrollId && getPayrollById(payrollId), {
        staleTime: 1000 * 60 * 2,
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setPayroll(result)
        })
    }, [data])

    const onShow = () => {
        !payrollId && setPayroll(initPayroll)
    }

    const onHide = () => {
        toggle(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        let _payroll = payroll
        _payroll.amount = getPayrollAmount()
        _payroll.salary = getSalary()
        mutate(_payroll)
    }

    const handleChange = (objValue) => {
        const _payroll = { ...payroll, ...objValue }
        setPayroll(_payroll)
    }

    const getWorkingPeriodLabel = () => {
        let _rateType = payroll.rateType
        if (_rateType === "HOURLY")
            return "Working Hours"
        else if (_rateType === "DAILY")
            return "Working Days"
        else
            return "Working Period"
    }

    const handleChangeRateType = (value) => {
        let _change = {
            rateType: value
        }
        if (value === "FIXED_MONTHLY") {
            _change.workingPeriod = ""
            _change.rate = 0
        }
        handleChange(_change)
    }

    const getPayrollAmount = () => {
        return payroll.rateType === "FIXED_MONTHLY" ? parseFloat(payroll.amount) : parseFloat(payroll.workingPeriod) * parseFloat(payroll.rate)
    }

    const getSalary = () => {
        return (getPayrollAmount() + parseFloat(payroll?.bonus || 0)) - parseFloat(payroll?.deduction || 0)
    }

    return (
        <Modal
            show={display}
            onHide={onHide}
            onShow={onShow}
            centered
            backdrop={isLoading ? "static" : true}
        >

            <Modal.Body className='pb-1' >
                <h4 className="text-center mt-3">{payrollId ? 'Edit' : 'Add'} Payroll</h4>

                <hr />

                <Form id='set-payroll' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                    <Row className="mb-3">
                        <Col lg="6">
                            <DatePicker className='form-control mb-2' label='Pay Start Date' placeholderText='Pay Start Date'
                                selected={new Date(payroll?.payStartDate) ?? null} onChange={(date) => handleChange({ payStartDate: date })} dateFormat="dd.MM.yyyy"
                            />
                        </Col>
                        <Col lg="6">
                            <DatePicker className='form-control mb-2' label='Pay End Date' placeholderText='Pay End Date'
                                selected={new Date(payroll?.payEndDate) ?? null} onChange={(date) => handleChange({ payEndDate: date })} dateFormat="dd.MM.yyyy"
                            />
                        </Col>
                        <Col lg="6">
                            <EmployeeSelect required className="mb-2" value={payroll.employeeId} label="Employee" placeholder="Employee" onChange={(option) => handleChange({ employeeId: option.value })} />
                        </Col>
                        <Col lg="6">
                            <FormInput as="select" label="Rate Type" placeholder="Select Rate Type"
                                value={payroll.rateType} onChange={(e) => handleChangeRateType(e.target.value)}>
                                <option value="HOURLY">Hourly</option>
                                <option value="DAILY">Daily</option>
                                <option value="FIXED_MONTHLY">Fixed-Monthly</option>
                            </FormInput>
                            {/* <FormInput className="mb-2" label="Rate Type" value={payroll.rateType} onChange={(e) => handleChange({ rateType: e.target.value })} /> */}
                        </Col>
                        <Col lg="6">
                            <FormInput disabled={payroll.rateType === "FIXED_MONTHLY"} type="number" className="mb-2" label={getWorkingPeriodLabel()} value={payroll.workingPeriod} onChange={(e) => handleChange({ workingPeriod: e.target.value })} />
                        </Col>
                        <Col lg="6">
                            <FormInput disabled={payroll.rateType === "FIXED_MONTHLY"} type="number" className="mb-2" label="Rate" value={payroll.rate} onChange={(e) => handleChange({ rate: e.target.value })} />
                        </Col>
                        <Col lg="12">
                            <FormInput disabled={payroll.rateType !== "FIXED_MONTHLY"} type="number" className="mb-2" label="Amount" value={getPayrollAmount()} onChange={(e) => handleChange({ amount: e.target.value })} />
                        </Col>
                        <Col lg="12">
                            <Row>
                                <Col lg="6">
                                    <FormInput type="number" className="mb-2" label="Bonus" value={payroll.bonus} onChange={(e) => handleChange({ bonus: e.target.value })} />
                                </Col>
                                <Col lg="6">
                                    <FormInput type="number" className="mb-2" label="Deduction" value={payroll.deduction} onChange={(e) => handleChange({ deduction: e.target.value })} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="12">
                            <FormInput as="textarea" label="Notes" value={payroll.notes} onChange={(e) => handleChange({ notes: e.target.value })} />
                        </Col>
                    </Row>

                    <Row className="mt-2 mb-3">
                        <Col>
                            <h5 className="d-flex text-end justify-content-between">
                                <span>Salary: </span>
                                <span>{rsdFormat(getSalary())}</span>
                            </h5>
                        </Col>
                    </Row>

                    <div className='d-flex justify-content-end'>
                        <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                        <Button type='submit' disabled={isLoading}>
                            {isLoading ? 'Submitting' : 'Submit'}
                        </Button>
                    </div>

                    {isFetching &&
                        <OverlayLoader />
                    }

                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SetPayRollModal;