import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Card, Table } from 'react-bootstrap';
import OverlayLoader from '../../components/overlayLoader';
import { getCalendarData } from '../../services/calendar';
import { onQuerySuccess } from '../../utils/queryResponse';
import { useQuery } from 'react-query';
import { rsdFormat, toLocalDateString } from '../../utils/helper';
import SetTempEditOrderModal from '../../components/modals/orderEditTemp';
import { getRemindersMonthly } from '../../services/reminder';
import SetReminderModal from './reminderSetModal';
import SetOrderModal from '../../components/modals/orderSet';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const CalendarPage = () => {

    const [editJobModal, setEditJobModal] = useState(false)
    const [reminderModal, setReminderModal] = useState(false)
    const [jobModal, setJobModal] = useState(false)

    const [todayDate] = useState(new Date())
    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedYear, setSelectedYear] = useState('')
    const [startDate, setStartDate] = useState()
    const [lastDate, setLastDate] = useState()

    const [calendarData, setCalendarData] = useState([])
    const [reminders, setReminders] = useState([])
    const [selectedJobId, setSelectedJobId] = useState('')
    const [selectedReminder, setSelectedReminder] = useState({})
    const [jobPickupDateTime, setJobPickupDateTime] = useState({})

    const { data: dbCalendar, isFetching: isFetchingCalendarData } = useQuery(['calendar-data', selectedMonth, selectedYear],
        () => getCalendarData(selectedMonth + 1, selectedYear)
    )

    const { data: remindersData } = useQuery(['reminders', 'monthly', selectedMonth, selectedYear],
        () => getRemindersMonthly(selectedMonth + 1, selectedYear)
    )

    useEffect(() => {
        onQuerySuccess(dbCalendar, (result) => {
            setCalendarData(result)
        })
    }, [dbCalendar])

    useEffect(() => {
        onQuerySuccess(remindersData, (result) => {
            setReminders(result)
        })
    }, [remindersData])

    useEffect(() => {
        const date = todayDate
        const year = date.getFullYear()
        const month = date.getMonth()

        setSelectedYear(year)
        setSelectedMonth(month)
        setStartDate(new Date(year, month, 1))
        setLastDate(new Date(new Date(year, month + 1, 1) - 1))
    }, [todayDate])

    const getDataOnDate = useCallback((dataArray, dateNumber) => {

        return dataArray.filter(data =>
            selectedYear === new Date(data?.date).getFullYear() &&
            selectedMonth === new Date(data?.date).getMonth() &&
            dateNumber === new Date(data?.date).getDate())

    }, [selectedYear, selectedMonth])

    const dateArray = useMemo(() => {
        let weeksArray = []
        // last date number or the month
        const lastDate = new Date(new Date(selectedYear, selectedMonth + 1, 1) - 1).getDate()
        let index = new Date(selectedYear, selectedMonth, 1).getDay()
        let dateNumber = 1

        // if starting day is not sunday
        if (index !== 0) {

            const daysArray = new Array(7)

            for (let i = 0; i < days.length; i++) {
                if (i < index) {
                    daysArray[i] = {}
                } else {

                    const _jobs = getDataOnDate(calendarData, dateNumber)
                    const _sortedJobs = _jobs.sort(function (a, b) { return new Date(a.date) - new Date(b.date) })
                    const remindersData = getDataOnDate(reminders, dateNumber)
                    daysArray[i] = {
                        dateNumber,
                        jobs: _sortedJobs,
                        reminders: remindersData
                    }

                    dateNumber++
                }
            }
            index = 0
            weeksArray.push(daysArray)
        }

        while (dateNumber <= lastDate) {
            const daysArray = new Array(7)

            while (index < days.length) {
                if (dateNumber > lastDate) {
                    daysArray[index] = {}
                    index++
                    continue
                }

                const _jobs = getDataOnDate(calendarData, dateNumber)
                const _sortedJobs = _jobs.sort(function (a, b) { return new Date(a.date) - new Date(b.date) })
                const remindersData = getDataOnDate(reminders, dateNumber)
                daysArray[index] = {
                    dateNumber,
                    jobs: _sortedJobs,
                    reminders: remindersData
                }

                index++
                dateNumber++
            }
            index = 0
            weeksArray.push(daysArray)
        }


        return weeksArray
    }, [selectedYear, selectedMonth, calendarData, getDataOnDate, reminders])

    const handlePreviousMonth = () => {
        if (selectedMonth === 0) {
            const month = 11
            const year = selectedYear - 1
            setStartDate(new Date(year, month, 1))
            setLastDate(new Date(new Date(year, 12, 1) - 1))
            setSelectedMonth(month)
            setSelectedYear(year)
        } else {
            setStartDate(new Date(selectedYear, selectedMonth - 1, 1))
            setLastDate(new Date(new Date(selectedYear, selectedMonth, 1) - 1))
            setSelectedMonth(selectedMonth - 1)
        }
        setCalendarData([])
    }

    const handleNextMonth = () => {
        if (selectedMonth === 11) {
            const month = 0
            const year = selectedYear + 1
            setStartDate(new Date(year, month, 1))
            setLastDate(new Date(new Date(year, 12, 1) - 1))
            setSelectedMonth(month)
            setSelectedYear(year)
        } else {
            const month = selectedMonth + 1
            setStartDate(new Date(selectedYear, month, 1))
            setLastDate(new Date(new Date(selectedYear, month + 1, 1) - 1))
            setSelectedMonth(month)
        }
        setCalendarData([])
    }

    const isTodayDate = (dateNumber) => {
        return (
            selectedYear === todayDate.getFullYear() &&
            selectedMonth === todayDate.getMonth() &&
            dateNumber === todayDate.getDate()
        )
    }

    const getBadgeBG = (badge, defaultBg) => {

        if (badge.type === "Upcoming Delivery")
            return "warning"
        if (badge.type === "Job Completed")
            return "success"
        if (badge === 'reminder')
            return "info"

        return defaultBg
    }

    const handleSelectData = (data) => {
        setSelectedJobId(data.id)
        setEditJobModal(true)
    }

    const handleSetReminder = (reminder) => {
        setSelectedReminder(reminder)
        setReminderModal(true)
    }

    const handleSetJob = (pickupDateTime) => {
        setJobPickupDateTime(pickupDateTime)
        setJobModal(true)
    }

    return (
        <>

            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJobId} />
            <SetReminderModal display={reminderModal} toggle={setReminderModal} reminder={selectedReminder}/>
            <SetOrderModal display={jobModal} toggle={setJobModal} pickupTime={jobPickupDateTime} />

            <h4 className="page-title d-flex align-items-center">
                <span className="me-2">Calendar</span>
            </h4>

            <hr />
            <Card>
                <Card.Body>

                    <div className='d-flex justify-content-between'>
                        <h6>
                            {months[selectedMonth] + ' ' + selectedYear}
                        </h6>
                        {/* <div className='d-flex align-items-center'>
                            <span>Legend #1</span>
                            <span className='d-inline-block bg-info rounded ms-1 me-3' style={{ width: '20px', height: '15px' }}>&nbsp;</span>
                            <span> Legend #2 </span>
                            <span className='d-inline-block bg-danger rounded ms-1 me-3' style={{ width: '20px', height: '15px' }}>&nbsp;</span>
                            <span> Legend #3 </span>
                            <span className='d-inline-block bg-success rounded ms-1' style={{ width: '20px', height: '15px' }}>&nbsp;</span>
                        </div> */}
                        <div>
                            <i title='previous month' className="fa-solid fa-square-caret-left fa-lg me-2 cursor-pointer" onClick={handlePreviousMonth}></i>
                            <i title='next month' className="fa-solid fa-square-caret-right fa-lg cursor-pointer" onClick={handleNextMonth}></i>
                        </div>
                    </div>

                    <div className="position-relative">
                        {isFetchingCalendarData ? // isFetching data
                            <div style={{ height: '100px' }}>
                                <OverlayLoader />
                            </div>
                            :
                            <Table bordered responsive className='mt-3'
                                style={{
                                    tableLayout: 'fixed',
                                    width: '100%'
                                }}
                            >
                                <thead>
                                    <tr>
                                        {days.map(day =>
                                            <th key={day} className='text-center'>
                                                {day}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dateArray.map((week, index1) =>
                                        <tr key={`key-${index1}`} style={{ height: '100px' }}>
                                            {week.map((day, index2) =>
                                                <td key={`key-${index2}`} style={{ overflow: 'hidden' }}>

                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <span className={`d-block ${isTodayDate(day.dateNumber) && 'text-info fw-bold'}`} >
                                                            {day.dateNumber || ''}
                                                        </span>
                                                        { day.dateNumber && 
                                                            <span>
                                                            <i className="fa-xs fa-solid fa-note-sticky text-secondary cursor-pointer me-1"
                                                                title='Add Reminder'
                                                                onClick={() => handleSetReminder({ 
                                                                    date: new Date(selectedYear, selectedMonth, day.dateNumber)
                                                                })}>
                                                            </i> 
                                                            <i className="fa-xs fa-solid fa-hammer text-secondary cursor-pointer" 
                                                                title='Add Job'
                                                                onClick={() => handleSetJob(new Date(selectedYear, selectedMonth, day.dateNumber))}>
                                                            </i>
                                                            </span>
                                                        }
                                                    </div>

                                                    {day.jobs?.map((b, i) =>
                                                        <Badge bg={getBadgeBG(b, "grey")} key={b.id + ''} 
                                                            className={`d-block text-wrap my-1 cursor-pointer`}
                                                            onClick={() => handleSelectData(b)}>   
                                                            {b.clientName} {toLocalDateString(b.date, "HH:mm")}
                                                        </Badge>
                                                    )}
                                                    {day.reminders?.map((r, i) =>
                                                        <Badge bg={getBadgeBG('reminder')} key={r.id + ''} className={`d-block text-wrap my-1 cursor-pointer`}
                                                            onClick={() => handleSetReminder(r)}
                                                        >
                                                            <i className="fa-solid fa-clock"></i>
                                                            {} {r.title}
                                                        </Badge>
                                                    )}

                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        }
                    </div>

                </Card.Body>
            </Card>
        </>
    )
}

export default CalendarPage;