import { toast } from "react-toastify"
import { getErrorMessages } from "./errorResponse"

export const onMutateSuccess = (callback, message) => (response, payload) => {
    const { code, message: responseMessage } = response
    
    if(code !== 200) {
        if(code === 403) {
            toast.error("You are not allowed", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }
        if(code === 500){
            toast.error("Server Error! Please contact the adminstrator.", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }
        toast.error(responseMessage, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
        return
    }
    callback(responseMessage, code, payload)

    message && toast.success( message, {
        position: toast.POSITION.BOTTOM_RIGHT
    })
}

export const onQuerySuccess = ( data, callback ) => {
    if(data) {
        const { code, message } = data
        if(code === 200) {
           callback(message)
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }
}