import React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

const FormDatePicker = ({label, inline, ...rest}) => {
  return ( 
    <Form.Group className={`${inline ? "d-flex" : ""}`}>
        <Form.Label>{label} {rest.required && <span className="text-danger">*</span>}</Form.Label>
        { inline && <>&nbsp;&nbsp;</>}
        <DatePicker {...rest} />
    </Form.Group>
  )
}

export default FormDatePicker
