import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteService, getServices } from '../../services/services';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import SetServiceModal from './servicesSet';
import SortableTable from "reactstrap-sortable-table";
import { initColumns, rsdFormat, toLocalDateString } from '../../utils/helper';
import OverlayLoader from '../../components/overlayLoader';
import ConfirmationModal from '../../components/modals/confirmation';

const ServicesPage = () => {

    const [setServiceModal, setSetServiceModal] = useState(false)
    const [services, setServices] = useState([])
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [serviceToEdit, setServiceToEdit] = useState()
    const [serviceToDelete, setServiceToDelete] = useState()

    const { data, isFetching } = useQuery('services', getServices)

    const queryClient = useQueryClient()
    const { mutate, isLoading: isDeleting } = useMutation(deleteService, {
        onSuccess: onMutateSuccess((result, id) => {
            queryClient.invalidateQueries(['services'])
        }, ' Successfully deleted a service ')
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            const transformedServices = result.map(obj => {
                const { serviceCategory, ...rest } = obj;
                return {
                    category: serviceCategory.name,
                    ...rest
                };
            });

            console.log(transformedServices)
            setServices(transformedServices)
        })
    }, [data])

    const handleShowSetServiceModal = (service) => {
        setServiceToEdit(service)
        setSetServiceModal(true)
    }

    const handleShowConfirmation = (expense) => {
        setConfirmationModal(true)
        setServiceToDelete(expense)
    }

    const handleDeleteService = () => {
        mutate(serviceToDelete.id)
    }

    return (
        <>
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Service'
                message="Are you sure you want to delete this service?" confirmAction={handleDeleteService}
                confirmText="Confirm" cancelText="Cancel"
            />
            <SetServiceModal display={setServiceModal} toggle={setSetServiceModal} serviceId={serviceToEdit?.id} />

            <Row>
                <Col>

                    <Card className="mt-3 mb-4">
                        <Card.Body>

                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Services</h4>
                                </Col>
                                <Col className="text-end">
                                    <div>
                                        <Button onClick={() => handleShowSetServiceModal()}><i className="fa-solid fa-plus"></i> Add</Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {services.length > 0 ?
                                            <>
                                                <div className="print-wrapper">
                                                    <SortableTable
                                                        style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                        responsive
                                                        size="sm"
                                                        data={services}
                                                        setData={setServices}
                                                        columns={initColumns(services, ['id', 'companyId', 'serviceCategoryId', 'createdById', 'updatedById', 'disabled'])}
                                                        columnRender={[
                                                            { column: 'createdAt', render: (value) => toLocalDateString(new Date(value)) },
                                                            { column: 'updatedAt', render: (value) => toLocalDateString(new Date(value)) },
                                                            { column: 'pricePerUnit', render: (value) => rsdFormat(value) }
                                                        ]}
                                                        firstColumnRender={(service) => <>
                                                            <i className="fa-solid fa-pencil fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleShowSetServiceModal(service)}></i>
                                                            <i className="fa-solid fa-trash fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleShowConfirmation(service)}></i>
                                                            {service?.disabled &&
                                                                <i className="fa-solid fa-triangle-exclamation text-danger" title={'service disabled'}></i>
                                                            }
                                                        </>}
                                                        addProps={{
                                                            tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                            tData: { className: 'py-2' }
                                                        }}
                                                        dateColumns={['date']}
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no services found</span>
                                            </>
                                        }

                                        {(isFetching || isDeleting) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>


                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ServicesPage;