import http from "./http"
import { getToken } from './auth'

const base = 'Sales'

export const getPaginatedSales = async (pageNumber, pageSize, startDate, endDate, serviceCategory, service, searchText) => {

    const params = new URLSearchParams()
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)

    startDate && params.append('startDate', startDate.toISOString())
    endDate && params.append('endDate', endDate.toISOString())
    searchText && params.append('searchText', searchText)
    service && params.append('service', service)
    serviceCategory && params.append('serviceCategory', serviceCategory)

    const { data } = await http.get(`${base}/paginated`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getSalesSummary() {

    const params = new URLSearchParams()
    params.append('timeZoneOffset', new Date().getTimezoneOffset())

    const { data } = await http.get(`${base}/summary`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getSaleInvoice(jobId) {

    const { data } = await http.get(`${base}/invoice/${jobId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}