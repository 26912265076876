import React, { Fragment } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { rsdFormat, toLocalDateString } from '../../utils/helper';
import logo from '../../assets/images/logo.png';

const SalesInvoicePrint = ({ invoice }) => {

    console.log(invoice)

    return (
        <div className="m-3 p-3">
            <Row>
                <Col xs="6">
                </Col>
                <Col>
                    <div className="w-100 text-center">
                        <img className="logo-img px-5" style={{ maxHeight: '150px' }} src={logo} alt="My ViVo Bot" />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs="6">
                    <div>
                        {/* <p className="mb-3">Date of issue: {toLocalDateString(invoice?.date)}</p> */}
                        <p className="mb-3">Datum izdavanja: {toLocalDateString(invoice?.date)}</p>
                        <h3 className="mb-1">{invoice?.clientFirstName + ' ' + invoice?.clientLastName}</h3>
                        <div className="mb-3">
                            {invoice?.clientEmail && <p className="mb-0"><i className="fa-solid fa-envelope text-muted"></i> {invoice?.clientEmail}</p>}
                            {invoice?.clientPhone && <p className="mb-0"><i className="fa-solid fa-phone text-muted"></i> {invoice?.clientPhone}</p>}
                            {invoice?.address && <p className="mb-0"><i className="fa-solid fa-location-dot text-muted"></i> {invoice?.address}</p>}
                        </div>
                    </div>
                </Col>
                <Col>

                    {/* <div className="bg-grey text-center text-white mb-1">Invoice Number</div> */}
                    <div className="bg-grey text-center text-white mb-1">Broj fakture</div>
                    <p className="text-center">INV{invoice?.jobNo}</p>

                    <table className="w-100">
                        <tbody>
                            <tr>
                                {/* <td>Invoice Currency:</td> */}
                                <td>Valuta fakture:</td>
                                <td className="text-end">RSD</td>
                            </tr>
                            <tr>
                                {/* <td>Amount:</td> */}
                                <td>Iznos:</td>
                                <td className="text-end text-bold">{rsdFormat(invoice?.totalAmount, false)}</td>
                            </tr>
                        </tbody>
                    </table>

                </Col>
            </Row>
            <Row>
                <Table className="mb-4">
                    <thead>
                        <tr>
                            {/* <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Unit of Measure</th>
                            <th>Price Excl. VAT</th>
                            <th>VAT Rate</th> */}
                            <th>Opys</th>
                            <th>Količina</th>
                            <th>Jedinična cena</th>
                            <th>Jedinica mere</th>
                            <th>Cena bez PDV</th>
                            <th>Stopa PDV-a</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice?.lineItems.map((lineItem, index) => (
                            <tr key={`invoice-${invoice.jobNo}-lineitem-${index}`}>
                                <td>{lineItem.description}</td>
                                <td>
                                    {lineItem.unitOfMeasure === 'm2' ?
                                        <p className='m-0'>{rsdFormat(lineItem.quantity, false)}
                                            <span className='m-0'>{' '}
                                                ({rsdFormat(lineItem.length, false)} X {rsdFormat(lineItem.width, false)})
                                            </span>
                                        </p>
                                        : rsdFormat(lineItem.quantity, false)
                                    }
                                </td>
                                <td>{rsdFormat(lineItem.unitPrice, false)}</td>
                                <td>{lineItem.unitOfMeasure}</td>
                                <td>{rsdFormat(lineItem.priceWithoutVat, false)}</td>
                                <td>{lineItem?.vatRate}%</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>

            <Row className="justify-content-end">
                <Col xs="5">
                    <Row className="justify-content-end">
                        <Col className="text-end">
                            {/* <p className="mb-0">Total Amount Excl. VAT:</p> */}
                            <p className="mb-0">Ukupan iznos bez PDV:</p>
                        </Col>
                        <Col xs="3" className="text-end">
                            <p className="mb-0">{rsdFormat(invoice?.totalPriceWithoutTax, false)}</p>
                        </Col>
                    </Row>
                    <hr />
                    {invoice?.taxRateGroup.map((vatItem, index) => (
                        (<Fragment key={`withvat-${index}`}>
                            <Row className="justify-content-end">
                                <Col className="text-end">
                                    {/* <p className="mb-0">{`Total VAT with rate of ${vatItem.taxRate}%:`}</p> */}
                                    <p className="mb-0">{`Ukupan PDV sa stopom od ${vatItem.taxRate}%:`}</p>
                                </Col>
                                <Col xs="3" className="text-end">
                                    <p className="mb-0">{rsdFormat(vatItem.totalTaxAmount, false)}</p>
                                </Col>
                            </Row>
                            <hr />
                        </Fragment>)
                    ))}
                    {/* <Row className="justify-content-end">
                        <Col className="text-end">
                            <p className="mb-0">Sum of items not subject to VAT:</p>
                        </Col>
                        <Col xs="3" className="text-end">
                            <p className="mb-0">{getNoVatItemsSum() ?? '0'}</p>
                        </Col>
                    </Row>
                    <hr /> */}
                    <Row className="justify-content-end">
                        <Col className="text-end">
                            {/* <p className="mb-0">Total VAT:</p> */}
                            <p className="mb-0">Ukupan PDV:</p>
                        </Col>
                        <Col xs="3" className="text-end">
                            <p className="mb-0">{rsdFormat(invoice?.totalTaxAmount, false)}</p>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="justify-content-end">
                        <Col className="text-end">
                            {/* <p className="mb-0">Total Amount:</p> */}
                            <p className="mb-0">Ukupan iznos:</p>
                        </Col>
                        <Col xs="3" className="text-end">
                            <p className="mb-0">{rsdFormat(invoice?.totalAmount, false)}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default SalesInvoicePrint;