import { useState } from 'react'
import { utils, writeFile } from "xlsx"
import { toLocalDateString } from '../helper'

export const useSheetExport = (name, data, colNames, fileExt = "xlsx") => {

    const [isExporting, setIsExporting] = useState(false)

    const exportSheet = () => {
        setIsExporting(true)

        let _data = data
        if(colNames.map((a) => { return a.toLowerCase() }).includes('date')){
            _data = _data.map(d => ({
                ...d,
                date: toLocalDateString(d.date),
            }))
        }

        const worksheet = utils.json_to_sheet(_data)

        utils.sheet_add_aoa(worksheet, [
            colNames
        ],
            { origin: 'A1' }
        )

        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, name)
        writeFile(workbook, `${name}.${fileExt}`)
        setIsExporting(false)
    }

    return {
        exportSheet,
        isExporting
    }
}