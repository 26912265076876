import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { logout } from '../services/auth';
import JobsSelect from './selects/jobsSelect';
import SocketContext from '../context/socket';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getLatestNotifications, getUnreadNotificationsCount, updateNotification } from '../services/notifications';
import { onMutateSuccess, onQuerySuccess } from '../utils/queryResponse';
import { getSelf } from '../services/users';
import { isUserAdmin } from '../utils/roleHelpers';

const NavigationBar = ({ setSidebarToggle }) => {

    const socket = useContext(SocketContext);
    const [user, setUser] = useState()
    const [scrollOffset, setScrollOffset] = useState(0);
    const [selectedJobId, setSelectedJobId] = useState('')

    const [notifications, setNotifications] = useState([])
    const [unreadNotifCount, setUnreadNotifCount] = useState()

    const { pathname } = useLocation()

    const queryClient = useQueryClient()

    const { data: notificationData } = useQuery(['notifications', 'latest'], () => getLatestNotifications(3))
    const { data: unreadNotifCountData } = useQuery(['notifications', 'unread-count'], () => getUnreadNotificationsCount())
    const { data: userData } = useQuery(['profile'], getSelf)

    const handleLogout = () => {
        logout()
        window.location.reload();
    }

    // const addSocketData = useCallback((data, ) => {
    //     setNotifications([data, ...notifications])
    // },[notifications])

    const { mutate: mutateMarkAsRead } = useMutation(updateNotification, {
        onSuccess: onMutateSuccess((resp) => {
            queryClient.invalidateQueries(['notifications',])
        })
    })

    useEffect(() => {
        user && socket.on(`late-job-${user.activeCompany.id}`, () => {
            queryClient.invalidateQueries(['notifications'])
        });

        user && isUserAdmin() && socket.on(`delete-job-approval-${user.activeCompany.id}`, (data) => {
            // addSocketData(data)
            queryClient.invalidateQueries(['notifications'])
        });

    }, [socket, user, queryClient])

    useEffect(() => {
        onQuerySuccess(notificationData, (result) => {
            setNotifications(result)
        })
        onQuerySuccess(unreadNotifCountData, (result) => {
            setUnreadNotifCount(result)
        })
        onQuerySuccess(userData, (result) => {
            setUser(result)
        })
    }, [notificationData, unreadNotifCountData, userData])

    useEffect(() => {
        const onScroll = () => setScrollOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    window.addEventListener('click', function (e) {
        if (window.innerWidth < 768) {

            const sidebar = document.getElementById('sidebar')
            const sidebarOpen = document.getElementById('sidebar-open')

            if (sidebar.contains(e.target) || sidebarOpen.contains(e.target)) {
                // Clicked in box
            } else {
                // Clicked outside box
                setSidebarToggle(false)
            }
        }
    });

    const getNotifMessage = (notif) => {
        if (notif.message === 'unpicked job')
            return `Job #${notif.objectId} was not picked up`
        else if (notif.message === 'excessive pickup')
            return `Job #${notif.objectId} pick up takes too long`
        else if (notif.message === 'not started cleaning')
            return `Job #${notif.objectId} cleaning not yet started`
        else if (notif.message === 'excessive cleaning')
            return `Job #${notif.objectId} cleaning takes too long`
        else if (notif.message === 'not started dispatch')
            return `Job #${notif.objectId} dispatch not yet started`
        else if (notif.message === 'excessive dispatch')
            return `Job #${notif.objectId} dispatch takes too long`
        else if (notif.message === 'delete request')
            return `Delete request for job #${notif.objectId}`
        else
            return notif.message
    }

    const readNotif = notif => {
        let payload = {
            id: notif.id,
            isRead: true
        }
        mutateMarkAsRead(payload)
    }

    return (
        <>
            <nav className={`navbar navbar-light sticky-top flex-md-nowrap justify-content-between px-0 pt-3 ${scrollOffset > 0 && 'bg-white'}`}>

                <div className="d-flex w-50">
                    <Button id="sidebar-open" className="ms-3 d-md-none d-inline" onClick={() => setSidebarToggle(true)}><i className="fas fa-bars"></i></Button>

                    {(pathname.includes('home') || pathname === "/") &&
                        <div className="ms-4 w-100 pe-2">
                            <JobsSelect value={selectedJobId} onChange={(option) => setSelectedJobId(option.value)} />
                        </div>
                    }
                </div>


                <ul className="navbar-nav px-3">
                    <li className="px-2">
                        <span className="cursor-pointer" onClick={() => handleLogout()}><i className="fa-solid fa-power-off"></i></span>
                    </li>

                    <li className="px-2 d-none d-md-flex align-items-center">
                        <Link to='/profile' className='cursor-pointer'>
                            <span className="text-capitalize">Hello, {user?.fullName}!</span>
                        </Link>
                    </li>

                    <li>
                        {/* drop="down-centered" solves the bug 
                        where dropdown items show in the center page on first click */}
                        {/* but the solution shows a triangle icon which does not look good with the bell
                        so we have to unset some property to hide that icon, see #notification-drop.dropdown-toggle::after in vstyle.css */}
                        <Dropdown className="no-caret" drop="down-centered">
                            <Dropdown.Toggle variant="transparent" id="notification-drop">
                                <i className="fa-solid fa-bell"></i>
                                {notifications.length > 0 &&
                                    <sup><span className="badge bg-danger">{unreadNotifCount}</span></sup>
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ boxShadow: '0 0 10px 2px rgba(0,30,0,0.15)', border: 0, padding: 0 }}>
                                {notifications.length > 0 && notifications.slice(0, 3).map((notif, index) =>
                                    <Dropdown.Item as="button" key={`notif-${index}`}
                                        style={{ borderBottom: '1px solid #d5e0d5' }}>
                                        <p style={{ margin: 0, height: '0.5rem' }}>&nbsp;</p>
                                        <Link onClick={() => readNotif(notif)} style={{ color: '#000' }} to={notif.object === 'job' ? `jobs?jobId=${notif.objectId}` : 'notifications'}>
                                            <i className="fa-solid fa-triangle-exclamation">&nbsp;</i>
                                            <small>{getNotifMessage(notif)}
                                            </small></Link>
                                        <p style={{ margin: 0, height: '0.5rem' }}>&nbsp;</p>
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Item as="button" className="p-0" key={`notif-view-all`}>
                                    <Link className="text-muted d-block px-3 py-2" to="notifications"><small>See All Notifications</small></Link>
                                </Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>

                    </li>

                </ul>
            </nav>
        </>
    )
}

export default NavigationBar;