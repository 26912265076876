import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getClientsAsOptions, getPaginatedClients } from '../../services/clients';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';
import { debounce } from '../../utils/helper';
import Select from 'react-select'

const colorStyles = {
    placeholder: defaultStyles => ({
        ...defaultStyles,
        fontSize: '1em',
    }),
    control: defaultStyles => ({
        ...defaultStyles,
        fontSize: '1.15em'
    })
}

const ClientsSelect = ({ value, isMulti, onChange, options, setOptions, setClientRawName, isFromEditJobModal, ...rest }) => {

    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState()

    useEffect(() => {
        if(!isFromEditJobModal) fetchOptions('')
    }, [])

    const fetchOptions = async(_searchText) => {
        setIsLoading(true)
        const {code, message} = await getClientsAsOptions(_searchText)
        setOptions(message.map(d => ({
            value: d.id, label: d.name
        })))
        setIsLoading(false)
    }

    const handleSearchText = debounce(async(text) => {

        if(!text) return
        if((text ?? '').trim().toLowerCase() === searchText.trim().toLocaleLowerCase()) return

        setSearchText(text)
        fetchOptions(text)
        setClientRawName && setClientRawName(text)
    })

    return (
        <Select isMulti={isMulti}
            value={
                isMulti ? (value && value.map(val => ({ value: val, label: val }))) :
                    (options.find(opt => opt.value === value) || null)
            }
            options={options}
            isLoading={isLoading}
            onInputChange={handleSearchText}
            onChange={onChange}
            styles={colorStyles}
            name="Select client"
            {...rest} />
    )
}

export default ClientsSelect