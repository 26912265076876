import http from './http'
import { getToken } from './auth'

const base = 'Badges'

export async function getBadgeById(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getBadgesByIdList(ids) {
    const { data } = await http.get(`${base}/list/${ids}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getBadges() {

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getAvailableBadges() {

    const { data } = await http.get(`${base}/available`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createBadge(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }

    const { data } = await http.post(base + '/generate', payload, config)
    return data
}

export async function deleteBadge(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}

export async function updateBadge(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}
