import http from "./http"
import { getToken } from './auth'

const base = 'company-admin/ExpenseTypes'

export async function getExpenseTypes() {
    const { data } = await http.get(base, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createExpenseType(payload) {
    const { data } = await http.post(base, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function deleteExpenseType(id) {
    const { data } = await http.delete(base + '/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function updateExpenseType(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/', payload, config)
    return data
}