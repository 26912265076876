import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getUsersAsOptions } from '../../services/companyUsers';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';
import { getEmployees } from '../../services/employees';

const EmployeeSelect = ({ value, isMulti, includeAll = false, ...rest  }) => {

    const [options, setOptions] = useState([])

    const { data, isLoading } = useQuery(['employees-option'], () => getEmployees())

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            let _options = []
            if (result.length > 0) {
                _options = (result.map(d => ({
                    value: d.id, label: d.firstName + " " + d.lastName
                })))
            }
            if(includeAll) {
                _options.unshift({value: '', label: 'All Employees'})
            }
            setOptions(_options)
        })
    }, [data])

    return (
        <FormSelect isMulti={isMulti} {...rest}
            value={
                isMulti ? (value && value.map(val => ({ value: val, label: val }))) :
                    (options.find(opt => opt.value === value) || null)
            }
            options={options}
            isLoading={isLoading}
        />
    )
}

export default EmployeeSelect