import { getAllCountries } from '../../utils/countries';
import FormSelect from '../forms/formSelect';

const UserRoleSelect = ({ value, onChange, isMulti, ...rest }) => {

    const roles = ['Driver', 'Staff', 'Cleaner', 'Order Desk']
    const options = roles.map((x => { return { value: x.toLowerCase().replace(' ', '_'), label: x } }));

    return (
        <FormSelect isMulti={isMulti} {...rest}
            value={
                isMulti ? (value && value.map(val => ({ value: val, label: val }))) :
                    (options.find(opt => opt.value === value) || null)
            }
            options={options}
            onChange={(option) => onChange(option && (isMulti ? option.map(opt => opt.value) : option.value))}
        />
    )
}

export default UserRoleSelect