import http from "./http"
import { getToken } from './auth'

const base = 'jobs'

export const getJobs = async (startDate, endDate, status) => {

    const params = new URLSearchParams()
    status && params.append('status', status)
    startDate && params.append('startDate', startDate.toISOString())
    if(startDate) startDate.setHours(0, 0, 1, 0)
    if(endDate) endDate.setHours(23, 59, 59, 999)
    endDate && params.append('endDate', endDate.toISOString())

    const { data } = await http.get(`${base}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getJobsOverallTotal() {
    const { data } = await http.get(`${base}/overall/total`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}

export async function getJobById(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}

export async function getJobsByNumber(number) {
    const { data } = await http.get(`${base}/number/${number}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}

export async function getJobsBySearchText(searchText) {
    const { data } = await http.get(`${base}/dashboard/${searchText}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}

export async function getPaginatedJobReports(
    pageNumber, pageSize, status, paymentStatus,
    // pickupTimeStartDate, pickupTimeEndDate, completionTimeStartDate, completionTimeEndDate,
    dateFrom, dateTo,
    pickupAssignedToId, cleaningAssignedToId, deliveryAssignedToId,
    serviceId, serviceCategoryId, badgeId, clientId, jobNoFrom, jobNoTo
    ) {

    const params = new URLSearchParams()
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)

    status && params.append('status', status)
    paymentStatus && params.append('paymentStatus', paymentStatus)
    // pickupTimeStartDate && params.append('pickupTimeStartDate', pickupTimeStartDate.toISOString())
    // pickupTimeEndDate && params.append('pickupTimeEndDate', pickupTimeEndDate.toISOString())
    // completionTimeStartDate && params.append('completionTimeStartDate', completionTimeStartDate.toISOString())
    // completionTimeEndDate && params.append('completionTimeEndDate', completionTimeEndDate.toISOString())
    dateFrom && params.append('dateFrom', dateFrom.toISOString())
    dateTo && params.append('dateTo', dateTo.toISOString())
    pickupAssignedToId && params.append('pickupAssignedToId', pickupAssignedToId)
    cleaningAssignedToId && params.append('cleaningAssignedToId', cleaningAssignedToId)
    deliveryAssignedToId && params.append('deliveryAssignedToId', deliveryAssignedToId)
    serviceId && params.append('serviceId', serviceId)
    serviceCategoryId  && params.append('serviceCategoryId', serviceCategoryId)
    badgeId && params.append('badgeId', badgeId)
    clientId && params.append('clientId', clientId)
    jobNoFrom && params.append('jobNoFrom', jobNoFrom)
    jobNoTo && params.append('jobNoTo', jobNoTo)

    const { data } = await http.get(`${base}/reports`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getJobsPerformance(startDate, endDate) {

    const params = new URLSearchParams()

    startDate  && params.append('startDate', startDate.toISOString())
    endDate  && params.append('endDate', endDate.toISOString())

    const { data } = await http.get(`${base}/reports/jobs-performance`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getJobAssigneesReport(startDate, endDate) {

    const params = new URLSearchParams()
    startDate  && params.append('startDate', startDate.toISOString())
    endDate  && params.append('endDate', endDate.toISOString())

    const { data } = await http.get(`${base}/reports/assignees`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}


export async function createJob(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }
    //api won't accept 'lineItemsTemp' as it does not exists in the accepted request body
    //we use 'lineItemsTemp' for adding more data for display purpose in the lineitems section in the create job modal
    // delete payload.lineItemsTemp
    const { data } = await http.post(base + '/', payload, config)
    return data
}

export async function updateJob(payload) {

    if(payload.updateLineItems) {
        const results = await payload.updateLineItems(payload.lineItemsTemp)
        for (const result of results) {
            if (result.code !== 200) {
                return result
            }
        }
    }

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }
    //api won't accept 'lineItemsTemp' as it does not exists in the accepted request body
    //we use 'lineItemsTemp' for adding more data for display purpose in the lineitems section in the create job modal
    delete payload.lineItemsTemp

    if(payload.updateLineItems) {
        delete payload.updateLineItems
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}

export async function deleteJob(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}
