import { Modal } from 'react-bootstrap'
import ShowHideColumns from '../showHideColumns'

const ShowHideColumnsModal = ({ display, toggle, columns, shownColumns, setShownColumns, storageName }) => {

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            size="lg"
            centered
            className="showhide-columns-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ width: '98%' }}>
                    <h4 className="text-center mt-3">Show/Hide Columns</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <h4 className="text-center mb-4">Show/Hide Columns</h4> */}

                <ShowHideColumns columns={columns} shownColumns={shownColumns} setShownColumns={setShownColumns} toggle={toggle}
                    storageName={storageName} />

            </Modal.Body>


        </Modal>
    )
}

export default ShowHideColumnsModal

