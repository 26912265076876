import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactToPrint from "react-to-print";
import SortableTable from "reactstrap-sortable-table";
import FormInput from "../../components/forms/formInput";
import ConfirmationModal from "../../components/modals/confirmation";
import OverlayLoader from "../../components/overlayLoader";
import Pages from "../../components/pages";
import { deleteExpense, getPaginated } from "../../services/expenses";
import { rsdFormat, debounce, initColumns, toLocalDateString } from "../../utils/helper";
import { useSheetExport } from "../../utils/queryHooks/sheetExport";
import { onMutateSuccess, onQuerySuccess } from "../../utils/queryResponse";
import SetExpenseModal from "./expenseSet";
import ExpenseSheetImportModal from "./expenseSheetImport";
import ExpensesFilter from "./expensesFilter";

const ExpensesPage = () => {

    const [expenseModal, setExpenseModal] = useState(false)
    const [expenseSheetImportModal, setExpenseSheetImportModal] = useState(false)

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [expenses, setExpenses] = useState([])
    const [expenseToDelete, setExpenseToDelete] = useState()
    const [expenseToEdit, setExpenseToEdit] = useState()
    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [filters, setFilters] = useState(['', ''])
    const [searchText, setSearchText] = useState('')

    const { exportSheet: exportExcel, isExporting } = useSheetExport("Expenses",
        expenses.map(({ id, companyId, createdById, notes, updatedById, ...keepAttrs }) => keepAttrs),
        ["Date", "Amount", "Category", "Description", "Payment Method", "Created At", "Updated At"])
    const { exportSheet: exportCSV, isExportingCSV } = useSheetExport("Expenses",
        expenses.map(({ id, companyId, createdById, notes, updatedById, ...keepAttrs }) => keepAttrs),
        ["Date", "Amount", "Category", "Description", "Payment Method", "Created At", "Updated At"], "csv")

    const printRef = useRef()
    const queryClient = useQueryClient()

    const { data, isFetching } = useQuery(
        ['expenses', 'paginated', pageNumber, pageSize, ...filters, searchText],
        () => getPaginated(pageNumber, pageSize, ...filters, searchText)
    )

    const { mutate, isLoading: isDeleting } = useMutation(deleteExpense, {
        onSuccess: onMutateSuccess((result, id) => {
            if (expenses.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1)
            }
            queryClient.invalidateQueries(['expenses', 'paginated'])
        })//, ' Successfully deleted an expense ')
    })

    const setExpensesModified = (data) => {
        setExpenses(data.map(x => {
            x.amount = parseFloat(x.amount)
            const { amount, ...rest } = x;
            return { date: x.date, amount, ...rest };//moving date and amount to be the first columns
        }))
    }

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setPagination(result)
            setExpensesModified(result.data)
        })
    }, [data])

    const handleDeleteExpense = () => {
        mutate(expenseToDelete.id)
    }

    const handleShowConfirmation = (expense) => {
        setConfirmationModal(true)
        setExpenseToDelete(expense)
    }

    const handleShowSetExpenseModal = (expense) => {
        setExpenseToEdit(expense)
        setExpenseModal(true)
    }

    const handleSearchText = debounce((e) => {
        setSearchText(e.target.value)
    })

    const handleSetPageSize = (size) => {
        setPageNumber(1)
        setPageSize(size)
    }

    return (
        <>
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Expense'
                message="Are you sure you want to delete expense?" confirmAction={handleDeleteExpense}
            />

            <SetExpenseModal display={expenseModal} toggle={setExpenseModal} expenseId={expenseToEdit?.id} onClose={() => setExpenseToEdit()} />
            <ExpenseSheetImportModal display={expenseSheetImportModal} toggle={setExpenseSheetImportModal} />

            <Row>
                <Col>
                    <ExpensesFilter onFilter={setFilters} />

                    <Card className="mt-3 mb-4">
                        <Card.Body>

                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Expenses</h4>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button onClick={() => handleShowSetExpenseModal()}><i className="fa-solid fa-plus"></i> Add</Button>
                                    {/* <Button onClick={() => setExpenseSheetImportModal(true)}><i className="fa-solid fa-plus"></i> Import Using CSV or Excel</Button> */}
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col lg="6" className="mb-2">
                                    <div className="d-flex">
                                        <div className="w-50">
                                            <FormInput type="search" placeholder="Search" size="sm" onChange={handleSearchText} />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <div className="text-right d-flex justify-content-lg-end me-2">
                                            <Form.Control as="select" className="w-auto d-inline mr-2" size="sm" onChange={(e) => handleSetPageSize(e.target.value)} defaultValue="">
                                                <option value="" disabled>Select Page Size</option>
                                                <option value="10">10 per page</option>
                                                <option value="20">20 per page</option>
                                                <option value="50">50 per page</option>
                                                <option value="100">100 per page</option>
                                                <option value="250">250 per page</option>
                                                <option value="500">500 per page</option>
                                            </Form.Control>
                                        </div>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic" disabled={isFetching}>
                                                    Export
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button">
                                                        <ReactToPrint
                                                            trigger={() => <div><a >Print</a></div>}
                                                            content={() => printRef.current}
                                                        />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" disabled={isExportingCSV} onClick={() => exportCSV()}>
                                                        {isExportingCSV ? 'Exporting...' : 'CSV'}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" disabled={isExporting} onClick={() => exportExcel()}>
                                                        {isExporting ? 'Exporting...' : 'Excel'}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {expenses.length > 0 ?
                                            <>
                                                <div className="print-wrapper" ref={printRef}>
                                                    <SortableTable
                                                        style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                        responsive
                                                        size="sm"
                                                        data={expenses}
                                                        setData={setExpenses}
                                                        columns={initColumns(expenses, ['id', 'notes', 'companyId', 'createdById', 'updatedById', 'payroll'])}
                                                        columnRender={[
                                                            { column: 'date', render: (value) => toLocalDateString(value) },
                                                            { column: 'createdAt', render: (value) => toLocalDateString(value) },
                                                            { column: 'updatedAt', render: (value) => toLocalDateString(value) },
                                                            { column: 'amount', render: (value) => rsdFormat(value) }
                                                        ]}
                                                        firstColumnRender={(expense) => <>
                                                            <i className={`fa-solid fa-pencil fa-sm me-1 ${expense?.payroll?.id && 'disabled'}`} style={{ cursor: 'pointer' }} onClick={() => handleShowSetExpenseModal(expense)}></i>
                                                            <i className={`fa-solid fa-trash fa-sm ${expense?.payroll?.id && 'disabled'}`} style={{ cursor: 'pointer' }} onClick={() => handleShowConfirmation(expense)}></i>
                                                        </>}
                                                        withTotalColumns={['amount']}
                                                        totalRender={(value) => <strong>{rsdFormat(value)}</strong>}
                                                        addProps={{
                                                            tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                            tData: { className: 'py-2' }
                                                        }}
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no expenses found</span>
                                            </>
                                        }
                                        { pagination?.totalPages > 0 &&
                                            <Pages paginationData={pagination} changePage={setPageNumber} pageNumber={pageNumber}/>
                                        }

                                        {(isFetching || isDeleting) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>

    );
};

export default ExpensesPage;