import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getServices } from '../../services/services';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';

const ServiceSelect = ({ value, isMulti, serviceId, ...rest }) => {

    const [options, setOptions] = useState([])

    const { data, isLoading } = useQuery(['services'], () => getServices())

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            if (result.length > 0) {
                let _options = [...result]
                let _service = []
                if (serviceId) _service = result.find(x => x.id === serviceId)
                _options = result.filter(x => !x.disabled).concat(_service)
                setOptions(_options.map(d => ({
                    value: d.id, label: d.name, ppu: d.pricePerUnit, unit: d.unit
                })))
            }
        })
    }, [data, serviceId])

    return (
        <FormSelect isMulti={isMulti} {...rest}
            value={
                isMulti ? (value && value.map(val => ({ value: val, label: val }))) :
                    (options.find(opt => opt.value === value) || null)
            }
            options={options}
            isLoading={isLoading}
        />
    )
}

export default ServiceSelect