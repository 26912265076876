import { getCurrentUser } from "../services/auth";

const permissions = [
    {
        role:'driver',
        paths: ['/home', '/jobs', '/clients', '/calendar','/notifications']
    },
    {
        role:'staff',
        paths: ['/jobs', '/clients', '/calendar','/notifications']
    },
    {
        role:'cleaner',
        paths: ['/home', '/jobs', '/clients', '/calendar','/notifications']
    },
    {
        role:'order_desk',
        paths: ['/home', '/jobs', '/clients', '/calendar', '/services','/notifications']
    }
]

export const isUserAllowed = (path) => {

    const curUser = getCurrentUser();

    if(!curUser || !curUser.activeRoles) return false

    if(curUser.activeRoles.includes('admin')) return true//matic na

    const perm = permissions.filter(x => curUser.activeRoles.includes(x.role))
    if(!perm || !perm.length) return false

    return perm.some(x => x.paths.includes(path))
}

export const isUserAdmin = () => {
    const curUser = getCurrentUser()
    return curUser?.activeRoles?.includes('admin') || false
}