import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, BarElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import OverlayLoader from '../overlayLoader';
import { getSales } from '../../services/overview'
import { useQuery } from 'react-query';
import { onQuerySuccess } from '../../utils/queryResponse';
import { get as generateNiceColors} from '../../utils/niceColors'

ChartJS.register(CategoryScale, Tooltip, Legend, BarElement, PointElement, LinearScale, Title);

const SalesChart = () => {

    const [chartData, setChartData] = useState()

    const [options, setOptions] = useState()

    const query = useQuery(['overview-sales'], () => getSales(), {
        keepPreviousData: true,
        staleTime: 1000 * 60, // 1 minute
        refetchOnMount: true,
    })

    const { data, isFetching } = query

    useEffect(() => {
        onQuerySuccess(data, (result) => {

            const salesData = result
          
            // Get unique categories and services
            const categories = Object.keys(salesData);
            const colors = generateNiceColors(categories.length);
            const services = Array.from(new Set(Object.values(salesData).flatMap((service) => Object.keys(service))));
            
            // Generate the datasets
            const datasets = services.map((service, index) => {
                const data = categories.map((category) =>
                    salesData[category][service] ? parseFloat(salesData[category][service]) : 0
                );
                
                return {
                    label: service,
                    data,
                    backgroundColor: colors[index],
                };
            });         

            setOptions({
                plugins: {
                    legend: {
                        position: 'top',
                        labels:{
                            usePointStyle:true,
                            pointStyle: 'rectRounded'
                        },
                        display: datasets?.length > 0
                    }
                    
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    x: {
                    stacked: true,
                    },
                    y: {
                    stacked: true,
                    },
                }
            })

            setChartData({
                labels: categories,
                datasets: datasets.length > 0 ? datasets : ['']
            })
           
        })
    }, [data])
    
    return (
        <div style={{position:'relative', height: '350px'}}>
            {chartData &&
                <Bar options={options} data={chartData} />
            }
            { isFetching &&
                <OverlayLoader />
            }
        </div>
    )
}

export default SalesChart