import React, { useState } from 'react';
import { Col, Form, Modal, Row, Button } from 'react-bootstrap';
import ServiceSelect from '../../components/selects/serviceSelect';
import FormInput from '../../components/forms/formInput';
import { useMutation, useQueryClient } from 'react-query';
import { createLineItem, updateLineItem } from '../../services/lineItems';
import { onMutateSuccess } from '../../utils/queryResponse';

const initLineItem = {
    quantity: 0,
    price: 0,
    taxRate: 0,
    taxAmount: 0,
    priceWithTax: 0,
    serviceId: ''
}

const SetLineItemModal = ({
    display,
    toggle,
    lineItemToEdit,
    jobId
}) => {

    const [lineItem, setLineItem] = useState({ ...initLineItem, jobId })

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(lineItemToEdit?.id ? updateLineItem : createLineItem, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['jobs', 'details', jobId])
            queryClient.invalidateQueries(['job-activities', jobId])
            onHide()
        })
    })

    const onShow = () => {
        lineItemToEdit?.id ? setLineItem(lineItemToEdit) : setLineItem({ ...initLineItem, jobId })
    }

    const onHide = () => {
        toggle(false)
    }

    const handleChange = (objValue) => {
        const _lineItem = { ...lineItem, ...objValue }

        let _price = _lineItem.pricePerUnit * _lineItem.quantity
        _lineItem.price = _price

        let _taxAmount = (_lineItem.taxRate / 100) * _lineItem.price
        _lineItem.taxAmount = _taxAmount

        let _priceWithTax = _lineItem.price + _lineItem.taxAmount
        _lineItem.priceWithTax = _priceWithTax

        setLineItem(_lineItem)
    }

    const handleServiceSelect = (option) => {
        handleChange({ serviceId: option.value, pricePerUnit: parseFloat(option.ppu), unit: option.unit, quantity: 0 })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        
        mutate(lineItem)
    }

    return (
        <Modal
            show={display}
            onHide={onHide}
            size="lg"
            onShow={onShow}
            centered
            backdropClassName="level-2"
        >

            <Modal.Body >
                <h4 className="text-center mt-3">{lineItemToEdit?.id ? 'Edit' : 'Add'} Line Item</h4>

                <hr />

                <Form id='set-line-item' onSubmit={handleSubmit} style={{ position: 'relative' }}>
                    <Row>
                        <Col lg="4">
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    {lineItem.serviceId ? `${lineItem.pricePerUnit} per ${lineItem.unit}` : 'Service'}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <ServiceSelect required value={lineItem.serviceId ?? ''} onChange={(option) => handleServiceSelect(option)} />
                            </Form.Group>
                        </Col>
                        <Col lg="2">
                            <FormInput value={lineItem.quantity} className="mb-2"
                                label={lineItem.serviceId ? lineItem.unit : "Quantity"}
                                type="number" min={lineItem.unit === 'Piece' ? 1 : 0.01} step={lineItem.unit === 'Piece' ? 1 : 0.01}
                                onChange={(e) => handleChange({ quantity: e.target.value })}
                                required
                            />
                        </Col>
                        <Col lg="2">
                            <FormInput disabled value={lineItem.price} className="mb-2"
                                label="Price" type="number" min="0.01" step="0.01"
                                onChange={(e) => handleChange({ price: e.target.value })}
                                required
                            />
                        </Col>
                        <Col lg="2">
                            <FormInput value={lineItem.taxRate} className="mb-2" label="Tax %" type="number" step='0.01' onChange={(e) => handleChange({ taxRate: e.target.value })} />
                        </Col>
                        <Col lg="2">
                            <FormInput disabled value={lineItem.priceWithTax} className="mb-2" label="Total"
                                type="number" min="0.01" step="0.01" required
                                onChange={(e) => handleChange({ priceWithTax: e.target.value })}
                            />
                        </Col>
                    </Row>

                    <div className='d-flex justify-content-end mt-3 mb-2'>
                        <Button type='submit' disabled={isLoading}>
                            {isLoading ? 'Saving' : 'Save'}
                        </Button>
                    </div>
                </Form>

            </Modal.Body>
        </Modal>
    );
};

export default SetLineItemModal;