import { Card, Col, Row, Table } from "react-bootstrap";
import {
    Chart as ChartJS, Legend, ArcElement, Tooltip
} from 'chart.js';
import { getReports } from "../../../services/expenses";
import { onQuerySuccess } from "../../../utils/queryResponse";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useState } from "react";
import { Doughnut } from 'react-chartjs-2';
import JobsPerformanceFilter from "./jobsPerformanceFilter";
import { msToHHMM } from "../../../utils/timeHelpers";
import { toTitleCase } from "../../../utils/helper"
import { get as generateNiceColors } from "../../../utils/niceColors"
import OverlayLoader from "../../../components/overlayLoader";

ChartJS.register(ArcElement, Tooltip, Legend);

const ExpensesReportsPage = () => {

    const [reports, setReports] = useState({ expensesByCategory: [], expensesByPaymentMethod: [] })

    const [filters, setFilters] = useState(['', ''])

    const { data, isFetching } = useQuery(
        ['expenses-reports', ...filters],
        () => getReports(...filters)
    )

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setReports(result)
        })

    }, [data])

    return (
        <>
            <Row>
                <Col>
                    <JobsPerformanceFilter onFilter={setFilters} />

                    <Row className="pb-3 mt-3">
                        <Col className="my-auto">
                            <h4 className="page-title mb-0">Expenses Reports</h4>
                        </Col>
                    </Row>

                    <Row className="row-eq-height mb-3">
                        <Col lg="6" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                        <i className="color-secondary fa-solid fa-layer-group fa-xl"></i>
                                        <p style={{ fontSize: ".8rem" }} className="text-muted mb-0">Category</p>
                                    </div>
                                    <Doughnut data={{
                                        labels: reports.expensesByCategory.map(x => toTitleCase(x.category ?? "No Name")),
                                        datasets: [{
                                            data: reports.expensesByCategory.map(x => x.amount),
                                            backgroundColor: generateNiceColors(reports.expensesByCategory.length),
                                        }]
                                    }} />
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                        <i className="color-secondary fa-brands fa-paypal fa-xl"></i>
                                        <p style={{ fontSize: ".8rem" }} className="text-muted mb-0">Payment Method</p>
                                    </div>
                                    <Doughnut data={{
                                        labels: reports.expensesByPaymentMethod.map(x => toTitleCase(x.paymentMethod ?? "No Name")),
                                        datasets: [{
                                            data: reports.expensesByPaymentMethod.map(x => x.amount),
                                            backgroundColor: generateNiceColors(reports.expensesByPaymentMethod.length),
                                        }]
                                    }} />
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    );
};

export default ExpensesReportsPage;