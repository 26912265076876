import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AdminPage = () => {

    return (
        <>
            <Row>
                <Col>
                    <h4 className="page-title admin">Admin</h4>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className="mb-3" lg="4">
                                    <p className="mb-0"><strong>Manage People</strong></p>
                                    <Link to="/settings/users" className="d-block">Users</Link>
                                    <Link to="/settings/employees" className="d-block">Employees</Link>
                                </Col>
                                <Col className="mb-3" lg="4">
                                    <p className="mb-0"><strong>Manage Services</strong></p>
                                    <Link to="/settings/service-categories" className="d-block">Service Categories</Link>
                                </Col>
                                <Col className="mb-3" lg="4">
                                    <p className="mb-0"><strong>Manage Badges</strong></p>
                                    <Link to="/settings/badges" className="d-block">Badges</Link>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AdminPage;