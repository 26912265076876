import React from 'react'
import { useElementLoader } from '../utils/loader'

const OverlayLoader = ({ noTextDisplay, textDisplay = 'loading...', size = 30 }) => {
    const loader = useElementLoader(size)
    return (
        <div className='d-flex justify-content-center align-items-center bg-white rounded'
            style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                background: '#fff',
                opacity: 0.9,
                zIndex: 999,
                // borderRadius:'50%'
            }}
        >
            {loader} {noTextDisplay ? '' : textDisplay}
        </div>
    )
}

export default OverlayLoader