import { useState } from 'react';
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import DatePicker from '../../components/forms/formDatePicker';
import { toLocalDateString } from '../../utils/helper';
import StatusSelector from '../../components/statusSelector';
import FormInput from '../../components/forms/formInput';

const JobsFilter = ({ isFiltering, onFilter }) => {

    const [isShow, setIsShow] = useState(false)
    const [status, setStatus] = useState('')
    const [dateFrom, setDateFrom] = useState(new Date())
    const [dateTo, setDateTo] = useState(new Date())
    const queryClient = useQueryClient()
    const [filterName, setFilterName] = useState('Today')

    const handleFilter = (e) => {

        let _filterName = []
        if (dateFrom)
            _filterName.push(toLocalDateString(dateFrom, 'dd.MM.yyyy'))
        if (dateTo)
            _filterName.push(toLocalDateString(dateTo, 'dd.MM.yyyy'))

        const _status = status ? status.replace(/_/g, ' ') + ' ': ''
        setFilterName(`${_status}${_filterName.join(' - ')}`)
        e.preventDefault()
        e.stopPropagation()
        onFilter([
            dateFrom ?? '',
            dateTo ?? '',
            status ?? ''
        ])
        setTimeout(() => setIsShow(false), 700);
    }

    const handleReset = () => {
        const _status = status ? status.replace(/_/g, ' ') + ' ': ''
        setFilterName(`${_status}Today`)
        onFilter(['', '', ''])
        setDateFrom('')
        setDateTo('')
        setStatus('')
        queryClient.invalidateQueries(['jobs'])
        setTimeout(() => setIsShow(false), 700);
    }

    const handleFilterToday = () => {
        const _status = status ? status.replace(/_/g, ' ') + ' ': ''
        setFilterName(`${_status}Today`)
        const startDate = new Date()
        const endDate = new Date()

        onFilter([startDate, endDate, status])
        setDateFrom(startDate)
        setDateTo(endDate)
        setTimeout(() => setIsShow(false), 700);
    }

    const handleFilterTomorrow = () => {
        const _status = status ? status.replace(/_/g, ' ') + ' ': ''
        setFilterName(`${_status}Tomorrow`)
        let dateToday = new Date();
        const startDate = new Date(dateToday.setDate(dateToday.getDate() + 1));
        const endDate = new Date(dateToday.setDate(dateToday.getDate() + 1));

        onFilter([startDate, endDate, status])
        setDateFrom(startDate)
        setDateTo(endDate)
        setTimeout(() => setIsShow(false), 700);
    }

    const handleFilterThisWeek = () => {
        const _status = status ? status.replace(/_/g, ' ') + ' ': ''
        setFilterName(`${_status}This Week`)
        let dateToday = new Date();
        const startDate = new Date(dateToday.setDate(dateToday.getDate() - dateToday.getDay() + 1));
        const endDate = new Date(dateToday.setDate(dateToday.getDate() - dateToday.getDay() + 7));
        onFilter([startDate, endDate, status])
        setDateFrom(startDate)
        setDateTo(endDate)
        setTimeout(() => setIsShow(false), 700);
    }

    const handleFilterThisMonth = () => {
        const _status = status ? status.replace(/_/g, ' ') + ' ': ''
        setFilterName(`${_status}This Month`)
        let dateToday = new Date();
        const startDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), 1);
        const endDate = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0);
        onFilter([startDate, endDate, status])
        setDateFrom(startDate)
        setDateTo(endDate)
        setTimeout(() => setIsShow(false), 700);
    }

    const handleFilterLastMonth = () => {
        const _status = status ? status.replace(/_/g, ' ') + ' ': ''
        setFilterName(`${_status}Last Month`)
        let dateToday = new Date();
        const startDate = new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, 1);
        const endDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), 0);
        onFilter([startDate, endDate, status])
        setDateFrom(startDate)
        setDateTo(endDate)
        setTimeout(() => setIsShow(false), 700);
    }

    return (
        <>
            <span className='cursor-pointer' style={{ display: 'flex', flexDirection: 'row' }} onClick={() => setIsShow(!isShow)}><i className={`fa-solid fa-sort-${isShow ? 'up' : 'down'} ${isShow ? 'mt-2' : 'mb-2'} px-1`}></i>
                {/* {isShow ? 'Hide' : 'Show'} Filter */}
                {filterName}
            </span>
            <Collapse in={isShow}>
                <Form onSubmit={handleFilter}>
                    <Card className='mt-2'>
                        <Card.Body>
                            <Row>
                                <Col lg="12" xl="6">
                                    <div className="d-flex flex-wrap mb-2" style={{ marginTop: '28px' }}>
                                        <Form.Group className="me-2">
                                            <div className="mb-2">
                                                <Button onClick={() => handleFilterToday()}>Today</Button>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="me-2">
                                            <div className="mb-2">
                                                <Button onClick={() => handleFilterTomorrow()}>Tomorrow</Button>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="me-2">
                                            <div className="mb-2">
                                                <Button onClick={() => handleFilterThisWeek()}>This Week</Button>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="me-2">
                                            <div className="mb-2">
                                                <Button onClick={() => handleFilterThisMonth()}>This Month</Button>
                                            </div>
                                        </Form.Group>
                                        <Form.Group>
                                            <div className="mb-2">
                                                <Button onClick={() => handleFilterLastMonth()}>Last Month</Button>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col lg="6" xl="3">
                                            <div className="mb-3">
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <FormInput value={status} as="select" onChange={(e) => setStatus(e.target.value)}>
                                                        <option value="">All Status</option>
                                                        <option value="SCHEDULED">Scheduled</option>
                                                        <option value="ASSIGNED">Assigned</option>
                                                        <option value="PICKUP_IN_PROGRESS">Pickup in Progress</option>
                                                        <option value="PICKUP_COMPLETED">Pickup Completed</option>
                                                        <option value="CLEANING">Cleaning</option>
                                                        <option value="CLEANING_COMPLETED">Cleaning Completed</option>
                                                        <option value="DISPATCH_IN_PROGRESS">Dispatch in Progress</option>
                                                        <option value="COMPLETED">Completed</option>
                                                    </FormInput>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col lg="6" xl="3" className='mb-2'>
                                            <DatePicker className='form-control' label='Date From' placeholderText='Date From'
                                                selected={dateFrom} onChange={setDateFrom} maxDate={dateTo || undefined}
                                            />
                                        </Col>
                                        <Col lg="6" xl="3" className='mb-2'>
                                            <DatePicker className='form-control' label='Date To' placeholderText='Date To'
                                                selected={dateTo} onChange={setDateTo} minDate={dateFrom || undefined}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Group >
                                                <Form.Label>&nbsp;</Form.Label>
                                                <div className='d-flex justify-content-end'>
                                                    <Button variant="outline-secondary" className='me-2' onClick={handleReset}>Reset</Button>
                                                    <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Filtering...' : "Filter"}</Button>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Card.Body>
                    </Card>

                </Form>
            </Collapse>
        </>

    )
}

export default JobsFilter