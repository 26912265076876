import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FormDatePicker from '../../components/forms/formDatePicker';
import FormInput from '../../components/forms/formInput';
import OverlayLoader from '../../components/overlayLoader';
// import ExpenseTypesSelect from '../../components/selects/expenseTypesSelect';
import { createExpense, getById, updateExpense } from '../../services/expenses';
import { isAdmin, toLocalDateString, trimProps } from '../../utils/helper';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
// import SetExpenseTypeModal from '../expenseTypes/expenseTypeSet';

const initExpense = {
    category: '',
    description: '',
    amount: '',
    date: new Date(),
    paymentMethod: ''
}

const SetExpenseModal = ({
    display,
    toggle,
    expenseId,
    onClose,
}) => {

    const [expense, setExpense] = useState(initExpense)
    // const [expenseTypeModal, setExpenseTypeModal] = useState(false)

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation( expenseId ? updateExpense : createExpense, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['expenses', 'paginated'])
            // queryClient.invalidateQueries('cash-flow')
            expenseId && queryClient.invalidateQueries(['expenses','details', expenseId])
            onHide()
        })
    })

    const { data, isFetching } = useQuery(['expenses','details', expenseId], () => expenseId && getById(expenseId), {
        staleTime: 1000 * 60 * 2,
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            // const _expense = trimProps(result, initExpense, [])
            // _expense.date = toLocalDateString(_expense.date)
            setExpense(result)
        })
    }, [data])

    const onShow = () => {
        !expenseId && setExpense(initExpense)
    }

    const onHide = () => {
        toggle(false)
        onClose()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        mutate(expense)
    }

    const handleChange = (objValue) => {
        const newExpense = { ...expense, ...objValue }
        setExpense(newExpense)
    }

    // const handleIsRecurringChange = (e) => {
    //     const isRecurring = e.target.checked
    //     if(isRecurring) {
    //         handleChange({ isRecurring })
    //     } else {
    //         handleChange({ isRecurring , recurringPeriod: '' })
    //     }
    // }

    return (
        <>
            {/* <SetExpenseTypeModal display={expenseTypeModal} toggle={setExpenseTypeModal} /> */}

            <Modal
                show={display}
                onHide={onHide}
                size="lg"
                onShow={onShow}
                centered
                backdrop={isLoading ? "static" : true}
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{expenseId ? 'Edit' : 'Add'} Expense</h4>

                    <hr />

                    <Form id='set-expense' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            {/* <Col lg="12" className='d-flex'>
                                <div className='flex-fill'>
                                <ExpenseTypesSelect className="mb-2" label="Type" value={expense.type ?? ''}
                                    onChange={(option) => handleChange({ type: option.value })}
                                    placeholder='Select Type'
                                    required
                                />
                                </div>
                                {isAdmin() &&
                                    <Form.Group>
                                        <Form.Label>&nbsp;</Form.Label>
                                        <div className='px-2 py-1'>
                                            <i className="fa-solid fa-square-plus action"
                                                title='Add Expense Type'
                                                onClick={() => setExpenseTypeModal(true)}
                                                style={{ fontSize: '2em' }}>
                                            </i>
                                        </div>
                                    </Form.Group>
                                }
                            </Col> */}
                            <Col lg="6">
                                <FormInput className="mb-2" label="Amount" value={expense.amount ?? ''}
                                    onChange={(e) => handleChange({ amount: e.target.value })}
                                    type='number' min='1' step='0.01' placeholder='Enter Amount'
                                    required
                                />
                            </Col>
                            <Col lg="6">
                                <FormDatePicker className="form-control mb-2" label="Date" selected={expense.date ? new Date(expense.date) : null}
                                    onChange={(date) => handleChange({ date })}
                                    required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="mb-2" label="Payment Method" value={expense.paymentMethod ?? ''}
                                    onChange={(e) => handleChange({ paymentMethod: e.target.value })}
                                    placeholder='Enter Payment Method'
                                    required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="mb-2" label="Category" value={expense.category ?? ''}
                                    onChange={(e) => handleChange({ category: e.target.value })}
                                    placeholder='Enter Category'
                                    required
                                />
                            </Col>
                            {/* <Col lg="12" className='mt-2'>
                                <Form.Group>
                                    <Form.Check label="Is Recurring" value={expense.isRecurring} checked={expense.isRecurring} onChange={handleIsRecurringChange} />
                                </Form.Group>
                            </Col>
                            { expense.isRecurring &&
                            <Col lg="12">
                                <FormInput className="mb-2" label="Recurring Period" value={expense.recurringPeriod ?? ''}
                                    onChange={(e) => handleChange({ recurringPeriod: e.target.value })}
                                    placeholder='Enter Recurring Period'
                                    required
                                />
                            </Col>
                            } */}
                            <Col lg="12">
                                <FormInput className="form-control mb-2" label="Description" value={expense.description ?? ''}
                                    onChange={(e) => handleChange({ description: e.target.value })}
                                    as='textarea'
                                />
                            </Col>
                        </Row>

                        <hr />
                        <div className='d-flex justify-content-end'>
                            <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>

                        { isFetching &&
                            <OverlayLoader />
                        }
                        
                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default SetExpenseModal