import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteEmployee, getEmployees } from '../../../services/employees';
import { onMutateSuccess, onQuerySuccess } from '../../../utils/queryResponse';
import SetEmployeeModal from './employeeSet';
import SortableTable from 'reactstrap-sortable-table';
import OverlayLoader from '../../../components/overlayLoader';
import ConfirmationModal from '../../../components/modals/confirmation';

const columns = ['firstName', 'lastName']

const EmployeesPage = () => {

    const [setEmployeeModal, setSetEmployeeModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [employees, setEmployees] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState()

    const { data, isFetching } = useQuery('employees', getEmployees)

    const queryClient = useQueryClient()
    const { mutate, isLoading: isDeleting } = useMutation(deleteEmployee, {
        onSuccess: onMutateSuccess((result) => {
            queryClient.invalidateQueries(['employees'])
        })
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setEmployees(result)
        })
    }, [data])

    const handleSetEmployee = (employee) => {
        setSelectedEmployee(employee)
        setSetEmployeeModal(true)
    }

    const handleConfirmation = (employee) => {
        setSelectedEmployee(employee)
        setConfirmationModal(true)
    }

    const handleDeleteEmployee = () => {
        mutate(selectedEmployee.id)
        setSelectedEmployee()
    }

    return (
        <>
            <SetEmployeeModal display={setEmployeeModal} toggle={setSetEmployeeModal} employeeId={selectedEmployee?.id ?? ''} />
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Employee'
                message="Are you sure you want to delete this employee?" confirmAction={handleDeleteEmployee} />

            <Row>
                <Col>
                    <h4 className="page-title admin">Employees</h4>

                    <Card className="mb-4">
                        <Card.Body>

                            <div className="mb-3">
                                <Button variant="secondary" size="sm" className="py-0 me-2" onClick={() => handleSetEmployee()}>
                                    <small><i className="fa-solid fa-plus"></i></small> Add Employee
                                </Button>
                            </div>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        <SortableTable
                                            responsive
                                            size="sm"
                                            data={employees}
                                            setData={setEmployees}
                                            columns={columns}
                                            firstColumnRender={(employee) => <>
                                                <i className="fa-solid fa-pencil fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleSetEmployee(employee)}></i>
                                                <i className="fa-solid fa-trash fa-sm" style={{ cursor: 'pointer' }} onClick={() => handleConfirmation(employee)}></i>
                                            </>}
                                            addProps={{ tBodyRow: { className: 'align-middle' } }}
                                        />

                                        {(isFetching || isDeleting) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </>
    );
};

export default EmployeesPage;