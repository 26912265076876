import { Badge, Row, Col, Card } from "react-bootstrap"
import { useQuery } from "react-query"
import { getJobAssigneesReport } from "../../../services/jobs"
import SortableTable from "reactstrap-sortable-table";
import { useEffect, useState } from "react";
import { onQuerySuccess } from "../../../utils/queryResponse";
import { initColumns } from "../../../utils/helper";
import JobAssigneesReportFilter from "./jobAssigneesFilter";
import OverlayLoader from "../../../components/overlayLoader";
import { useNavigate } from "react-router-dom";

function JobAssignees() {
  const [reportData, setReportData] = useState([])
  const [filter, setFilter] = useState([])
  const navigate = useNavigate()

  const { data, isFetching } = useQuery(
    ['jobs', 'assignees-report', ...filter],
    () => getJobAssigneesReport(...filter)
  )

  useEffect(() => {
    onQuerySuccess(data, (result) => {
      setReportData(result)
    })
  }, [data])

  return (
    <>
      <JobAssigneesReportFilter onFilter={setFilter} />
      <Row className="my-3">
        <Col>
          <Card>
            <Card.Body>
              <Row className="pb-3">
                <Col className="my-auto">
                  <h4 className="page-title mb-3">Assignees Reports</h4>
                  <div style={{ position: 'relative' }}>
                    {reportData.length ?
                      <SortableTable
                        data={reportData}
                        columns={initColumns(reportData, ['id'])}
                        columnRender={[
                          {
                            column: 'totalPickup', render: (value) => value && (
                                <h6 className="d-inline">
                                    <Badge bg="warning" className="ps-2 pe-1 me-1 cursor-default">
                                        <span className="me-1">{value.m2} sqm</span>
                                    </Badge>
                                    <Badge bg="warning" className="ps-2 pe-1 me-1 cursor-default">
                                        <span className="me-1">{value.piece} {value.piece > 1 ? "pieces": "piece"}</span>
                                    </Badge>
                                </h6>
                            )
                          },
                          {
                            column: 'totalCleaned', render: (value) => value && (
                                <h6 className="d-inline">
                                    <Badge bg="info" className="ps-2 pe-1 me-1 cursor-default">
                                        <span className="me-1">{value.m2} sqm</span>
                                    </Badge>
                                    <Badge bg="info" className="ps-2 pe-1 me-1 cursor-default">
                                        <span className="me-1">{value.piece} {value.piece > 1 ? "pieces": "piece"}</span>
                                    </Badge>
                                </h6>
                            )
                          },
                          {
                            column: 'totalDelivered', render: (value) => value && (
                                <h6 className="d-inline">
                                    <Badge bg="success" className="ps-2 pe-1 me-1 cursor-default">
                                        <span className="me-1">{value.m2} sqm</span>
                                    </Badge>
                                    <Badge bg="success" className="ps-2 pe-1 me-1 cursor-default">
                                        <span className="me-1">{value.piece} {value.piece > 1 ? "pieces": "piece"}</span>
                                    </Badge>
                                </h6>
                            )
                          }
                        ]}
                        firstColumnRender={(d) =>
                          <div className="mt-1">
                              <i className="fa-solid fa-eye fa-sm me-1" title="view job history" style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/reports/jobs-history/?deliveryAssignedToId=${d.id}&cleaningAssignedToId=${d.id}&pickupAssignedToId=${d.id}`)}>
                              </i>
                          </div>
                        }
                      />
                      :
                      <>
                        <hr />
                        <span className="fst-italic">no reports found</span>
                      </>
                    }
                    {(isFetching) &&
                      <OverlayLoader />
                    }
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default JobAssignees