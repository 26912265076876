import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getBadgeById, getAvailableBadges } from '../../services/badges';
import { toTitleCase } from '../../utils/helper';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';

const BadgeSelect = ({ value, isLoading, options, isMulti, ...rest }) => {

    // const [options, setOptions] = useState([])
    // const [isLoading, setIsLoading] = useState(false)

    // const { data, isLoading } = useQuery(['available-badges'], () => getAvailableBadges())

    // useEffect(() => {
    //     onQuerySuccess(data, (result) => {
    //         if (result.length > 0) {
    //             setOptions(result.map(d => ({
    //                 value: d.id, label: d.badgeNo
    //             })))
    //         }
    //     })
    // }, [data])

    const uniqueOptions = Array.from(new Map(options.map(item => [item.value, item])).values())

    return (
        <FormSelect isMulti={isMulti} {...rest}
            value={
                isMulti ? (value && value.map(val => ({ value: val, label: val }))) :
                    (uniqueOptions?.find(opt => opt.value === value) || null)
            }
            options={uniqueOptions}
            isLoading={isLoading}
        />
    )
}

export default BadgeSelect