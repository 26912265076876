import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useEffect, useState } from 'react';
import { Button, Col, Collapse, Form, FormSelect, Modal, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import DatePicker from '../../components/forms/formDatePicker';
import ClientInfoModal from '../../pages/clients/clientInfo';
import SetClientModal from '../../pages/clients/clientsSet';
import { getAvailableBadges } from '../../services/badges';
import { getUsersAsOptions } from '../../services/companyUsers';
import { createJob } from '../../services/jobs';
import { roundDecimal, rsdFormat } from '../../utils/helper';
import { isCleaningAndOnwards, isCleaningCompletedAndOnwards } from '../../utils/jobHelper';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormInput from '../forms/formInput';
import OverlayLoader from '../overlayLoader';
import AssigneeSelect from '../selects/assigneeSelect';
import BadgeSelect from '../selects/badgeSelect';
import ClientsSelect from '../selects/clientsSelect';
import ServiceSelect from '../selects/serviceSelect';
import StatusSelector from '../statusSelector';

const initJob = {
    clientId: '',
    pickupType: 'PICKUP_FROM_HOME',
    pickupTime: null,
    status: 'SCHEDULED',
    dispatchTime: null,
    completionTime: null,
    paymentStatus: 'PENDING',
    paymentMethod: null,
    lineItemsTemp: [{
        quantity: 0,
        price: 0,
        taxRate: 0,
        taxAmount: 0,
        priceWithTax: 0,
        serviceId: '',
        badgeId: ''
    }],
    paymentType: null,
    paymentTime: null,
    pickupAssignedToId: null,
    cleaningAssignedToId: null,
    deliveryAssignedToId: null,
    note: ''
}

const SetOrderModal = ({
    display,
    toggle,
    pickupTime
}) => {

    const [setClientModal, setSetClientModal] = useState(false)
    const [clientInfoModal, setClientInfoModal] = useState(false)

    const [isAdvance, setIsAdvance] = useState(false)

    const [clientRawName, setClientRawName] = useState('')
    const [clientOptions, setClientOptions] = useState([])
    const [assigneesOptions, setAssigneesOptions] = useState([])
    const [availableBadges, setAvailableBadges] = useState([])
    const [selectedBadges, setSelectedBadges] = useState([])

    const [job, setJob] = useState(initJob)

    const onShow = () => {
        setJob(initJob)
        pickupTime && setJob({ ...initJob, pickupTime })
    }

    const { data: dbAssigneesOptions, isLoading: isLoadingAssigneesOptions } = useQuery(['users-option'], () => getUsersAsOptions())
    const { data: dbAvailableBadges, isLoading: isAvailableBadgesLoading, isFetching: isAvailableBadgesFetching } = useQuery(['available-badges'], () => getAvailableBadges())

    useEffect(() => {

        if (!dbAssigneesOptions || !dbAvailableBadges)
            return

        onQuerySuccess(dbAssigneesOptions, (result) => {
            if (result.length > 0) {
                setAssigneesOptions(result.map(d => ({
                    value: d.id, label: d.fullName, roles: d.roles
                })))
            }
        })

        onQuerySuccess(dbAvailableBadges, (result) => {
            if (result.length > 0) {
                setAvailableBadges(result.map(d => ({
                    value: d.id, label: d.badgeNo
                })))
            }
        })


    }, [dbAssigneesOptions, dbAvailableBadges])

    const onHide = () => {
        setIsAdvance(false)
        let _initJob = initJob
        _initJob.lineItemsTemp = [{
            quantity: 0,
            price: 0,
            taxRate: 0,
            taxAmount: 0,
            priceWithTax: 0,
            serviceId: '',
            badgeId: ''
        }]
        setJob({ ...initJob })
        toggle(false)
    }

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(createJob, {
        onSuccess: (response, payload) => {
            const { code, message: responseMessage } = response

            if (code !== 200) {
                if (code === 403) {
                    toast.error("You are not allowed", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                    return
                }
                toast.error(responseMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                queryClient.invalidateQueries(['available-badges'])
                return
            }

            queryClient.invalidateQueries(['jobs'])
            queryClient.invalidateQueries(['calendar-data'])
            queryClient.invalidateQueries(['available-badges'])
            setJob(initJob)
            onHide()
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        job.price = parseFloat(getTotalPrice())
        job.lineItems = job.lineItemsTemp.map((x => {
            return {
                quantity: x.quantity,
                price: x.price,
                taxRate: x.taxRate,
                taxAmount: x.taxAmount,
                priceWithTax: x.priceWithTax,
                serviceId: x.serviceId,
                badgeId: x.badgeId,
                unit: x.unit,
                length: x.unit === 'm2' ? x.length : undefined,
                width: x.unit === 'm2' ? x.width : undefined,
            }
        }))

        mutate(job)
    }

    const handleChange = (objValue) => {

        const newOrder = { ...job, ...objValue }
        if (newOrder.pickupType === "CUSTOMER_DELIVERS")
            newOrder.pickupTime = null
        if (newOrder.pickupType === "PICKUP_FROM_HOME")
            newOrder.deliveredByCustomerTime = null

        setJob(newOrder)
    }

    const handleAddLineItem = () => {
        let _order = job
        let _lineItems = job.lineItemsTemp
        _lineItems.push({
            quantity: 0,
            price: 0,
            taxRate: 0,
            taxAmount: 0,
            priceWithTax: 0,
            serviceId: '',
            badgeId: ''
        })
        _order.lineItemsTemp = _lineItems
        setJob({ ..._order })
    }

    const handleRemoveLineItem = (index) => {
        let _order = job
        let _lineItems = job.lineItemsTemp
        _lineItems.splice(index, 1)
        _order.lineItemsTemp = _lineItems

        const _selectedBadges = _lineItems.map(x => x.badgeId)
        setSelectedBadges(_selectedBadges)

        setJob({ ..._order })
    }

    const handleLineItemChange = (objValue, index) => {

        const _lineItems = [...job.lineItemsTemp]; // Create a new copy of the array
        _lineItems[index] = { ..._lineItems[index], ...objValue }

        let _quantity = _lineItems[index].quantity

        if (_lineItems[index].unit === 'm2') {
            const length = +_lineItems[index].length || 0
            const width = +_lineItems[index].width || 0
            _quantity = roundDecimal(length * width, 2)
        }

        _lineItems[index].quantity = _quantity

        let _price = +_lineItems[index].pricePerUnit * +_lineItems[index].quantity
        _lineItems[index].price = roundDecimal(_price, 2)

        let _taxAmount = (+_lineItems[index].taxRate / 100) * +_lineItems[index].price
        _lineItems[index].taxAmount = roundDecimal(_taxAmount, 2)

        let _priceWithTax = +_lineItems[index].price + +_lineItems[index].taxAmount
        _lineItems[index].priceWithTax = roundDecimal(_priceWithTax, 2)

        const _selectedBadges = _lineItems.map(x => x.badgeId)
        setSelectedBadges(_selectedBadges)

        setJob((prevJob) => ({
            ...prevJob,
            lineItemsTemp: _lineItems // Update the state with the new array
        }));
    }

    const getTotalPrice = () => {
        var sum = job.lineItemsTemp?.reduce(function (acc, obj) {
            return parseFloat(acc) + parseFloat(obj.priceWithTax);
        }, 0);
        return rsdFormat(sum)
    }

    const onCreateClient = (newClient) => {

        let _clientOptions = clientOptions
        _clientOptions.push({
            value: newClient.id,
            label: newClient.name
        })

        let _job = job
        _job.clientId = newClient.id
        setJob({ ..._job })
    }

    const handleSelectDate = (prop, date) => {
        let _date = date

        const dateObj = new Date(date)
        //check if midnight, means no time is selected then set to 8:00 as default
        if (dateObj.getHours() === 0 && dateObj.getMinutes() === 0 && dateObj.getSeconds() === 0) {
            _date = setHours(setMinutes(date, 0), 8)
        }

        handleChange({ [prop]: _date })
    }

    const handlePaymentStatusChange = (checked) => {
        handleChange({ paymentStatus: checked ? 'PAID' : 'PENDING' })
    }

    const handleServiceSelect = (option, index) => {
        handleLineItemChange({ serviceId: option.value, pricePerUnit: parseFloat(option.ppu), unit: option.unit, quantity: 0 }, index)
    }

    const getDispatchMaxTime = (completionDateTime) => {
        let maxTime = setHours(setMinutes(new Date(), 59), 23)
        let dispatchTime = new Date(completionDateTime).getTime()
        if (dispatchTime < maxTime)
            return new Date(completionDateTime).setMinutes(new Date(completionDateTime).getMinutes() - 5);
        else
            return maxTime
    }

    const getCompletionMinTime = (dispatchDateTime) => {
        let minTime = setHours(setMinutes(new Date(), 0), 5)
        let dispatchTime = new Date(dispatchDateTime).getTime()
        if (minTime < dispatchTime)
            return new Date(dispatchDateTime).setMinutes(new Date(dispatchDateTime).getMinutes() + 15);
        else
            return minTime
    }

    const getLineItemMinQuantity = (lineItemUnit) => {

        if (job.status === "CLEANING_COMPLETED" ||
            job.status === "DISPATCH_IN_PROGRESS" ||
            job.status === "COMPLETED"
        ) {
            return lineItemUnit === 'Piece' ? 1 : 0.01
        } else {
            return 0
        }
    }

    return (
        <>
            <SetClientModal display={setClientModal} toggle={setSetClientModal} onCreateClient={onCreateClient} /*suggestName={clientRawName}*/ suggestName={''} />
            <ClientInfoModal display={clientInfoModal} toggle={setClientInfoModal} clientId={job.clientId} />

            <Modal
                show={display}
                onHide={onHide}
                size="xl"
                onShow={onShow}
                centered
                backdrop="static"
                style={{ overflowY: "scroll" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ width: '98%' }}>
                        <h4 className="text-center mt-3">Create New Job</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-1' >

                    <Form id='set-job' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col xs="12">
                                <Row className="mb-2">
                                    <Col lg="6">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label>Client <span className="text-danger">*</span></Form.Label>
                                            <div>
                                                <span className="me-2 text-muted"><small>Client not found?</small></span>
                                                <Button style={{ fontSize: '0.70rem' }} className="mb-1 py-0 px-2" size="sm" variant="light" onClick={() => setSetClientModal(true)}><i className="fas fa-plus"></i> Add New</Button>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div style={{ width: '90%' }}>
                                                    <ClientsSelect value={job.clientId} onChange={(option) => handleChange({ clientId: option.value })} options={clientOptions} setOptions={setClientOptions} setClientRawName={setClientRawName} />
                                                </div>
                                                {job.clientId &&
                                                    <div style={{ width: '10%' }}>
                                                        <div className="d-flex center-xy">
                                                            <i title="view client details" onClick={() => setClientInfoModal(true)} className="fa-solid fa-circle-info text-muted cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-2">
                                    <Form.Label>Pickup type <span className="text-danger">*</span></Form.Label>
                                    <FormSelect value={job.pickupType} onChange={(e) => handleChange({ pickupType: e.target.value })} >
                                        <option value="" disabled>Select Pickup Type</option>
                                        <option value="PICKUP_FROM_HOME">Pickup From Home</option>
                                        <option value="CUSTOMER_DELIVERS">Customer Delivers</option>
                                    </FormSelect>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <DatePicker selected={job.pickupType === "CUSTOMER_DELIVERS" ? job.deliveredByCustomerTime : job.pickupTime}
                                    label={job.pickupType === "CUSTOMER_DELIVERS" ? 'Customer Deliver Time' : 'Pickup Time'}
                                    onChange={(date) => handleChange({ [job.pickupType === "CUSTOMER_DELIVERS" ? 'deliveredByCustomerTime' : 'pickupTime']: date })}
                                    onSelect={(date) => handleSelectDate(job.pickupType === "CUSTOMER_DELIVERS" ? 'deliveredByCustomerTime' : 'pickupTime', date)}
                                    showTimeSelect
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    minTime={setHours(setMinutes(new Date(), 0), 5)}
                                    maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                    dateFormat="dd.MM.yyyy h:mm aa"
                                    className="form-control mb-2"
                                    required={job.pickupType === "PICKUP_FROM_HOME" || job.pickupType === "CUSTOMER_DELIVERS"}
                                />
                            </Col>
                            <Col xs="12">
                                <Button style={{ width: "100%" }} className="btn-xs" variant='light' onClick={() => setIsAdvance(!isAdvance)}><li className={`fa-solid ${isAdvance ? 'fa-angles-up' : 'fa-angles-down'} fa-2xs`}></li></Button>
                            </Col>
                            <Collapse in={isAdvance}>
                                <Col lg="12">
                                    <div className="my-4">
                                        <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                                        <StatusSelector value={job.status} onChange={(status) => handleChange({ status: status })} />
                                    </div>
                                </Col>
                                {/* <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                                        <FormSelect value={job.status} onChange={(e) => handleChange({ status: e.target.value })} >
                                            <option value="" disabled>Select Status</option>
                                            <option value="SCHEDULED">Scheduled</option>
                                            <option value="ASSIGNED">Assigned</option>
                                            <option value="PICKUP_IN_PROGRESS">Pickup in Progress</option>
                                            <option value="PICKUP_COMPLETED">Pickup Completed</option>
                                            <option value="CLEANING">Cleaning</option>
                                            <option value="CLEANING_COMPLETED">Cleaning Completed</option>
                                            <option value="DISPATCH_IN_PROGRESS">Dispatch in Progress</option>
                                            <option value="COMPLETED">Completed</option>
                                        </FormSelect>
                                    </Form.Group>
                                </Col> */}
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <DatePicker selected={job.dispatchTime}
                                        label='Dispatch Time' placeholderText=''
                                        onChange={(date) => handleChange({ dispatchTime: date })}
                                        onSelect={(date) => handleSelectDate('dispatchTime', date)}
                                        showTimeSelect
                                        timeIntervals={15}
                                        maxDate={job.completionTime}
                                        minTime={setHours(setMinutes(new Date(), 0), 5)}
                                        maxTime={getDispatchMaxTime(job.completionTime)}
                                        timeCaption="Time"
                                        dateFormat="dd.MM.yyyy h:mm aa"
                                        className="form-control mb-2"
                                        required={job.status === 'DISPATCH_IN_PROGRESS'}
                                    />
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <DatePicker selected={job.completionTime}
                                        label='Expected Delivery' placeholderText=''
                                        onChange={(date) => handleChange({ completionTime: date })}
                                        onSelect={(date) => handleSelectDate('completionTime', date)}
                                        showTimeSelect
                                        timeIntervals={15}
                                        minDate={job.dispatchTime}
                                        minTime={getCompletionMinTime(job.dispatchTime)}
                                        maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                        timeCaption="Time"
                                        dateFormat="dd.MM.yyyy h:mm aa"
                                        className="form-control mb-2"
                                    />
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Pickup Assignee'
                                            value={job.pickupAssignedToId ?? ''}
                                            assigneesOptions={assigneesOptions}
                                            filterRole={'driver'}
                                            onChange={(option) => handleChange({ pickupAssignedToId: option?.value })}
                                            required={(job.status === 'ASSIGNED' || job.status === 'PICKUP_IN_PROGRESS' ||
                                                job.status === 'PICKUP_COMPLETED') && job.pickupType === 'PICKUP_FROM_HOME'
                                            }
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Cleaning Assignee'
                                            value={job.cleaningAssignedToId ?? ''}
                                            assigneesOptions={assigneesOptions}
                                            filterRole={'cleaner'}
                                            onChange={(option) => handleChange({ cleaningAssignedToId: option?.value })}
                                            required={job.status === 'CLEANING_COMPLETED' || job.status === 'CLEANING'}
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Delivery Assignee'
                                            assigneesOptions={assigneesOptions}
                                            filterRole={'driver'}
                                            value={job.deliveryAssignedToId ?? ''}
                                            onChange={(option) => handleChange({ deliveryAssignedToId: option?.value })}
                                            required={job.status === 'DISPATCH_IN_PROGRESS' || job.status === 'COMPLETED'}
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Payment Status</Form.Label>
                                        <div>
                                            <BootstrapSwitchButton
                                                checked={job.paymentStatus === "PAID" ? true : false}
                                                onlabel='Paid'
                                                offlabel='Pending'
                                                onChange={(checked) => handlePaymentStatusChange(checked)}
                                                width={150}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            {job.paymentStatus === "PAID" &&
                                <>
                                    <Collapse in={isAdvance}>
                                        <Col lg="3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Payment Method <span className="text-danger">*</span></Form.Label>
                                                <FormSelect value={job.paymentMethod ?? ""} onChange={(e) => handleChange({ paymentMethod: e.target.value })} required>
                                                    <option value="" disabled>Select Payment Method</option>
                                                    <option value="CASH">Cash</option>
                                                    <option value="CREDIT_CARD">Credit Card</option>
                                                    <option value="CHECK">Check</option>
                                                </FormSelect>
                                            </Form.Group>
                                        </Col>
                                    </Collapse>
                                    <Collapse in={isAdvance}>
                                        <Col lg="3">
                                            <DatePicker selected={job.paymentTime}
                                                label='Payment Time' placeholderText=''
                                                onChange={(date) => handleChange({ paymentTime: date })}
                                                onSelect={(date) => handleSelectDate('paymentTime', date)}
                                                showTimeSelect
                                                timeIntervals={15}
                                                minTime={setHours(setMinutes(new Date(), 0), 5)}
                                                maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                                timeCaption="Time"
                                                dateFormat="dd.MM.yyyy h:mm aa"
                                                className="form-control mb-2"
                                                required
                                            />
                                        </Col>
                                    </Collapse>
                                    <Collapse in={isAdvance}>
                                        <Col lg="3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Payment Type <span className="text-danger">*</span></Form.Label>
                                                <FormSelect value={job.paymentType ?? ""} onChange={(e) => handleChange({ paymentType: e.target.value })} required>
                                                    <option value="" disabled>Select Payment Type</option>
                                                    <option value="BEFORE_SERVICE">Before Service</option>
                                                    <option value="DURING_SERVICE">During Service</option>
                                                    <option value="AFTER_SERVICE">After Service</option>
                                                </FormSelect>
                                            </Form.Group>
                                        </Col>
                                    </Collapse>
                                </>
                            }
                            <Collapse in={isAdvance}>
                                <Col lg="12" className="mb-3">
                                    <FormInput as="textarea" label="Notes" value={job.note} onChange={(e) => handleChange({ note: e.target.value })} />
                                </Col>
                            </Collapse>

                            <div className="mt-3 text-end">
                                <span size="sm" variant="outline-secondary" className="border-0 cursor-pointer" onClick={() => queryClient.invalidateQueries(['available-badges'])}>
                                    <small><i className={`fa-solid fa-arrows-rotate ${isAvailableBadgesFetching ? 'fa-spin' : ''} text-muted me-1`}></i> refresh available badges</small>
                                </span>
                            </div>

                            {/* <Col lg="12"> */}
                            <div className="mb-2 p-3 my-2" style={{ backgroundColor: '#f8f9fa' }}>
                                {job?.lineItemsTemp?.length > 0 && job.lineItemsTemp.map((lineItem, index) => (
                                    <div key={`line-item-${index}`}>
                                        <Row>
                                            <Col xs="12">
                                                <Row>
                                                    <Col xl="3" className="pe-0">
                                                        <Form.Group className="mb-2">
                                                            <Form.Label>
                                                                {index > 0 &&
                                                                    <Button onClick={() => handleRemoveLineItem(index)} size="sm" variant="outline-secondary" style={{ fontSize: '0.75rem', border: 0 }}><i className="fa-solid fa-trash text-muted"></i></Button>
                                                                }
                                                                <strong>Item #{index + 1}</strong>&nbsp;
                                                                {lineItem.serviceId ? ` - ${lineItem.pricePerUnit} per ${lineItem.unit}` : ''}
                                                                <span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <ServiceSelect required value={lineItem.serviceId ?? ''} onChange={(option) => handleServiceSelect(option, index)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xl="2" className="pe-0">
                                                        <Form.Group className="mb-2">
                                                            <Form.Label>Badge Number {isCleaningAndOnwards(job) && <span className="text-danger">*</span>}</Form.Label>
                                                            <BadgeSelect required={isCleaningAndOnwards(job)} value={lineItem.badgeId ?? ''}
                                                                isLoading={isAvailableBadgesLoading}
                                                                // options={availableBadges}
                                                                options={(availableBadges).filter(x => !selectedBadges.includes(x.value) || x.value === lineItem.badgeId).sort(function (a, b) { return a.label - b.label })}
                                                                onChange={(option) => handleLineItemChange({ badgeId: option.value }, index)} />
                                                        </Form.Group>
                                                    </Col>
                                                    {lineItem.unit === 'm2' &&
                                                        <>
                                                            <Col xl={lineItem.unit === 'm2' ? 1 : 2} className="pe-0">
                                                                <FormInput value={lineItem.width ?? ''} className="mb-2"
                                                                    label="Width"
                                                                    type="number" min={0.01}
                                                                    step="0.01"
                                                                    onChange={(e) => handleLineItemChange({ width: e.target.value }, index)}
                                                                    required={isCleaningCompletedAndOnwards(job)}
                                                                />
                                                            </Col>
                                                            <Col xl={lineItem.unit === 'm2' ? 1 : 2} className="pe-0">
                                                                <FormInput value={lineItem.length ?? ''} className="mb-2"
                                                                    label="Length"
                                                                    type="number" min={0.01}
                                                                    step="0.01"
                                                                    onChange={(e) => handleLineItemChange({ length: e.target.value }, index)}
                                                                    required={isCleaningCompletedAndOnwards(job)}
                                                                />
                                                            </Col>
                                                        </>
                                                    }
                                                    <Col xl={lineItem.unit === 'm2' ? 1 : 2} className="pe-0">
                                                        <FormInput value={lineItem.quantity} className="mb-2"
                                                            title={rsdFormat(+lineItem.quantity, false)}
                                                            label={lineItem.serviceId ? lineItem.unit : "Quantity"}
                                                            type="number" min={getLineItemMinQuantity(lineItem.unit)} step={lineItem.unit === 'Piece' ? 1 : 0.01}
                                                            onChange={(e) => handleLineItemChange({ quantity: e.target.value }, index)}
                                                            disabled={lineItem.unit === 'm2'}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col xl={lineItem.unit === 'm2' ? 1 : 2} className="pe-0">
                                                        <FormInput disabled value={lineItem.price} className="mb-2"
                                                            title={rsdFormat(+lineItem.price, false)}
                                                            label="Price" type="number" min="0.01" step="0.01"
                                                            onChange={(e) => handleLineItemChange({ price: e.target.value }, index)}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col xl="1" className="pe-0">
                                                        <FormInput value={lineItem.taxRate} className="mb-2" label="Tax %" type="number" step='0.01' onChange={(e) => handleLineItemChange({ taxRate: e.target.value }, index)} />
                                                    </Col>
                                                    {/* <Col lg="4">
                                                            <FormInput disabled value={lineItem.taxAmount} className="mb-2" label="Tax Amount" type="number" onChange={(e) => handleLineItemChange({ taxAmount: parseInt(e.target.value) }, index)} />
                                                        </Col> */}
                                                    <Col xl="2">
                                                        <FormInput disabled value={lineItem.priceWithTax} className="mb-2" label="Total"
                                                            title={rsdFormat(+lineItem.priceWithTax, false)}
                                                            type="number" min="0.01" step="0.01" required
                                                            onChange={(e) => handleLineItemChange({ priceWithTax: e.target.value }, index)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {/* <Col>
                                                    {index > 0 &&
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <div className="center-xy mt-1">
                                                                <Button onClick={() => handleRemoveLineItem(index)} size="sm" variant="outline-secondary" style={{ fontSize: '0.75rem' }}><i className="fa-solid fa-trash text-muted"></i></Button>
                                                            </div>
                                                        </Form.Group>
                                                    }
                                                </Col> */}
                                        </Row>
                                        <hr />
                                    </div>
                                ))}
                                <div className="text-end mt-3">
                                    <Button size="sm" variant="outline-primary" className="px-4" onClick={() => handleAddLineItem()}>Add</Button>
                                </div>
                            </div>
                            {/* </Col> */}
                            <div className="text-end mt-2">
                                <h5 className="text-bold">Total Price: &emsp; {getTotalPrice()}</h5>
                            </div>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            {/* <Button variant="outline-secondary" onClick={() => {}}>Delete</Button> */}
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>

                        {(isLoadingAssigneesOptions || isAvailableBadgesLoading) &&
                            <OverlayLoader />
                        }

                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default SetOrderModal