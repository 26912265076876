import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getServiceCategories } from '../../services/services';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';

const ServiceCategorySelect = ({ value, isMulti, ...rest }) => {

    const [options, setOptions] = useState([])

    const { data, isLoading } = useQuery(['service-category'], () => getServiceCategories())

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            if (result.length > 0) {
                setOptions(result.map(d => ({
                    value: d.id, label: d.name
                })))
            }
        })
    }, [data])

    return (
        <FormSelect isMulti={isMulti} {...rest}
            value={
                isMulti ? (value && value.map(val => ({ value: val, label: val }))) :
                    (options.find(opt => opt.value === value) || null)
            }
            options={options}
            isLoading={isLoading}
        />
    )
}

export default ServiceCategorySelect