import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import RouteContext from './components/routeContext';
import NotFoundPage from './pages/404';
import AdminPage from './pages/admin/admin';
import EmployeesPage from './pages/admin/employees/employees';
import ServiceCategoriesPage from './pages/admin/serviceCategories';
import UsersPage from './pages/admin/users';
import CalendarPage from './pages/calendar/calendar';
import CashFlowPage from './pages/cashflow/cashflow';
import CashFlowTransactionsPage from './pages/cashflowTransactions/cashflowTransactions';
import ClientsPage from './pages/clients/clients';
import ExpenseTypesPage from './pages/expenseTypes/expenseTypes';
import ExpensesPage from './pages/expenses/expenses';
import HomePage from './pages/home';
import JobsPage from './pages/jobs/jobs';
import LoginPage from './pages/login';
import PayrollsPage from './pages/payrolls/payrolls';
import ProfilePage from './pages/profile/profile';
import RegisterPage from './pages/register';
import SalesPage from './pages/sales/sales';
import ServicesPage from './pages/services/services';
import ThankYouPage from './pages/thankyou';
import DashboardTemplate from './templates/dashboard';
import BadgesPage from './pages/admin/badges/badges';
import JobReportsPage from './pages/reports/jobReports/jobReports';
import SocketContext, { socket } from './context/socket';
import NotificationsPage from './pages/notifications/notifications';
import JobsPerformancePage from './pages/reports/jobReports/jobsPerformance';
import ExpensesReportsPage from './pages/reports/jobReports/expensesReports';
import ServicesReportsPage from './pages/reports/servicesReports/servicesReports';
import JobAssignees from './pages/reports/jobReports/jobAssignees';

function App(props) {

  return (
    <>
    <SocketContext.Provider value={socket}>
      <ToastContainer />
      <Routes>
        
        <Route element={<DashboardTemplate />}>
          <Route index element={<HomePage />} />
          <Route path="home" element={<RouteContext><HomePage /></RouteContext>} />
          <Route path="profile" element={<RouteContext><ProfilePage /></RouteContext>} />
          <Route path="jobs" element={<RouteContext><JobsPage /></RouteContext>} />
          <Route path="sales" element={<RouteContext><SalesPage /></RouteContext>} />
          <Route path="clients" element={<RouteContext><ClientsPage /></RouteContext>} />
          <Route path="profile" element={<RouteContext><ProfilePage /></RouteContext>} />
          <Route path="expenses" element={<RouteContext><ExpensesPage /></RouteContext>} />
          <Route path="payrolls" element={<RouteContext><PayrollsPage /></RouteContext>} />
          <Route path="cash-flow" element={<RouteContext><CashFlowPage /></RouteContext>} />
          <Route path="cash-flow-transactions" element={<RouteContext><CashFlowTransactionsPage /></RouteContext>} />
          <Route path="calendar" element={<RouteContext><CalendarPage /></RouteContext>} />
          <Route path="reports/jobs-history" element={<RouteContext><JobReportsPage /></RouteContext>} />
          <Route path="reports/jobs-performance" element={<RouteContext><JobsPerformancePage /></RouteContext>} />
          <Route path="reports/expenses" element={<RouteContext><ExpensesReportsPage /></RouteContext>} />
          <Route path="reports/jobs-assignees" element={<RouteContext><JobAssignees /></RouteContext>} />
          <Route path="reports/services-reports" element={<RouteContext><ServicesReportsPage /></RouteContext>} />
          <Route path="services" element={<RouteContext><ServicesPage /></RouteContext>} />
          <Route path="notifications" element={<RouteContext><NotificationsPage /></RouteContext>} />

          {/* Admin Pages */}
          <Route path="settings" element={<RouteContext><AdminPage /></RouteContext>} />
          <Route path="settings/users"element={<RouteContext><UsersPage /></RouteContext>} />
          <Route path="settings/employees"element={<RouteContext><EmployeesPage /></RouteContext>} />
          <Route path="settings/service-categories"element={<RouteContext><ServiceCategoriesPage /></RouteContext>} />
          <Route path="settings/expense-types"element={<RouteContext><ExpenseTypesPage /></RouteContext>} />
          <Route path="settings/badges" element={<RouteContext><BadgesPage /></RouteContext>} />

          <Route
            path="*"
            element={
              <NotFoundPage />
            }
          />
        </Route>
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />

      </Routes>
    </SocketContext.Provider>
    </>
  );
}

export default App;