import http from './http'
import { getToken } from './auth'

const base = 'job-activities'

export async function getJobActivities(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}