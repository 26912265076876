import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormInput from '../components/forms/formInput';
import { register } from '../services/users';
import { getErrorMessages } from '../utils/errorResponse';

const RegisterPage = () => {

    const [user, setUser] = useState({
        fullName: "",
        username: "",
        password: "",
        email : ""
    })
    const [confirmPassword, setConfirmPassword] = useState("")

    const [error, setError] = useState()

    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const usersMutation = useMutation(register, {
        onSuccess: (response) => {
            const { data, status }= response

            if (status !== 200) {
                toast.error(data[0].message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                return
            }

            queryClient.invalidateQueries('users')
            toast.success(` Successfully created a user. You can now use your account to login.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })

            navigate('/login')

        },
        onError: ({ response }) => {
            toast.error(getErrorMessages(response.data.responseException), {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    })

    const handleChangeInput = (prop) => {
        return (e) => {
            const value = e.target.value
            setUser({ ...user, [prop]: value })
        }
    }

    const handleSubmit = async (e) => {

        setError()
        e.preventDefault()
        e.stopPropagation()

        if (user.password !== confirmPassword) {
            setError("Passwords do not match!")
            return
        }

        let payload = { ...user }
        
        usersMutation.mutate(payload)
    }

    return (
        <>
            <section style={{ height: '100vh', backgroundColor: '#dedede' }}>
                <div className="h-100">
                    <Row className="justify-content-center h-100 g-0">
                        <Col lg="4" className="h-100">
                            <div className="login-container d-flex align-items-center h-100">

                                <div className="w-100">

                                    <div className="w-100 text-center mb-3">
                                        <h5>Registration Form</h5>
                                    </div>

                                    <Card>
                                        <Card.Body>
                                            <Form onSubmit={handleSubmit}>
                                                <p className="text-bold mb-2">Account Information</p>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <FormInput required label="username" onChange={handleChangeInput('username')} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <FormInput required type="password" label="Password" onChange={handleChangeInput('password')} />
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormInput required type="password" label="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                                    </Col>
                                                </Row>

                                                <hr className="mt-0" />
                                                <p className="text-bold mb-2">Personal Information</p>
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <FormInput required label="Full Name" onChange={handleChangeInput('fullName')} />
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormInput required label="Email" onChange={handleChangeInput('email')} />
                                                    </Col>
                                                </Row>
                                                <hr />

                                                {/* <p className="mb-0 text-bold">Tell us about your company</p>
                                                <p className="mb-2"><small><i>You can add more company later</i></small></p>
                                                <hr className="mt-0" />
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <FormInput label="Company Name" onChange={handleChangeInput('firstCompanyName')} />
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormSelect
                                                            label={"Company Industry"}
                                                            value={user.firstCompanyIndustry}
                                                            options={industries.map((industry) => {
                                                                return {value:industry.name, label:industry.name}
                                                            })}
                                                            isClearable
                                                            onChange={(option) => setUser({ ...user, firstCompanyIndustry: option })}
                                                        />
                                                    </Col>
                                                </Row>
                                                */}

                                                <div className="mt-4 text-center">
                                                    <Button type="submit" disabled={usersMutation.isLoading} className="px-4">{usersMutation.isLoading ? 'Submitting...' : 'Submit'}</Button>
                                                </div> 

                                                <p className="mb-1 mt-2 text-center">
                                                    Already have an account? login <Link to="/login"><strong>here</strong></Link>.
                                                </p>

                                                {error &&
                                                    <p className="mt-3 mb-0 text-center text-danger"><small>{error}</small></p>
                                                }
                                            </Form>
                                        </Card.Body>
                                    </Card>

                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};

export default RegisterPage;