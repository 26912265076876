import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import OverlayLoader from '../../components/overlayLoader';
import FormInput from '../../components/forms/formInput';
import CountrySelect from '../../components/selects/countrySelect';
import { createClient, getClientById, updateClient } from '../../services/clients';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { rsdFormat, trimProps } from '../../utils/helper';

const initClient = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'Serbia',
    apartmentNo: '',
    floor: '',
    intercomName: '',
    notes: '',
    totalSQM: '',
    totalAmount: '',
}

const SetClientModal = ({
    display,
    toggle,
    clientId,
    onCreateClient,
    suggestName
}) => {

    const handleChange = (objValue) => {
        const newClient = { ...client, ...objValue }
        setClient(newClient)
    }

    const clientObj = initClient
    const suggestNameSplits = suggestName ? suggestName.split(' ') : []
    if (suggestNameSplits.length > 0) {
        clientObj.firstName = suggestNameSplits[0]
        if (suggestNameSplits.length > 1) clientObj.lastName = suggestNameSplits[1]
    }
    const [client, setClient] = useState(clientObj)

    const onShow = () => {
        !clientId && setClient(initClient)
    }

    const onHide = () => {
        toggle(false)
    }

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(clientId ? updateClient : createClient, {
        onSuccess: onMutateSuccess((response, statusCode) => {
            queryClient.invalidateQueries(['clients'])
            queryClient.invalidateQueries(['clients-option'])
            onCreateClient && onCreateClient(response)
            onHide()
        })
    })

    const { data, isFetching } = useQuery(['clients', 'details', clientId], () => clientId && getClientById(clientId), {
        staleTime: 1000 * 60 * 2,
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            const _client = trimProps(result, initClient, ['id'])
            setClient(_client)
        })
    }, [data])

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        mutate(client)
    }

    const handleSelectChange = (prop) => (date) => {
        handleChange({ ...client, [prop]: date })
    }

    return (
        <>
            <Modal
                show={display}
                onHide={onHide}
                size="lg"
                onShow={onShow}
                centered
                backdropClassName="level-2"
                backdrop={isLoading ? "static" : true}
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{clientId ? 'Edit' : 'Add'} Client</h4>

                    <hr />

                    <Form id='set-client' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="First Name" value={client.firstName ?? ''}
                                    onChange={(e) => handleChange({ firstName: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="Last Name" value={client.lastName ?? ''}
                                    onChange={(e) => handleChange({ lastName: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="Phone Number" value={client.phone ?? ''}
                                    onChange={(e) => handleChange({ phone: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput type="email" className="form-control mb-2" label="Email" value={client.email ?? ''}
                                    onChange={(e) => handleChange({ email: e.target.value })}
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Apartment No." value={client.apartmentNo ?? ''}
                                    onChange={(e) => handleChange({ apartmentNo: e.target.value })}
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Floor" value={client.floor ?? ''}
                                    onChange={(e) => handleChange({ floor: e.target.value })}
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Intercom Name" value={client.intercomName ?? ''}
                                    onChange={(e) => handleChange({ intercomName: e.target.value })}
                                />
                            </Col>
                            <Col lg="8">
                                <FormInput className="form-control mb-2" label="Street" value={client.street ?? ''}
                                    onChange={(e) => handleChange({ street: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="City" value={client.city ?? ''}
                                    onChange={(e) => handleChange({ city: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="State" value={client.state ?? ''}
                                    onChange={(e) => handleChange({ state: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <FormInput className="form-control mb-2" label="Zip" value={client.zipCode ?? ''}
                                    onChange={(e) => handleChange({ zipCode: e.target.value })} required
                                />
                            </Col>
                            <Col lg="4">
                                <CountrySelect className="mb-2" label="Country" value={client.country ?? ''}
                                    onChange={handleSelectChange('country')}
                                    placeholder='Select Country'
                                    required
                                />
                            </Col>

                            <Col lg="12">
                                <FormInput className="form-control mb-2" label="Notes" value={client.notes ?? ''}
                                    onChange={(e) => handleChange({ notes: e.target.value })}
                                    as="textarea"
                                />
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-between align-items-center mb-3">
                            {clientId &&
                                <>
                                    <h5 className="mb-0 d-inline-block">Total SQM: {client.totalSQM}</h5>
                                    <h5 className="mb-0 d-inline-block">Total Amount: {rsdFormat(+client.totalAmount)}</h5>
                                </>
                            }
                        </div>
                        <div className="d-flex justify-content-end" >
                            <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>

                        {isFetching &&
                            <OverlayLoader />
                        }

                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default SetClientModal