import { memo } from 'react'
import { Form } from 'react-bootstrap'

const FormInput = ({label, children, ...rest}) => {
  return (
    <Form.Group>
        {label &&<Form.Label>{label} {rest.required && <span className="text-danger">*</span>}</Form.Label> }
        <Form.Control {...rest} >
          {children}
        </Form.Control>
    </Form.Group>
  )
}

export default memo(FormInput)