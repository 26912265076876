import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import FormInput from '../../components/forms/formInput';
import { createExpenseType, updateExpenseType } from '../../services/expenseTypes';
import { onMutateSuccess } from '../../utils/queryResponse';

const SetExpenseTypeModal = ({
    display,
    toggle,
    expenseType,
}) => {

    const [type, setType] = useState({name: ''})

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation( expenseType ? updateExpenseType : createExpenseType, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['expense-types'])
            onHide()
        })
    })

    const onShow = () => {
        expenseType && setType(expenseType)
    }

    const onHide = () => {
        setType({name: ''})
        toggle(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        mutate(type)
    }

    const handleChange = (objValue) => {
        const newType = { ...type, ...objValue }
        setType(newType)
    }

    return (
        <>
            <Modal
                show={display}
                onHide={onHide}
                size="sm"
                onShow={onShow}
                centered
                backdropClassName="level-2"
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{expenseType ? 'Edit' : 'Add'} Expense Type</h4>

                    <hr />

                    <Form id='set-expense' className="mb-4" onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg="12">
                                <FormInput className="mb-2" label="Name" value={type.name}
                                    onChange={(e) => handleChange({ name: e.target.value })}
                                    placeholder='Enter Name'
                                    required
                                />
                            </Col>
                        </Row>

                        <hr />
                        <div className='d-flex justify-content-end'>
                            <Button className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                            <Button type='submit' disabled={isLoading || expenseType?.name === type.name}>
                                { isLoading ? 'Submitting' : 'Submit' }
                            </Button>
                        </div>
                        
                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default SetExpenseTypeModal