import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { prettyTitle } from '../utils/helper';

const ShowHideColumns = ({ columns, shownColumns, setShownColumns, toggle = () => { }, automatic = false, storageName }) => {

    const [selectedColumns, setSelectedColumns] = useState([])

    useEffect(() => {
        setSelectedColumns([...shownColumns])
    }, [shownColumns])

    const handleSelectedColumns = (column) => {
        let _selectedColumns = selectedColumns
        const index = _selectedColumns.indexOf(column)

        if (_selectedColumns.some(x => (x.value || x) === (column.value || column))) {
            _selectedColumns.splice(index, 1)
        } else {
            _selectedColumns.push(column)
        }
        setSelectedColumns([..._selectedColumns])
        automatic && setShownColumns([...selectedColumns])
    }

    const shiftLeft = (arr, index) => {
        if (index > 0) {
            let _arr = [...arr];
            _arr[index] = arr[index - 1]
            _arr[index - 1] = arr[index]
            setSelectedColumns([..._arr])
            automatic && setShownColumns([..._arr])
        }
    }

    const shiftRight = (arr, index) => {
        if (index < arr.length - 1) {
            let _arr = [...arr];
            _arr[index] = arr[index + 1]
            _arr[index + 1] = arr[index]
            setSelectedColumns([..._arr])
            automatic && setShownColumns([..._arr])
        }
    }

    const resetSelectedColumns = () => {
        setSelectedColumns([...shownColumns])
        automatic && setShownColumns([...shownColumns])
    }

    const handleShownColumns = () => {
        if (selectedColumns.length > 0) {
            storageName && localStorage.setItem(storageName, JSON.stringify(selectedColumns))

            setShownColumns([...selectedColumns])
            toggle(false)
        } else {
            toast.error(' Shown Columns can not be empty! ', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const isColumnSelected = (column) => {
        return selectedColumns.some(x => (x.value || x) === (column.value || column))
    }

    return (
        <>
            <Row className="mb-3">
                <Col xs="6">
                    <p className="mb-2"><strong>Select Columns</strong></p>
                    <Card>
                        <div className="p-2">
                            {columns.length > 0 ?
                                <>
                                    {columns.map((column, index) => (
                                        <div key={`column-${index}`}
                                            onClick={() => handleSelectedColumns(column)}
                                            className={`columns-selection text-capitalize cursor-pointer px-1 ${isColumnSelected(column) ? 'selected' : ''}`}>
                                            {prettyTitle(column.label || column)}
                                        </div>
                                    ))}
                                </>
                                :
                                <i><small>Nothing to display</small></i>
                            }
                        </div>
                    </Card>
                </Col>
                <Col xs="6">
                    <p className="mb-2"><strong>Shown Columns</strong></p>
                    <Card>
                        <div className="p-2">
                            {selectedColumns.length > 0 ?
                                <>
                                    {selectedColumns.map((column, index) => (
                                        <div key={`showncolumn-${index}`}
                                            className="shown-column text-capitalize d-flex px-1">
                                            <span onClick={() => handleSelectedColumns(column)} className="cursor-pointer me-3">{prettyTitle(column.label || column)}</span>
                                            <div className="shown-column-actions ms-auto">

                                                <i className="text-muted fa-solid fa-chevron-left cursor-pointer color-primary me-1" onClick={() => shiftLeft(selectedColumns, index)}></i>
                                                <i className="text-muted fa-solid fa-chevron-right cursor-pointer color-primary" onClick={() => shiftRight(selectedColumns, index)}></i>

                                            </div>
                                        </div>
                                    ))}
                                </>
                                :
                                <i><small>Nothing to display</small></i>
                            }
                        </div>
                    </Card>
                </Col>
            </Row>

            {!automatic &&
                <div className="text-end">
                    <Button variant="outline-secondary" className="me-2" onClick={() => resetSelectedColumns()}>Reset</Button>
                    <Button onClick={() => handleShownColumns()}>Submit</Button>
                </div>
            }
        </>
    )
}

export default ShowHideColumns;