import http from './http'
import { getToken } from './auth'

const base = 'overview'

export async function getUpcomingJobs(startDate, endDate) {
    
    const config = {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params: {
            timeZoneOffset: new Date().getTimezoneOffset()
        }
    }
    if(startDate && endDate){
        config.params = { ...config.params, ...{startDate, endDate}}
    }
    const { data } = await http.get(`${base}/upcoming-jobs`, config)
    return data
}

export async function getUpcomingDeliveries(startDate, endDate) {
    
    const config = {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params: {
            timeZoneOffset: new Date().getTimezoneOffset()
        }
    }
    if(startDate && endDate){
        config.params = { ...config.params, ...{startDate, endDate}}
    }
    const { data } = await http.get(`${base}/upcoming-deliveries`, config)
    return data
}

export async function getFinishedJobs(startDate, endDate) {
    
    const config = {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params: {
            timeZoneOffset: new Date().getTimezoneOffset()
        }
    }
    if(startDate && endDate){
        config.params = { ...config.params, ...{startDate, endDate}}
    }
    const { data } = await http.get(`${base}/finished-jobs`, config)
    return data
}

export async function getTotals() {
    
    const { data } = await http.get(`${base}/totals`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }

    })
    return data
}

export async function getDriverDeliveries(startDate, endDate = new Date()) {
    
    const { data } = await http.get(`${base}/driver-deliveries`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params: {
            startDate: startDate || new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 6),
            endDate,
            timeZoneOffset: new Date().getTimezoneOffset()
        }

    })
    return data
}

export async function getSales() {
    
    const { data } = await http.get(`${base}/sales`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }

    })
    return data
}