import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from "react-router-dom";
import JobsCard from '../../components/cards/jobsCards';
import ConfirmationModal from '../../components/modals/confirmation';
import SetTempEditOrderModal from '../../components/modals/orderEditTemp';
import SetOrderModal from '../../components/modals/orderSet';
import OverlayLoader from '../../components/overlayLoader';
import { deleteJob, getJobById, getJobs, updateJob } from '../../services/jobs';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { isUserAdmin } from '../../utils/roleHelpers';
import JobsFilter from './jobsFilter';

const JobsPage = () => {

    const disableDragging = true // Disable Card Drag and Drop

    const [searchParams] = useSearchParams();
    const queryJobId = searchParams.get("jobId")
    const [isLoadingQueryJob, setIsLoadingQueryJob] = useState(false)

    const [orderModal, setOrderModal] = useState(false)
    const [editJobModal, setEditJobModal] = useState(false)
    const [jobInfoModal, setJobInfoModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [selectedJob, setSelectedJob] = useState({})
    const [selectedJobForDeletion, setSelectedJobForDeletion] = useState({})
    const [filter, setFilter] = useState([new Date(), new Date()])

    const [alert, setAlert] = useState()

    const [jobs, setJobs] = useState(
        {
            jobs: { items: [] },
            pickup: { items: [] },
            inProgress: { items: [] },
            completed: { items: [] }
        }
    )

    const { data, isFetching, isLoading } = useQuery(
        ['jobs', ...filter],
        () => getJobs(...filter)
    )

    const getJobColumnByDroppableId = (droppableId) => {
        if (droppableId === "droppable-jobs")
            return "jobs"
        else if (droppableId === "droppable-pickup")
            return "pickup"
        else if (droppableId === "droppable-in-progress")
            return "inProgress"
        else
            return "completed"
    }

    const queryClient = useQueryClient()

    const { mutate: mutateDelete, isLoading: isDeleting } = useMutation(deleteJob, {
        onSuccess: onMutateSuccess((result) => {
            queryClient.invalidateQueries(['jobs'])
            // let _jobs = jobs
            // _jobs[getJobColumnByDroppableId(selectedJob.droppableId)].items.splice(selectedJob.droppableIndex, 1)
            // setJobs(_jobs)
        }, isUserAdmin() ? ' Successfully deleted a job ' : ' Delete request was sent to the admin ')
    })

    const { mutate: mutateUpdate, isLoading: isUpdating } = useMutation(updateJob, {
        onSuccess: onMutateSuccess((result, code) => {
            if (code !== 200) {
                setAlert(result)
                setEditJobModal(true)
            }
            queryClient.invalidateQueries(['jobs'])
            queryClient.invalidateQueries(['sales'])
        })
    })

    useEffect(() => {
        if (queryJobId) {
            setIsLoadingQueryJob(true)
            getJobById(queryJobId)
                .then(data => {
                    if (data.code == 200) {
                        handleJobClick(data.message)
                    }
                    setIsLoadingQueryJob(false)
                })
                .catch(error => {
                    console.error(error)
                    setIsLoadingQueryJob(false)
                });
        }
    }, [queryJobId]);

    useEffect(() => {
        onQuerySuccess(data, (result) => {

            const _jobs = {
                jobs: {
                    items: []
                },
                pickup: {
                    items: []
                },
                inProgress: {
                    items: []
                },
                completed: {
                    items: []
                }
            }

            _jobs.jobs.items = result.filter(x => x.status === 'SCHEDULED' || x.status === 'ASSIGNED').sort(function (a, b) { return a.id - b.id })
            _jobs.pickup.items = result.filter(x => x.status === 'PICKUP_IN_PROGRESS' || x.status === 'PICKUP_COMPLETED').sort(function (a, b) { return a.id - b.id })
            _jobs.inProgress.items = result.filter(x => x.status === 'CLEANING' || x.status === 'CLEANING_COMPLETED' || x.status === 'DISPATCH_IN_PROGRESS').sort(function (a, b) { return a.id - b.id })
            _jobs.completed.items = result.filter(x => x.status === 'COMPLETED').sort(function (a, b) { return a.id - b.id })

            setJobs(_jobs)
        })
    }, [data])

    const handleOnDragEnd = (result) => {

        setAlert()

        if (!result.destination) return

        let _jobs = { ...jobs }
        let _selectedJob = jobs[getJobColumnByDroppableId(result.source.droppableId)].items.find(x => parseInt(x.id) === parseInt(result.draggableId))
        setSelectedJob(_selectedJob)

        // get dragged item and remove it from source
        const [reorderedItem] = _jobs[getJobColumnByDroppableId(result.source.droppableId)].items.splice(result.source.index, 1)

        // initialize job payload
        var job = {
            id: result.draggableId
        }

        if (result.destination.droppableId === "droppable-jobs") {
            reorderedItem.progress = "Scheduled"
            job.status = "SCHEDULED"
        }
        else if (result.destination.droppableId === "droppable-pickup") {
            reorderedItem.progress = "Pickup in Progress"
            job.status = "PICKUP_IN_PROGRESS"
        }
        else if (result.destination.droppableId === "droppable-in-progress") {
            reorderedItem.progress = "In-Progress"
            job.status = "CLEANING"
        }
        else {
            reorderedItem.progress = "Completed"
            job.status = "COMPLETED"
        }

        // place dragged item to dropped destination
        //_jobs[getJobColumnByDroppableId(result.destination.droppableId)].items.splice(result.destination.index, 0, reorderedItem)

        // set droppable column loading
        _jobs[getJobColumnByDroppableId(result.destination.droppableId)].isLoading = true

        setJobs(_jobs)
        mutateUpdate(job)
    }

    const handleJobClick = (job) => {
        setSelectedJob(job)
        setEditJobModal(true)
    }

    const loader = () => {
        return <>{(isLoading || isLoadingQueryJob) &&
            <Card className="mb-3">
                <Card.Body>
                    <div style={{ height: '100px' }}>
                        <OverlayLoader noTextDisplay />
                    </div>
                </Card.Body>
            </Card>
        }</>
    }

    const columnLoader = () => {
        return (
            <div className="h-100 rounded" style={{ minHeight: "150px", opacity: 0.65, backgroundColor: '#fff' }}>
                <div style={{ minHeight: '150px' }} className="position-relative">
                    <OverlayLoader noTextDisplay />
                </div>
            </div>
        )
    }

    const handleDeleteJob = () => {
        mutateDelete(selectedJobForDeletion.id)
    }

    const handleOnClickDeleteJob = (job, droppableId, droppableIndex) => {
        let _job = job
        _job.droppableId = droppableId
        _job.droppableIndex = droppableIndex
        setSelectedJobForDeletion(_job)
        setConfirmationModal(true)
    }

    return (
        <div>
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Job'
                message="Are you sure you want to delete this Job?" confirmAction={handleDeleteJob}
            />
            <SetOrderModal display={orderModal} toggle={setOrderModal} />
            {
                selectedJob.id &&
                <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJob.id} setSelectedJob={setSelectedJob} alert={alert} setAlert={setAlert} />
            }
            <div className="mb-3">
                <JobsFilter onFilter={setFilter} />
            </div>

            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Row>
                    <Droppable droppableId='droppable-jobs'>
                        {(provided) => (
                            <Col lg="3" {...provided.droppableProps} ref={provided.innerRef}>
                                <p style={{ fontSize: "1rem", marginBottom: "-5px" }}>New</p>
                                <hr style={{ borderTop: '2px solid' }} />
                                {loader()}
                                <Card style={{ visibility: ((isLoading || isLoadingQueryJob) ? 'hidden' : 'visible') }} className="mb-3 cursor-pointer" onClick={() => setOrderModal(true)}>
                                    <Card.Body>
                                        <span><i className="fa-solid fa-plus"></i> New Job</span>
                                    </Card.Body>
                                </Card>
                                <div className="position-relative">
                                    {jobs.jobs.isLoading &&
                                        columnLoader()
                                    }
                                    <div style={{ visibility: ((isLoading || isLoadingQueryJob || jobs.jobs.isLoading) ? 'hidden' : 'visible') }}>
                                        {jobs.jobs.items.length > 0 && jobs.jobs.items.map((job, index) => (
                                            <Draggable isDragDisabled={disableDragging} key={`job-${index}`} draggableId={(job.id).toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <JobsCard index={index}
                                                            onClickDelete={() => handleOnClickDeleteJob(job, 'droppable-jobs', index)}
                                                            onClickEdit={() => handleJobClick(job)}
                                                            job={job} client={job.client}
                                                            dateTime={job.pickupType === "CUSTOMER_DELIVERS" ? job.deliveredByCustomerTime : (job.pickupTime ? new Date(job.pickupTime) : null)} progress={job.status} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                    {provided.placeholder}
                                </div>
                            </Col>
                        )}
                    </Droppable>
                    <Droppable droppableId='droppable-pickup' style={{ transition: 'none' }}>
                        {(provided) => (
                            <Col lg="3" {...provided.droppableProps} ref={provided.innerRef}>
                                <p style={{ fontSize: "1rem", marginBottom: "-5px" }}>Pickup</p>
                                <hr style={{ borderTop: '2px solid var(--color-primary)', opacity: 1 }} />
                                <div className="position-relative">
                                    {jobs.pickup.isLoading &&
                                        columnLoader()
                                    }
                                    {loader()}
                                    <div style={{ visibility: ((isLoading || isLoadingQueryJob || jobs.pickup.isLoading) ? 'hidden' : 'visible') }}>
                                        {jobs.pickup.items.length > 0 && jobs.pickup.items.map((pickup, index) => (
                                            <Draggable isDragDisabled={disableDragging} key={`pickup-${index}`} draggableId={(pickup.id).toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <JobsCard index={index}
                                                            onClickDelete={() => handleOnClickDeleteJob(pickup, 'droppable-pickup', index)}
                                                            onClickEdit={() => handleJobClick(pickup)}
                                                            job={pickup} progress={pickup.status}
                                                            client={pickup.client} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                    {provided.placeholder}
                                </div>
                            </Col>
                        )}
                    </Droppable>
                    <Droppable droppableId='droppable-in-progress'>
                        {(provided) => (
                            <Col lg="3" {...provided.droppableProps} ref={provided.innerRef}>
                                <p style={{ fontSize: "1rem", marginBottom: "-5px" }}>In Progress</p>
                                <hr style={{ borderTop: '2px solid var(--color-orange)', opacity: 1 }} />
                                <div className="position-relative">
                                    {jobs.inProgress.isLoading &&
                                        columnLoader()
                                    }
                                    {loader()}
                                    <div style={{ visibility: ((isLoading || isLoadingQueryJob || jobs.inProgress.isLoading) ? 'hidden' : 'visible') }}>
                                        {jobs.inProgress.items.length > 0 && jobs.inProgress.items.map((inProgress, index) => (
                                            <Draggable isDragDisabled={disableDragging} disableInteractiveElementBlocking key={`inProgress-${index}`} draggableId={(inProgress.id).toString()} index={index}>
                                                {(provided) => (
                                                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <JobsCard index={index}
                                                            onClickDelete={() => handleOnClickDeleteJob(inProgress, 'droppable-in-progress', index)}
                                                            onClickEdit={() => handleJobClick(inProgress)}
                                                            job={inProgress}
                                                            dateTime={inProgress.dispatchTime}
                                                            progress={inProgress.status}
                                                            client={inProgress.client} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                    {provided.placeholder}
                                </div>
                            </Col>
                        )}
                    </Droppable>
                    <Droppable droppableId='droppable-completed'>
                        {(provided) => (
                            <Col lg="3" {...provided.droppableProps} ref={provided.innerRef}>
                                <p style={{ fontSize: "1rem", marginBottom: "-5px" }}>Completed</p>
                                <hr style={{ borderTop: '2px solid var(--color-success)', opacity: 1 }} />
                                <div className="position-relative">
                                    {jobs.completed.isLoading &&
                                        columnLoader()
                                    }
                                    {loader()}
                                    <div style={{ visibility: ((isLoading || isLoadingQueryJob || jobs.completed.isLoading) ? 'hidden' : 'visible') }}>
                                        {jobs.completed.items.length > 0 && jobs.completed.items.map((completed, index) => (
                                            <Draggable isDragDisabled={disableDragging} key={`completed-${index}`} draggableId={(completed.id).toString()} index={index}>
                                                {(provided) => (
                                                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <JobsCard index={index}
                                                            onClickDelete={() => handleOnClickDeleteJob(completed, 'droppable-completed', index)}
                                                            onClickEdit={() => handleJobClick(completed)}
                                                            job={completed}
                                                            dateTime={completed.completionTimeActual}
                                                            progress={completed.status}
                                                            client={completed.client} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                    {provided.placeholder}
                                </div>
                            </Col>
                        )}
                    </Droppable>
                </Row>
            </DragDropContext>

        </div>
    );
};

export default JobsPage;