import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { rsdFormat, toTitleCase } from '../../utils/helper'
import OverlayLoader from '../../components/overlayLoader'

//get keys from object with a specified substring
const getKeysFromObjectWithSubstring = (
    obj, // object to get keys
    substring // substring to be match
) => {

    const length = substring.length
    return Object.keys(obj).filter( d => d.substring(0, length) === substring )
}

const CashFlowTable = ({data, titleText, isLoading}) => {
    const [expensesKeys, setExpensesKeys] = useState([])
    const [incomeKeys, setIncomeKeys] = useState([])

    useEffect(() => {
        if(data.length) {
            setExpensesKeys( getKeysFromObjectWithSubstring(data[0], 'expense_') )
            setIncomeKeys( getKeysFromObjectWithSubstring(data[0], 'income_' ) )
        }
    }, [data])
    
    return (
        <>
        <div className='d-flex justify-content-center mt-3 mb-3'>
            <h6>{titleText}</h6>
        </div>

        <div style={{position:'relative'}}>
        <Table responsive>
            <thead>
                <tr>
                    <th></th>
                    { data.map( d =>
                    <th key={d.label} style={{whiteSpace: 'nowrap'}}>{d.label}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                { incomeKeys.map( key =>
                <tr key={key}>
                    <td><strong>{toTitleCase(key.replace("income_", ""))}</strong></td>
                    { data.map( (d, index) =>
                        <td key={`key-${index}`}>{rsdFormat(d[key])}</td>
                    )}
                </tr>
                )}

                <tr className='table-primary' style={{borderBottom:'2px solid #444'}}>
                    <td><strong>Total Income</strong></td>
                    { data.map( (d, index) =>
                        <td key={`key-${index}`}><strong>{rsdFormat(d.totalIncome)}</strong></td>
                    )}
                </tr>

                { expensesKeys.map( key =>
                <tr key={key}>
                    <td><strong>{toTitleCase(key.replace("expense_", ""))}</strong></td>
                    { data.map( (d, index) =>
                        <td key={`key-${index}`}>{rsdFormat(d[key])}</td>
                    )}
                </tr>
                )}

                <tr className='table-danger' style={{borderBottom:'2px solid #444'}}>
                    <td><strong>Total Expenses</strong></td>
                    { data.map( (d, index) =>
                        <td key={`key-${index}`}><strong>{rsdFormat(d.totalExpenses)}</strong></td>
                    )}
                </tr>

                <tr className='bg-primary text-white' style={{borderBottom:'2px solid #444'}}>
                    <td><strong>Net Total</strong></td>
                    { data.map( (d, index) =>
                        <td key={`key-${index}`} style={{whiteSpace: 'nowrap'}}>
                            <strong>{rsdFormat(d.net)}</strong>
                        </td>
                    )}
                </tr>
            </tbody>
        </Table>

        { isLoading &&
            <OverlayLoader />
        }

        </div>
        </>
    )
}

export default CashFlowTable