import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import OverlayLoader from '../../components/overlayLoader';
import FormInput from '../../components/forms/formInput';
import UserRoleSelect from '../../components/selects/userRoleSelect';
import { createUser, getUserById } from '../../services/companyUsers';
import { updateUser } from '../../services/users';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { trimProps } from '../../utils/helper';

const initUser = {
    username: '',
    password: '',
    fullName: '',
    email: '',
    role: ''
}

const SetUserModal = ({
    display,
    toggle,
    selectedUser
    //userId,
}) => {

    const [user, setUser] = useState(initUser)

    const onShow = () => {
        if (selectedUser?.id) {
            let _selectedUser = selectedUser
            _selectedUser.role = _selectedUser.roles.toLowerCase()
            setUser(_selectedUser)
        } else {
            setUser(initUser)
        }
    }

    const onHide = () => {
        toggle(false)
    }

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(selectedUser?.id ? updateUser : createUser, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['users'])
            onHide()
        })
    })

    // const { data, isFetching } = useQuery(['users','details', userId], () => userId && getUserById(userId), {
    //     staleTime: 1000 * 60 * 2,
    // })

    // useEffect(() => {
    //     onQuerySuccess(data, (result) => {
    //         const _user = trimProps(result, initUser, ['id'])
    //         setUser(_user)
    //     })
    // }, [data])

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        delete user['roles'];
        mutate(user)
    }

    const handleChange = (objValue) => {
        const newUser = { ...user, ...objValue }
        setUser(newUser)
    }

    const handleSelectChange = (prop) => (val) => {
        handleChange({ ...user, [prop]: val })
    }

    return (
        <>
            <Modal
                show={display}
                onHide={onHide}
                size="md"
                onShow={onShow}
                centered
                backdropClassName="level-2"
                backdrop={isLoading ? "static" : true}
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{selectedUser?.id ? 'Edit' : 'Add'} User</h4>

                    <hr />

                    <Form id='set-user' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="Username" value={user.username ?? ''}
                                    onChange={(e) => handleChange({ username: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="form-control mb-2" type="password" label="Password" value={user.password ?? ''}
                                    onChange={(e) => handleChange({ password: e.target.value })} required
                                    disabled={selectedUser?.id}
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="Full Name" value={user.fullName ?? ''}
                                    onChange={(e) => handleChange({ fullName: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput type="email" className="form-control mb-2" label="Email" value={user.email ?? ''}
                                    onChange={(e) => handleChange({ email: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <UserRoleSelect className="mb-2" label="Role" value={user.role ?? ''}
                                    onChange={handleSelectChange('role')}
                                    placeholder='Select Role'
                                    required
                                />
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>

                        {/* {isFetching &&
                            <OverlayLoader />
                        } */}

                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default SetUserModal