import { getToken } from './auth'
import http from './http'
// import format from 'date-fns/format'

const base = 'Payrolls'

export const getPaginatedPayrolls = async (pageNumber, pageSize, startDate, endDate, employeeId, rateType) => {

    const params = new URLSearchParams()
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)

    startDate && params.append('startDate', startDate.toISOString())
    endDate && params.append('endDate', endDate.toISOString())
    employeeId && params.append('employeeId', employeeId)
    rateType && params.append('rateType', rateType)

    const { data } = await http.get(`${base}/paginated`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getPayrollById(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createPayroll(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.post(base + '/', payload, config)
    return data
}

export async function updatePayroll(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}

export async function deletePayroll(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}
