import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SortableTable from 'reactstrap-sortable-table';
import ConfirmationModal from '../../components/modals/confirmation';
import OverlayLoader from '../../components/overlayLoader';
import { getUsers } from '../../services/companyUsers';
import { deleteUser } from '../../services/users';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import SetUserModal from './usersSet';

const columns = ['username', 'password', 'fullName', 'email', 'roles']

const UsersPage = () => {

    const [userCreateModal, setUserCreateModal] = useState(false)
    const [isShowConfirmation, setIsShowConfirmation] = useState(false)

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState({})

    const queryClient = useQueryClient()

    const { data, isFetching } = useQuery('users', getUsers)

    //TODO: not used/implemneted
    const { mutate, isLoading: isDeleting } = useMutation(deleteUser, {
        onSuccess: onMutateSuccess((result, id) => {
            queryClient.invalidateQueries('users')
        }, ' Successfully deleted a user ')
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setUsers(result)
        })
    }, [data])

    const handleSetUser = (user) => {
        setSelectedUser(user)
        setUserCreateModal(true)
    }

    const handleConfirmation = (user) => {
        setSelectedUser(user)
        setIsShowConfirmation(true)
    }

    const handleDeleteExpense = () => {
        mutate(selectedUser.id)
    }

    const copyPassword = (password) => {
        navigator.clipboard.writeText(password)
    }

    return (
        <>
            <ConfirmationModal display={isShowConfirmation} toggle={setIsShowConfirmation} title='Delete User'
                message={`Are you sure you want to delete user ${selectedUser?.fullName}`}
                confirmAction={handleDeleteExpense}
            />
            <SetUserModal display={userCreateModal} toggle={setUserCreateModal} selectedUser={selectedUser} />

            <Row>
                <Col>
                    <h4 className="page-title admin">Users</h4>

                    <Card className="mb-4">
                        <Card.Body>

                            <div className="mb-3">
                                <Button variant="secondary" size="sm" className="py-0 me-2" onClick={() => handleSetUser()}>
                                    <small><i className="fa-solid fa-plus"></i></small> Add User
                                </Button>
                            </div>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        <SortableTable
                                            responsive
                                            size="sm"
                                            data={users}
                                            setData={setUsers}
                                            columns={columns}
                                            firstColumnRender={(user) => <>
                                                <i className="fa-solid fa-pencil fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleSetUser(user)}></i>
                                                <i className="fa-solid fa-trash fa-sm" style={{ cursor: 'pointer' }} onClick={() => handleConfirmation(user)}></i>
                                            </>}
                                            columnRender={[
                                                { column: 'roles', render: (value) => <span className="text-capitalize">{value.replace('_', ' ')}</span> },
                                                { column: 'password', render: (value) => 
                                                    <>
                                                        <span className="text-capitalize me-2">*********</span>
                                                        <i className="fa-regular fa-clipboard" title="copy password" style={{ cursor: 'pointer' }} onClick={() => copyPassword(value)}></i>
                                                    </>
                                                }
                                            ]}
                                            addProps={{ tBodyRow: { className: 'align-middle' } }}
                                        />

                                        {(isFetching || isDeleting) &&
                                            <OverlayLoader />
                                        }

                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                    
                </Col>
            </Row>
        </>

    );
};

export default UsersPage;