import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import OverlayLoader from '../../components/overlayLoader';
import { get as generateNiceColors} from '../../utils/niceColors'
import { toTitleCase } from '../../utils/helper'

ChartJS.register(CategoryScale, Tooltip, Legend, LineElement, PointElement, LinearScale, Title);

const CashFlowCharts = ({ data, titleText, isLoading }) => {

    const [chartData, setChartData] = useState()

    const [options, setOptions] = useState({
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Weekly Chart',
            },
            
        },
        maintainAspectRatio: false,
        responsive: true,
    })

    useEffect(() => {
        if(!data) return

        const { expenses } = data//for label purpose
        const datasets = [
            // {
            //     label: 'Income',
            //     data: income.data,
            //     borderColor: 'rgb(25,135,84)',
            //     backgroundColor: 'rgba(25,135,84, 0.5)',
            //     lineTension: .5
            // },
            // {
            //     label: 'Expenses',
            //     data: expenses.data,
            //     borderColor: 'rgb(220,53,69)',
            //     backgroundColor: 'rgba(220,53,69, 0.5)',
            //     lineTension: .5
            // }
        ]
        
        const keys = Object.keys(data)//.filter(x => x.endsWith('-Expenses'))
        const colors = generateNiceColors(keys.length).reverse();

        keys.forEach((x,i) => datasets.push({
            label: toTitleCase(x.replace("-Expenses","")),
            data: data[x].data,
            borderColor: colors[i],
            backgroundColor: colors[i] + '50',
            // backgroundColor: 'rgba(220,53,69, 0.5)',
            lineTension: .5
        }))
        setChartData({
            labels: expenses.labels,
            datasets: datasets
        })

    }, [data])

    useEffect(() => {
        setOptions({
            ...options,
            plugins: {
                ...options.plugins,
                title: { display: true, text: titleText }
            }
        })
    }, [titleText])
    
    return (
        <div style={{position:'relative', height: '350px'}}>
            {chartData &&
                <Line options={options} data={chartData} />
            }
            { isLoading &&
                <OverlayLoader />
            }
        </div>
    )
}

export default CashFlowCharts