import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import OverlayLoader from '../../../components/overlayLoader';
import FormInput from '../../../components/forms/formInput';
import { createEmployee, getEmployeeById, updateEmployee } from '../../../services/employees';
import { trimProps } from '../../../utils/helper';
import { onMutateSuccess, onQuerySuccess } from '../../../utils/queryResponse';

const initEmployee = {
    firstName: '',
    lastName: ''
}

const SetEmployeeModal = ({
    display,
    toggle,
    employeeId,
}) => {

    const [employee, setEmployee] = useState(initEmployee)

    const onShow = () => {
        !employeeId && setEmployee(initEmployee)
    }

    const onHide = () => {
        toggle(false)
    }

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation( employeeId ? updateEmployee : createEmployee, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['employees'])
            onHide()
        })
    })

    const { data, isFetching } = useQuery(['employees','details', employeeId], () => employeeId && getEmployeeById(employeeId))

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            const _employee = trimProps(result, initEmployee, ['id'])
            setEmployee(_employee)
        })
    }, [data])

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        mutate(employee)
    }

    const handleChange = (objValue) => {
        const newEmployee = { ...employee, ...objValue }
        setEmployee(newEmployee)
    }

    return (
        <>
            <Modal
                show={display}
                onHide={onHide}
                size="md"
                onShow={onShow}
                centered
                backdropClassName="level-2"
                backdrop={isLoading ? "static" : true}
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{employeeId ? 'Edit' : 'Add'} Employee</h4>

                    <hr />

                    <Form id='set-employee' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="First Name" value={employee.firstName ?? ''}
                                    onChange={(e) => handleChange({ firstName: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <FormInput className="form-control mb-2" label="Last Name" value={employee.lastName ?? ''}
                                    onChange={(e) => handleChange({ lastName: e.target.value })} required
                                />
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>

                        {isFetching &&
                            <OverlayLoader />
                        }

                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default SetEmployeeModal