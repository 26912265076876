import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SortableTable from "reactstrap-sortable-table";
import ConfirmationModal from '../../components/modals/confirmation';
import OverlayLoader from '../../components/overlayLoader';

import { toLocalDateString } from '../../utils/helper';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { deleteNotification, getPaginatedNotifications, markAllNotificationsAsRead, updateNotification } from '../../services/notifications';
import NotificationsFilter from './notificationsFilter';
import Pages from '../../components/pages';
import SetTempEditOrderModal from '../../components/modals/orderEditTemp';
// import SetNotificationModal from './notificationsSet';

const NotificationsPage = () => {

    const [notifications, setNotifications] = useState([])
    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize] = useState(50)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [editJobModal, setEditJobModal] = useState(false)
    const [selectedJobId, setSelectedJobId] = useState()
    const [isDelete, setIsDelete] = useState(false)

    const [notificationToDelete, setNotificationToDelete] = useState()
    const [filters, setFilters] = useState(['', ''])

    const { data, isLoading } = useQuery(
        ['notifications', 'paginated', pageNumber, pageSize, ...filters],
        () => getPaginatedNotifications(pageNumber, pageSize, ...filters)
    )

    const queryClient = useQueryClient()
    const { mutate, isLoading: isDeleting } = useMutation(deleteNotification, {
        onSuccess: onMutateSuccess((result, id) => {
            if (notifications.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1)
            }
            queryClient.invalidateQueries(['notifications'])
        }, ' Successfully deleted a notification ')
    })

    const { mutate: mutateMarkAsRead, isLoading: isMarkingAsRead } = useMutation(updateNotification, {
        onSuccess: onMutateSuccess((resp) => {
            queryClient.invalidateQueries(['notifications',])
        })
    })

    const { mutate: mutateMarkAllAsRead, isLoading: isMarkingAllAsRead } = useMutation(markAllNotificationsAsRead, {
        onSuccess: onMutateSuccess((resp) => {
            queryClient.invalidateQueries(['notifications',])
        })
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setPagination(result)
            setNotifications(result.data)
        })
    }, [data])

    const handleShowConfirmation = (notification) => {
        setIsDelete(notification ? true : false)
        setConfirmationModal(true)
        notification && setNotificationToDelete(notification)
    }

    const handleDeleteNotification = () => {
        mutate(notificationToDelete.id)
    }

    const markAsRead = (notification, index) => {
        let _notifications = notifications
        _notifications[index].markingAsRead = true
        setNotifications([..._notifications])
        let payload = {
            id: notification.id,
            isRead: true
        }
        mutateMarkAsRead(payload)
    }

    const handleShowEditJobModal = (data, index) => {
        markAsRead(data, index)
        setSelectedJobId(data.objectId)
        setEditJobModal(true)
    }

    return (
        <>
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title={isDelete ? 'Delete Notification' : 'Mark All As Read'}
                message={`Are you sure you want to ${isDelete ? 'delete this' : 'mark all'} notification${!isDelete ? 's as read':''}?`} confirmAction={isDelete ? handleDeleteNotification : mutateMarkAllAsRead}
                confirmText="Confirm" cancelText="Cancel"
            />

            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJobId} />

            {/* <SetNotificationModal display={setNotificationModal} toggle={setSetNotificationModal} notificationId={notificationToEdit?.id} /> */}

            <Row>
                <Col>
                    <NotificationsFilter onFilter={setFilters} />

                    <Card className="mt-3 mb-4">
                        <Card.Body>

                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Notifications</h4>
                                </Col>
                                <Col className="text-end">
                                    <Button onClick={() => handleShowConfirmation()} disabled={isMarkingAllAsRead}>
                                        <i className="fa-solid fa-check"></i> Mark All As Read
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {notifications.length > 0 ?
                                            <div className="print-wrapper">
                                                <SortableTable
                                                    style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                    responsive
                                                    size="sm"
                                                    data={notifications}
                                                    setData={setNotifications}
                                                    columns={['message', { value: 'updatedAt', label: 'Date' }]}
                                                    columnRender={[
                                                        { column: 'updatedAt', render: (value) => toLocalDateString(new Date(value)) },
                                                        { column: 'isRead', render: (value) => value ? "true" : "false" },
                                                        {
                                                            column: 'message', render: (value, column, columnIndex, data, dataIndex) => {
                                                                if (value === 'unpicked job')
                                                                    return <>
                                                                        Job <span className="text-info cursor-pointer" onClick={() => handleShowEditJobModal(data, dataIndex)}>{'#' + data.objectId + ' '}</span>
                                                                        was not picked up
                                                                    </>
                                                                else if (value === 'excessive pickup')
                                                                    return <>
                                                                        Job <span className="text-info cursor-pointer" onClick={() => handleShowEditJobModal(data, dataIndex)}>{'#' + data.objectId + ' '}</span>
                                                                        pick up takes too long
                                                                    </>
                                                                else if (value === 'not started cleaning')
                                                                    return <>
                                                                        Job <span className="text-info cursor-pointer" onClick={() => handleShowEditJobModal(data, dataIndex)}>{'#' + data.objectId + ' '}</span>
                                                                        cleaning not yet started
                                                                    </>
                                                                else if (value === 'excessive cleaning')
                                                                    return <>
                                                                        Job <span className="text-info cursor-pointer" onClick={() => handleShowEditJobModal(data, dataIndex)}>{'#' + data.objectId + ' '}</span>
                                                                        cleaning takes too long
                                                                    </>
                                                                else if (value === 'not started dispatch')
                                                                    return <>
                                                                        Job <span className="text-info cursor-pointer" onClick={() => handleShowEditJobModal(data, dataIndex)}>{'#' + data.objectId + ' '}</span>
                                                                        dispatch not yet started
                                                                    </>
                                                                else if (value === 'excessive dispatch')
                                                                    return <>
                                                                        Job <span className="text-info cursor-pointer" onClick={() => handleShowEditJobModal(data, dataIndex)}>{'#' + data.objectId + ' '}</span>
                                                                        dispatch takes too long
                                                                    </>
                                                                else if (value === 'delete request')
                                                                    return <>
                                                                        Delete request for job <span className="text-info cursor-pointer" onClick={() => handleShowEditJobModal(data, dataIndex)}>{'#' + data.objectId + ' '}</span>
                                                                    </>
                                                                else
                                                                    return value
                                                            }
                                                        }
                                                    ]}
                                                    firstColumnRender={(notification, index) => <>
                                                        <div>
                                                            {/* <i className="fa-solid fa-pencil fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleShowSetNotificationModal(notification)}></i> */}
                                                            <div className="d-inline position-relative">
                                                                {notification.markingAsRead &&
                                                                    <OverlayLoader noTextDisplay size="25" />
                                                                }
                                                                <i className={`fa-solid fa-envelope${notification.isRead ? '-open no-pointer-events' : ''} fa-sm mx-1`} title={`${notification.isRead ? '' : 'mark as read'}`} style={{ cursor: 'pointer' }} onClick={() => markAsRead(notification, index)}></i>
                                                            </div>
                                                            <i className="fa-solid fa-trash fa-sm mx-1" style={{ cursor: 'pointer' }} onClick={() => handleShowConfirmation(notification)}></i>
                                                        </div>
                                                    </>}
                                                    addProps={{
                                                        tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                        tBodyRow: (data, index) => ({ className: !data.isRead ? 'row-bold' : '' }),
                                                        tData: { className: 'py-2' }
                                                    }}
                                                    dateColumns={['updatedAt']}
                                                    noSortColumns={['message']}
                                                />
                                            </div>
                                            :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no notifications found</span>
                                            </>
                                        }
                                        {pagination?.totalPages > 0 &&
                                            <Pages paginationData={pagination} changePage={setPageNumber} pageNumber={pageNumber} />
                                        }

                                        {(isLoading || isDeleting) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default NotificationsPage;