import http from "./http"
import { getToken } from './auth'

const base = 'Expenses'

export const getPaginated = async (pageNumber, pageSize, startDate, endDate, category, paymentMethod, searchText) => {

    const params = new URLSearchParams()
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)

    // types.forEach( type => {
    //     params.append('type', type)
    // })
    startDate && params.append('startDate', startDate.toISOString())
    endDate && params.append('endDate', endDate.toISOString())
    category && params.append('category', category)
    paymentMethod && params.append('paymentMethod', paymentMethod)
    searchText && params.append('searchText', searchText)

    const { data } = await http.get(`${base}/paginated`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function createExpense(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.post(base + '/', payload, config)
    return data
}

export async function getById(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getReports(startDate, endDate) {

    const params = new URLSearchParams()
   
    startDate  && params.append('startDate', startDate.toISOString())
    endDate  && params.append('endDate', endDate.toISOString())

    const { data } = await http.get(`${base}/report`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function createExpenseMulti(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.post(base + '/multi', payload, config)
    return data
}

export async function importExpenseCSV(file) {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + getToken(),
        },
        params: {
            splitter: ','
        }
    }

    const formData = new FormData();
    formData.append('file', file);

    const { data } = await http.post(base + '/import/file', formData, config)
    return data
}

export async function deleteExpense(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}

export async function updateExpense(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}