import { useState } from 'react';
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import DatePicker from '../../components/forms/formDatePicker';
import ServiceCategorySelect from '../../components/selects/serviceCategorySelect';
import ServiceSelect from '../../components/selects/serviceSelect';

const SalesFilter = ({ isFiltering, onFilter }) => {

    const [isShow, setIsShow] = useState(false)
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [service, setService] = useState('')
    const [serviceCategory, setServiceCategory] = useState('')
    const queryClient = useQueryClient()

    const handleFilter = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onFilter([
            dateFrom ? new Date(dateFrom.setHours(0, 0, 0, 0)) : '',
            dateTo ? new Date(dateTo.setHours(23, 59, 59, 999)) : '',
            serviceCategory ?? '',
            service ?? ''
        ])
    }

    const handleReset = () => {
        onFilter(['', '', '', ''])
        setDateFrom('')
        setDateTo('')
        setServiceCategory('')
        setService('')
        queryClient.invalidateQueries(['sales', 'paginated'])
    }

    return (
        <>
            <span className='cursor-pointer' onClick={() => setIsShow(!isShow)}><i className="fa-solid fa-list-check"></i> {isShow ? 'Hide' : 'Show'} Filter</span>
            <Collapse in={isShow}>
                <Form onSubmit={handleFilter}>
                    <Card className='mt-2'>
                        <Card.Body>
                            <Row>
                                <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Date From' placeholderText='Filter Date From'
                                        selected={dateFrom} onChange={setDateFrom} maxDate={dateTo || undefined} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Date To' placeholderText='Filter Date To'
                                        selected={dateTo} onChange={setDateTo} minDate={dateFrom || undefined} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <ServiceCategorySelect label="Service Category" placeholder="Filter Service Category" 
                                        value={serviceCategory} onChange={(opt) => setServiceCategory(opt && opt.value)}
                                        isClearable
                                    />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <ServiceSelect label="Service" placeholder="Filter Service" dateFormat="dd.MM.yyyy" value={service} onChange={(opt) => setService(opt && opt.value)} isClearable />
                                </Col>
                            </Row>
                            <div className='mt-3 d-flex justify-content-end'>
                                <Button variant="outline-secondary" className='me-2' onClick={handleReset}>Reset</Button>
                                <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Filtering...' : "Filter"}</Button>
                            </div>
                        </Card.Body>
                    </Card>

                </Form>
            </Collapse>
        </>

    )
}

export default SalesFilter