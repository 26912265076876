import http from './http'
import { getToken } from './auth'

const base = 'Users'

export async function getSelf() {
    const { data } = await http.get(`${base}/self`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function login(account) {
    return await http.post(base + '/login', account)
}

export async function register(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    const { data } = await http.post(base + '/register', payload, config)
    return data
}

export async function updateUser(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}

export async function updateProfile(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/self', payload, config)
    return data
}

export async function deleteUser(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}