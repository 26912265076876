import http from "./http"
import { getToken } from './auth'

const base = 'Clients'

export const getPaginatedClients = async (pageNumber, pageSize, searchText) => {

    const params = new URLSearchParams()
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)

    searchText && params.append('searchText', searchText)

    const { data } = await http.get(`${base}/paginated`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export const getClientsAsOptions = async (searchText) => {

    const params = new URLSearchParams()
    searchText && params.append('searchText', searchText)

    const { data } = await http.get(`${base}/options-search`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export async function getClientById(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createClient(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.post(base + '/', payload, config)
    return data
}

export async function updateClient(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}

export async function deleteClient(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}