export const isCleaningAndOnwards = (job) => {
    if (job.status === "CLEANING" || job.status === "CLEANING_COMPLETED" || job.status === "DISPATCH_IN_PROGRESS" || job.status === "COMPLETED")
        return true
    return false
}

export const isCleaningCompletedAndOnwards = (job) => {
    if (job.status === "CLEANING_COMPLETED" || job.status === "DISPATCH_IN_PROGRESS" || job.status === "COMPLETED")
        return true
    return false
}