import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Row, ProgressBar } from 'react-bootstrap';

const initStatusOptions = [
    { value: 'SCHEDULED', label: 'Scheduled', iconClass: 'fa-solid fa-clock', isActive: true },
    { value: 'ASSIGNED', label: 'Assigned', iconClass: 'fa-solid fa-user-check', isActive: false },
    { value: 'PICKUP_IN_PROGRESS', label: 'Pickup in Progress', iconClass: 'fa-solid fa-truck-pickup', isActive: false },
    { value: 'PICKUP_COMPLETED', label: 'Pickup Completed', iconClass: 'fa-solid fa-check-to-slot', isActive: false },
    { value: 'CLEANING', label: 'Cleaning', iconClass: 'fa-solid fa-broom', isActive: false },
    { value: 'CLEANING_COMPLETED', label: 'Cleaning Completed', iconClass: 'fa-solid fa-soap', isActive: false },
    { value: 'DISPATCH_IN_PROGRESS', label: 'Dispatch in Progress', iconClass: 'fa-solid fa-user-clock', isActive: false },
    { value: 'COMPLETED', label: 'Completed', iconClass: 'fa-solid fa-check', isActive: false },
]


const StatusSelector = ({ value, onChange }) => {

    const [statusOptions, setStatusOptions] = useState(initStatusOptions)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(1)

    useEffect(() => {
        let _index = statusOptions.findIndex(x => x.value === value)
        handleSelectStatus(_index)
    }, [value])

    const handleSelectStatus = (index) => {

        setSelectedOptionIndex(index)
        onChange(statusOptions[index].value)
        let _statusOptions = statusOptions

        for (let x = 0; x < statusOptions.length; x++) {
            if (x <= index)
                _statusOptions[x].isActive = true
            else {
                _statusOptions[x].isActive = false
            }
        }


        setStatusOptions([..._statusOptions])
    }

    return (
        <div className="position-relative">
            <div className="position-absolute status-selector-line" style={{ zIndex: 0 }}>
                <ProgressBar style={{ height: '5px' }} variant="primary" now={selectedOptionIndex * 14} />
            </div>
            <Row>
                {statusOptions.map((option, index) => (
                    <Col style={{ width: '12.5%' }} key={`status-col-${index}`}>
                        <div className="center-xy">
                            <div className={`status-selector ${option.isActive ? 'is-active' : ''}`}>
                                <div className="d-flex justify-content-center">
                                    <div title={option.label} onClick={() => handleSelectStatus(index)} className="status-selector-circle rounded-circle d-flex align-items-center justify-content-center color-primary cursor-pointer"
                                        style={{ zIndex: 0 }}>
                                        <div>
                                            <i className={option.iconClass}></i>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-center mb-0 d-lg-block d-none"><small style={{ fontSize: '0.55rem', textWrap: 'nowrap' }}>{option.label}</small></p>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default StatusSelector;