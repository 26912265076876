import chroma from 'chroma-js';

export const get = (count) => {
    const baseColor = '#66cc99'; // Base color to start with
  
    // Generate a color scale from the base color
    const scale = chroma.scale(['#f1828d', baseColor, '#00b5cc']).mode('lch').colors(count);
  
    // Adjust the brightness of the colors
    const adjustedColors = scale.map((color) => chroma(color).brighten(0.4).hex());
  
    return adjustedColors;
};