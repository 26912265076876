import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { isAdmin, isDriver } from '../utils/helper';
import { isUserAllowed } from '../utils/roleHelpers';

const Sidebar = ({ sidebarToggle, setSidebarToggle, currentUser }) => {

    const photoBaseUrl = process.env.REACT_APP_API_BASE_URL + 'files/profile-photos/'

    const SubMenu = ({ title, menu = [] }) => {

        const [isExpand, setIsExpand] = useState(false)

        return (
            <li>
                <span onClick={() => setIsExpand(!isExpand)} className={`nav-link cursor-pointer ${isExpand ? 'expand' : ''}`}>
                    <div className="px-2 py-2 rounded">
                        <i className="fa-solid fa-chart-simple"></i>
                        <i className={`text-muted fa-solid fa-chevron-${isExpand ? 'down' : 'right'}`}></i>
                        <span className="me-2">{title}</span>

                    </div>
                </span>
                <Collapse in={isExpand}>
                    <ul className="sub-menu my-2">
                        {menu.length > 0 && menu.map((m, index) =>
                            <NavLink key={`menu-${title}-${index}`} to={`/reports/${m.path}`}>
                                <li className="nav-link my-2"><i className="fa-solid fa-bookmark"></i> {m.title}</li>
                            </NavLink>
                        )}
                    </ul>
                </Collapse>
            </li>
        )
    }

    return (
        <>
            <nav id="sidebar" className={`col-md-3 col-lg-2 sidebar px-0 ${sidebarToggle ? 'expanded' : ''}`}>
                <div id="sidebar-close" onClick={() => setSidebarToggle(false)} className="sidebar-close d-block d-md-none px-3"><i className="fas fa-times text-muted"></i></div>
                <div className="sidebar-sticky">

                    <div className="sidebar-top">
                        <div className="center-xy p-3">
                            <Link to='/'><img className="logo-img px-2" src={logo} alt="My ViVo Bot Logo" /></Link>
                        </div>
                    </div>

                    <ul className="nav flex-column mb-3">
                        {isUserAllowed('/home') &&
                            <NavLink to='/home'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-solid fa-table-columns"></i> Overview</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/jobs') &&
                            <NavLink to='/jobs'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-solid fa-bars-progress"></i> Jobs</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/sales') &&

                            <NavLink to='/sales'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-solid fa-receipt"></i> Sales</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/clients') &&

                            <NavLink to='/clients'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-regular fa-user"></i> Clients</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/expenses') &&

                            <NavLink to='/expenses'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-regular fa-credit-card"></i> Expenses</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/payrolls') &&

                            <NavLink to='/payrolls'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-brands fa-paypal"></i> Payroll</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/cash-flow') &&

                            <NavLink to='/cash-flow'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-solid fa-scale-balanced"></i> Cash Flow</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/calendar') &&

                            <NavLink to='/calendar'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-regular fa-calendar"></i> Calendar</div></span></li>
                            </NavLink>
                        }

                        {isUserAllowed('/reports/job-reports') &&

                            <SubMenu title={"Reports"}
                                menu={[
                                    { title: 'Jobs History', path: 'jobs-history' },
                                    { title: 'Jobs Performance', path: 'jobs-performance' },
                                    { title: 'Assignees', path: 'jobs-assignees' },
                                    { title: 'Services', path: 'services-reports' },
                                    { title: 'Expenses', path: 'expenses' },
                                ]}
                            />
                        }

                        {isUserAllowed('/services') &&

                            <NavLink to='/services'>
                                <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-solid fa-hand-sparkles"></i> Services</div></span></li>
                            </NavLink>
                        }

                    </ul>

                    {isAdmin() &&

                        <div className={`sidebar-bottom col-lg-2 col-md-3 py-3 ${sidebarToggle ? 'expanded' : ''}`}>
                            <ul className="nav flex-column">
                                <NavLink to='/settings' className="mb-0">
                                    <li><span className="nav-link cursor-pointer"><div className="px-2 py-2 rounded"><i className="fa-solid fa-gear"></i>Settings</div></span></li>
                                </NavLink>
                            </ul>
                        </div>

                    }

                </div>

            </nav>
        </>
    )
}

export default Sidebar;