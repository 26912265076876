import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CashFlowTransactionsFilter from './cashflowTransactionsFilter';

const CashFlowTransactionsPage = () => {

    const [filters, setFilters] = useState()

    return (
        <div>
            <h4 className="page-title">Transaction Log</h4>

            <CashFlowTransactionsFilter onFilter={setFilters} />

            <Card className="mt-3 mb-4">
                <Card.Body>

                    <Row className="mb-2">
                        <Col lg="6">
                            
                        </Col>
                        <Col>
                            <div className="text-right d-flex justify-content-lg-end">
                                <Form.Control as="select" className="w-auto d-inline mr-2" size="sm" onChange={(e) => {}} defaultValue="">
                                    <option value="" disabled>Select Page Size</option>
                                    <option value="2">2 per page</option>
                                    <option value="10">10 per page</option>
                                    <option value="20">20 per page</option>
                                    <option value="50">50 per page</option>
                                    <option value="100">100 per page</option>
                                    <option value="250">250 per page</option>
                                    <option value="500">500 per page</option>
                                </Form.Control>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
        </div>
    );
};

export default CashFlowTransactionsPage;