import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import OverlayLoader from '../overlayLoader';
import { getDriverDeliveries } from '../../services/overview'
import { useQuery } from 'react-query';
import { onQuerySuccess } from '../../utils/queryResponse';
import { get as generateNiceColors} from '../../utils/niceColors'

ChartJS.register(CategoryScale, Tooltip, Legend, LineElement, PointElement, LinearScale, Title);

const DriverDeliveriesChart = ({filters}) => {

    const [chartData, setChartData] = useState()

    const [options, setOptions] = useState()

    const query = useQuery(['overview-deliveries', filters], () => getDriverDeliveries(...filters), {
        keepPreviousData: true,
        staleTime: 1000 * 60, // 1 minute
        refetchOnMount: true,
    })

    const { data, isFetching } = query

    useEffect(() => {
        onQuerySuccess(data, (result) => {

            setOptions({
                plugins: {
                    legend: {
                        position: 'top',
                        labels:{
                            usePointStyle:true,
                            pointStyle: 'rectRounded'
                        },
                        display: result?.datasets?.length > 0
                    }//,
                    // title: {
                    //     display: true,
                    //     text: 'Driver Deliveries Chart',
                    // },
                    
                },
                maintainAspectRatio: false,
                responsive: true,
            })
    
            const { datasets, labels } = result
            const colors = generateNiceColors(datasets.length);
            datasets.forEach((element, index) => {
                const randomHexColor = colors[index]
                element.borderColor = randomHexColor
                element.lineTension = .5
            });
    
            setChartData({
                labels: labels,
                datasets: datasets.length > 0 ? datasets : ['']
            })
        })
    }, [data])
    
    return (
        <div style={{position:'relative', height: '350px'}}>
            {chartData &&
                <Line options={options} data={chartData} />
            }
            { isFetching &&
                <OverlayLoader />
            }
        </div>
    )
}

export default DriverDeliveriesChart