import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import FormDatePicker from '../../components/forms/formDatePicker';
import ImportSheet from '../../components/importSheet';
import { createExpenseMulti, importExpenseCSV } from '../../services/expenses';
import { onMutateSuccess } from '../../utils/queryResponse';
import FormInput from '../../components/forms/formInput';

const initExpense = {
    expenseType: '',
    amount: '',
    date: new Date(),
    description: '',
}

const ExpenseSheetImportModal = ({
    display,
    toggle
}) => {

    const [expenses, setExpenses] = useState([])

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(createExpenseMulti, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['expenses', 'paginated'])
            queryClient.invalidateQueries('cash-flow')
            onHide()
        })
    })

    const onShow = () => {
        setExpenses([])
    }

    const onHide = () => {
        toggle(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        mutate(expenses)
    }

    const handleChange = (index, objValue) => {
        const newExpense = { ...expenses[index], ...objValue }
        let _expenses = expenses
        _expenses[index] = newExpense
        setExpenses([..._expenses])
    }

    const handleAddExpenseToAdd = () => {
        let _expenses = expenses
        _expenses.push(initExpense)
        setExpenses([..._expenses])
    }

    const handleRemoveExpenseToAdd = (index) => {
        let _expenses = expenses
        _expenses.splice(index, 1)
        setExpenses([..._expenses])
    }

    return (
        <>

            <Modal
                show={display}
                onHide={onHide}
                size="xl"
                onShow={onShow}
                centered
                backdrop="static"
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center my-3">Import CSV or Excel</h4>

                    <p>CSV or Excel file should have the following columns: <strong>Date</strong>, <strong>Amount</strong>, <strong>Expense Type</strong>, <strong>Description</strong></p>

                    <ImportSheet setData={setExpenses} importAction={importExpenseCSV} />

                    <hr />

                    <Form id='set-expense' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>

                        {expenses.length > 0 && expenses.map((expense, index) => (
                            <div key={`expense-${index}`}>
                                <div className="d-flex">
                                    <div style={{ width: '100px' }}>
                                        <h4 className="text-muted">
                                            <span className="me-2">#{index + 1}</span>
                                            <Button title="remove row" onClick={() => handleRemoveExpenseToAdd(index)} size="sm" variant="outline-grey" style={{ fontSize: '10px' }}>
                                                <i className="fa-solid fa-trash text-muted"></i>
                                            </Button>
                                        </h4>

                                    </div>
                                    <div className="w-100">
                                        <Row className="mb-3">
                                            <Col lg="4">
                                                <FormInput className="mb-2" label="Expense Type" value={expense.expenseType ?? ''}
                                                    onChange={(e) => handleChange(index, { expenseType: e.target.value })}
                                                    placeholder='Enter Expense Type'
                                                    required
                                                />
                                            </Col>
                                            <Col lg="4">
                                                <FormInput className="mb-2" label="Amount" value={expense?.amount ?? ''}
                                                    onChange={(e) => handleChange(index, { amount: e.target.value })}
                                                    type='number' min='1' step='0.01' placeholder='Enter Amount'
                                                    required
                                                />
                                            </Col>
                                            <Col lg="4">
                                                <FormDatePicker className="form-control mb-2" label="Expense Date" selected={expense?.date ?? ''}
                                                    onChange={(date) => handleChange(index, { date })}
                                                    required
                                                />
                                            </Col>
                                            <Col lg="12">
                                                <FormInput className="form-control mb-2" label="Description" value={expense?.description ?? ''}
                                                    onChange={(e) => handleChange(index, { description: e.target.value })}
                                                    as='textarea'
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))}

                        <Row className='d-flex justify-content-between'>
                            <Col lg="6" className="mb-3 mb-lg-0">
                                {expenses.length > 0 &&
                                    <Button className="me-2 mb-2 mb-lg-0" variant="outline-primary" onClick={() => handleAddExpenseToAdd()}>Add More Row</Button>
                                }
                            </Col>
                            <Col lg="6">
                                <div className="text-end">
                                    <Button className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                                    <Button type='submit' disabled={isLoading || expenses.length === 0}>
                                        {isLoading ? 'Submitting' : 'Submit'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default ExpenseSheetImportModal