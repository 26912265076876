import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Collapse, Form, FormSelect, InputGroup, Modal, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import ClientInfoModal from '../../pages/clients/clientInfo';
import SetClientModal from '../../pages/clients/clientsSet';
import SetLineItemModal from '../../pages/jobs/lineItemSet';
import { getAvailableBadges, getBadgesByIdList } from '../../services/badges';
import { getUsersAsOptions } from '../../services/companyUsers';
import { getJobActivities } from '../../services/jobActivities';
import { deleteJob, getJobById, updateJob } from '../../services/jobs';
import { createLineItem, deleteLineItem, updateLineItem } from '../../services/lineItems';
import { getSaleInvoice } from '../../services/sales';
import { jobLabel, lineItemLabel } from '../../utils/constants';
import { roundDecimal, rsdFormat, toLocalDateString, toTitleCase } from '../../utils/helper';
import { isCleaningAndOnwards, isCleaningCompletedAndOnwards } from '../../utils/jobHelper';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { isUserAdmin } from '../../utils/roleHelpers';
import DatePicker from '../forms/formDatePicker';
import FormInput from '../forms/formInput';
import OverlayLoader from '../overlayLoader';
import SalesInvoicePrint from '../print/salesInvoice';
import AssigneeSelect from '../selects/assigneeSelect';
import BadgeSelect from '../selects/badgeSelect';
import ClientsSelect from '../selects/clientsSelect';
import ServiceSelect from '../selects/serviceSelect';
import StatusSelector from '../statusSelector';
import ConfirmationModal from './confirmation';

const initJob = {
    clientId: '',
    pickupType: 'PICKUP_FROM_HOME',
    pickupTime: null,
    status: 'SCHEDULED',
    completionTime: null,
    paymentStatus: 'PENDING',
    paymentMethod: null,
    lineItemsTemp: [{
        quantity: 0,
        price: 0,
        taxRate: 0,
        taxAmount: 0,
        priceWithTax: 0,
        serviceId: '',
        badgeId: ''
    }],
    paymentType: null,
    paymentTime: null,
    pickupAssignedToId: null,
    cleaningAssignedToId: null,
    deliveryAssignedToId: null,
    note: ''
}

const SetTempEditOrderModal = ({
    display,
    toggle,
    jobId,
    setSelectedJob,
    alert,
    setAlert
}) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [deleteJobConfirmation, setDeleteJobConfirmation] = useState(false)
    const [setClientModal, setSetClientModal] = useState(false)
    const [setLineItemModal, setSetLineItemModal] = useState(false)
    const [clientInfoModal, setClientInfoModal] = useState(false)

    const [clientRawName, setClientRawName] = useState('')
    const [clientOptions, setClientOptions] = useState([])
    const [assigneesOptions, setAssigneesOptions] = useState([])
    const [isAdvance, setIsAdvance] = useState(false)
    const [showJobActivities, setShowJobActivities] = useState(false)
    const [availableBadges, setAvailableBadges] = useState([])
    const [selectedBadges, setSelectedBadges] = useState([])

    const [job, setJob] = useState(initJob)
    const [isLoadingLineItemBadgeIds, setIsLoadingLineItemBadgeIds] = useState(false)
    const [lineItemBadges, setLineItemBadges] = useState([])
    //const [jobActivities, setJobActivities] = useState([])
    const [groupedJobActivities, setGroupedJobActivities] = useState([])
    const [lineItemToEdit, setLineItemToEdit] = useState()
    const [lineItemToDelete, setLineItemToDelete] = useState()
    const [lineItemToDeleteIndex, setLineItemToDeleteIndex] = useState()

    const [invoice, setInvoice] = useState()
    const [isPrinting, setIsPrinting] = useState(false)
    const invoiceRef = useRef()

    const { data: dbJob, isFetching } = useQuery(['jobs', 'details', jobId], () => jobId && getJobById(jobId))
    const { data: dbJobActivities, isFetching: isFetchingJobActivities } = useQuery(['job-activities', jobId], () => jobId && getJobActivities(jobId))
    const { data: dbAssigneesOptions, isLoading: isLoadingAssigneesOptions } = useQuery(['users-option'], () => getUsersAsOptions())
    const { data: dbAvailableBadges, isLoading: isAvailableBadgesLoading, isFetching: isAvailableBadgesFetching } = useQuery(['available-badges'], () => getAvailableBadges())
    const { data: dbSaleInvoice, isFetching: isFetchingSaleInvoice } = useQuery(['getSaleInvoice', jobId], () => jobId && getSaleInvoice(jobId))

    useEffect(() => {

        if (!dbAvailableBadges || !dbAssigneesOptions || !dbJobActivities || !dbJob)
            return

        onQuerySuccess(dbAvailableBadges, (result) => {
            if (result.length > 0) {
                setAvailableBadges(result.map(d => ({
                    value: d.id, label: d.badgeNo
                })))
            }
        })

        onQuerySuccess(dbAssigneesOptions, (result) => {
            if (result.length > 0) {
                setAssigneesOptions(result.map(d => ({
                    value: d.id, label: d.fullName, roles: d.roles
                })))
            }
        })

        onQuerySuccess(dbJobActivities, (result) => {
            let _groupedActivities = groupActivitiesByDateCreated(result)
            setGroupedJobActivities(_groupedActivities)
            //setJobActivities(result)
        })

        onQuerySuccess(dbJob, (result) => {
            setIsAdvance(true)
            setClientOptions([{ value: result.clientId, label: `${result.client.firstName} ${result.client.lastName}` }])
            createJobLineItemsTemp(result)

            const _lineItemBadgeIds = result.lineItems.map(x => x.badgeId)
            if (_lineItemBadgeIds.length > 0) {

                const fetchBadges = async () => {

                    let _lineItemBadges = []
                    setIsLoadingLineItemBadgeIds(true)

                    const { code, message } = await getBadgesByIdList(_lineItemBadgeIds)
                    if (code === 200)
                        _lineItemBadges = message.map(x => { return { value: x.id, label: x.badgeNo } })

                    setLineItemBadges(_lineItemBadges)
                }

                fetchBadges()
                    .then(() => { setIsLoadingLineItemBadgeIds(false) })
                    .catch(console.error);
            } else {
                setLineItemBadges([])
            }

        })

    }, [dbAvailableBadges, dbAssigneesOptions, dbJobActivities, dbJob])

    const groupActivitiesByDateCreated = (activities) => {

        const groupedActivities = activities.reduce((group, activity) => {
            const { createdAt } = activity;
            const createdAtDate = toLocalDateString(new Date(createdAt), 'yyyy-MM-dd')

            group[createdAtDate] = group[createdAtDate] ?? [];
            group[createdAtDate].push(activity);
            return group;
        }, {});

        const groupedActivitiesArray = Object.keys(groupedActivities).map((createdAtDate) => {
            return {
                createdAtDate,
                activities: groupedActivities[createdAtDate]
            };
        });

        const sortedGroupedActiviesArray = groupedActivitiesArray.sort(function (a, b) { return new Date(b.createdAtDate) - new Date(a.createdAtDate) })
        return sortedGroupedActiviesArray
    }

    const createJobLineItemsTemp = (result = job) => {
        const lineItemsTemp = result.lineItems.map(x => {
            return { ...x, pricePerUnit: parseFloat(x.service.pricePerUnit), unit: x.service.unit }
        })

        let _dbJob = { ...result, lineItemsTemp };
        _dbJob = { ..._dbJob, ...{ clientId: result.clientId } };

        const _selectedBadges = _dbJob.lineItemsTemp.map(x => x.badgeId)
        setSelectedBadges(_selectedBadges)

        console.log(_dbJob)
        setJob(_dbJob)
    }

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(updateJob, {
        onSuccess: onMutateSuccess((a) => {
            queryClient.invalidateQueries(['jobs'])
            queryClient.invalidateQueries(['jobs', 'details', jobId])
            queryClient.invalidateQueries(['job-activities', jobId])
            queryClient.invalidateQueries(['calendar-data'])
            queryClient.invalidateQueries(['available-badges'])
            queryClient.invalidateQueries(['job-reports'])
            queryClient.invalidateQueries(['jobs-performance'])
            queryClient.invalidateQueries(['sales'])
            onHide()
        })
    })

    const { mutate: mutateDeleteJob, isLoading: isDeletingJob } = useMutation(deleteJob, {
        onSuccess: onMutateSuccess((result) => {
            queryClient.invalidateQueries(['jobs'])
            toggle(false)
        }, isUserAdmin() ? ' Successfully deleted a job ' : ' Delete request was sent to the admin ')
    })

    const { mutate: mutateDeleteLineItem, isLoading: isDeletingLineItem } = useMutation(deleteLineItem, {
        onSuccess: onMutateSuccess((result, id) => {
            queryClient.invalidateQueries(['jobs', 'details', jobId])
            queryClient.invalidateQueries(['available-badges'])

        }, ' Successfully deleted a line item ')
    })

    const onHide = () => {
        setIsAdvance(false)
        alert && setAlert(false)
        //setSelectedJob && setSelectedJob({})
        //setJob(initJob)
        toggle(false)
    }

    const onShow = () => {

    }

    const updateLineItems = async (lineItems) => {
        const promises = []
        for (const element of lineItems) {
            if (element.isTouched) {
                if (element.id) {
                    // await mutateUpdateLineItem({ ...element, jobId });
                    promises.push(updateLineItem({ ...element, jobId }));
                } else {
                    // await mutateCreateLineItem({ ...element, jobId });
                    promises.push(createLineItem({ ...element, jobId }));
                }
            }
        }
        return Promise.all(promises)
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        let _job = job
        _job.price = parseFloat(getTotalPriceRaw())

        if (!_job.pickupAssignedToId) {
            _job.pickupAssignedToId = null
            _job.pickupAssignedTo = null
        }
        if (!_job.cleaningAssignedToId) {
            _job.cleaningAssignedToId = null
            _job.cleaningAssignedTo = null
        }
        if (!_job.deliveryAssignedToId) {
            _job.deliveryAssignedToId = null
            _job.deliveryAssignedTo = null
        }

        mutate({ ..._job, updateLineItems })
    }

    const handleChange = (objValue) => {

        const newOrder = { ...job, ...objValue }
        if (newOrder.pickupType === "CUSTOMER_DELIVERS")
            newOrder.pickupTime = null
        if (newOrder.pickupType === "PICKUP_FROM_HOME")
            newOrder.deliveredByCustomerTime = null

        setJob(newOrder)
    }

    const handleAddLineItem = () => {
        let _order = job
        let _lineItems = job.lineItemsTemp
        _lineItems.push({
            quantity: 0,
            price: 0,
            taxRate: 0,
            taxAmount: 0,
            priceWithTax: 0,
            serviceId: ''
        })
        _order.lineItemsTemp = _lineItems
        setJob({ ..._order })
    }

    const handleLineItemChange = (objValue, index) => {

        const _lineItems = [...job.lineItemsTemp]; // Create a new copy of the array
        _lineItems[index] = { ..._lineItems[index], ...objValue }

        let _quantity = _lineItems[index].quantity

        if (_lineItems[index].unit === 'm2') {
            const length = +_lineItems[index].length || 0
            const width = +_lineItems[index].width || 0
            _quantity = roundDecimal(length * width, 2)
        }

        _lineItems[index].quantity = _quantity

        let _price = +_lineItems[index].pricePerUnit * +_lineItems[index].quantity
        _lineItems[index].price = roundDecimal(_price, 2)

        let _taxAmount = (+_lineItems[index].taxRate / 100) * +_lineItems[index].price
        _lineItems[index].taxAmount = roundDecimal(_taxAmount, 2)

        let _priceWithTax = +_lineItems[index].price + +_lineItems[index].taxAmount
        _lineItems[index].priceWithTax = roundDecimal(_priceWithTax, 2)

        _lineItems[index].isTouched = true

        const _selectedBadges = _lineItems.map(x => x.badgeId)
        setSelectedBadges(_selectedBadges)

        setJob((prevJob) => ({
            ...prevJob,
            lineItemsTemp: _lineItems // Update the state with the new array
        }));
    }

    const getTotalPriceRaw = () => {
        var sum = job.lineItemsTemp?.reduce(function (acc, obj) {
            return parseFloat(acc) + parseFloat(obj.priceWithTax);
        }, 0);
        return sum;
    }

    const getTotalPrice = () => {
        var sum = job.lineItemsTemp?.reduce(function (acc, obj) {
            return parseFloat(acc) + parseFloat(obj.priceWithTax);
        }, 0);
        return rsdFormat(sum)
    }

    const onCreateClient = (newClient) => {

        let _clientOptions = clientOptions
        _clientOptions.push({
            value: newClient.id,
            label: newClient.name
        })

        let _job = job
        _job.clientId = newClient.id
        setJob({ ..._job })
    }

    const handlePaymentStatusChange = (checked) => {
        handleChange({ paymentStatus: checked ? 'PAID' : 'PENDING' })
    }

    const handleServiceSelect = (option, index) => {
        handleLineItemChange({ serviceId: option.value, pricePerUnit: parseFloat(option.ppu), unit: option.unit, quantity: 0 }, index)
    }

    const getActivityActionMessage = (activity) => {

        return <>
            <span className='fw-bold'>{activity.createdBy.fullName}</span>
            {` ${activity.message.action}d ${activity.message.object} at ${toLocalDateString(activity.createdAt)}`}
        </>
    }

    const getVariantIfStatusProp = (prop, progress) => {
        if (prop === "Status") {
            if (progress.toLowerCase() === "scheduled" || progress.toLowerCase() === "assigned")
                return "text-secondary"
            if (progress.toLowerCase() === "pickup_in_progress" || progress.toLowerCase() === "pickup_completed")
                return "text-info"
            if (progress.toLowerCase() === "cleaning" || progress.toLowerCase() === "cleaning_completed" || progress.toLowerCase() === "dispatch_in_progress")
                return "text-warning"
            if (progress.toLowerCase() === "completed")
                return "text-success"
        }
    }

    const getActivityUpdateMessage = (update, object) => {
        const objectLabels = object === 'Job' ? jobLabel : lineItemLabel
        const { prop, from, to } = update

        return <>
            <small className='fw-bold'>{objectLabels[prop] || toTitleCase(prop)}</small>
            {` from `}
            <small className={`fw-bold ${getVariantIfStatusProp(objectLabels[prop], from)}`}>{formatValByProp(from, prop)}</small>
            {` to `}
            <small className={`fw-bold ${getVariantIfStatusProp(objectLabels[prop], to)}`}>{formatValByProp(to, prop)}</small>
        </>
    }

    const formatValByProp = (val, prop) => {
        if (prop === "deliveryAssignedToId" || prop === "pickupAssignedToId" || prop === "cleaningAssignedToId")
            return val?.fullName ?? 'Unassigned'
        else if (prop === "completionTimeActual" || prop === "dispatchTime" || prop === "pickupTime" || prop === "completionTime" || prop === "pickupInProgressTime")
            return val ? toLocalDateString(val) : 'No Time'
        else if (prop === "serviceId")
            return val?.name
        else if (prop === "clientId")
            return `${val?.firstName} ${val?.lastName}`
        else
            return val
    }

    const handleShowConfirmation = (lineItem, index) => {
        setConfirmationModal(true)
        setLineItemToDelete(lineItem)
        setLineItemToDeleteIndex(index)
    }

    const handleDeleteLineItem = () => {
        if (lineItemToDelete.id)
            mutateDeleteLineItem(lineItemToDelete.id)
        else {
            const lineItems = job.lineItemsTemp.filter((item, index) => index !== lineItemToDeleteIndex)
            setJob({
                ...job,
                lineItemsTemp: lineItems
            })
        }
    }

    const handleSelectDate = (prop, date) => {

        let _date = date

        const dateObj = new Date(date)
        //check if midnight, means no time is selected then set to 8:00 as default
        if (dateObj.getHours() === 0 && dateObj.getMinutes() === 0 && dateObj.getSeconds() === 0) {
            _date = setHours(setMinutes(date, 0), 8)
        }

        handleChange({ [prop]: _date })
    }

    const activityHeaderText = (date) => {
        if (isToday(new Date(date)))
            return 'Today'
        if (isYesterday(new Date(date)))
            return 'Yesterday'
        return toLocalDateString(new Date(date), 'dd.MM.yyyy')
    }

    const getDispatchMaxTime = (completionDateTime) => {
        let maxTime = setHours(setMinutes(new Date(), 59), 23)
        let dispatchTime = new Date(completionDateTime).getTime()
        if (dispatchTime < maxTime)
            return new Date(completionDateTime).setMinutes(new Date(completionDateTime).getMinutes() - 5);
        else
            return maxTime
    }

    const getCompletionMinTime = (dispatchDateTime) => {
        let minTime = setHours(setMinutes(new Date(), 0), 5)
        let dispatchTime = new Date(dispatchDateTime).getTime()
        if (minTime < dispatchTime)
            return new Date(dispatchDateTime).setMinutes(new Date(dispatchDateTime).getMinutes() + 15);
        else
            return minTime
    }

    const getLineItemMinQuantity = (lineItemUnit) => {

        if (job.status === "CLEANING_COMPLETED" ||
            job.status === "DISPATCH_IN_PROGRESS" ||
            job.status === "COMPLETED"
        ) {
            return lineItemUnit === 'Piece' ? 1 : 0.01
        } else {
            return 0
        }
    }

    const handleDeleteJob = () => {
        mutateDeleteJob(job.id)
    }

    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        onAfterPrint: () => {
            setIsPrinting(false);
        }
    });

    const handlePrint = async () => {

        setIsPrinting(true)
        printInvoice()
    }

    useEffect(() => {
        onQuerySuccess(dbSaleInvoice, (result) => {
            setInvoice(result)
        })
    }, [dbSaleInvoice]);

    return (
        <>
            <div ref={invoiceRef} className="hide-on-screen">
                <SalesInvoicePrint invoice={invoice} />
            </div>

            <ConfirmationModal display={deleteJobConfirmation} toggle={setDeleteJobConfirmation} title='Delete Job'
                message="Are you sure you want to delete this job?" confirmAction={handleDeleteJob}
            />
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Line Item'
                message="Are you sure you want to delete this line item?" confirmAction={handleDeleteLineItem}
            />
            <SetLineItemModal display={setLineItemModal} toggle={setSetLineItemModal} lineItemToEdit={lineItemToEdit} jobId={jobId} />
            <SetClientModal display={setClientModal} toggle={setSetClientModal} onCreateClient={onCreateClient} suggestName={clientRawName} />
            <ClientInfoModal display={clientInfoModal} toggle={setClientInfoModal} clientId={job.clientId} />

            <Modal
                show={display}
                onShow={onShow}
                onHide={onHide}
                size="xl"
                centered
                backdrop="static"
                style={{ overflowY: "scroll" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ width: '98%' }}>
                        <h4 className="text-center mt-3">Edit Job #{jobId}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-1' >

                    {alert &&
                        <Alert key="danger" variant="danger" onClose={() => setAlert()} dismissible>
                            Erorr! <span className="text-bold">{alert}</span>
                        </Alert>
                    }

                    <Form id='edit-job' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col xs="12">
                                <Row className="mb-2">
                                    <Col lg="6">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label>Client <span className="text-danger">*</span></Form.Label>
                                            <div>
                                                <span className="me-2 text-muted"><small>Client not found?</small></span>
                                                <Button style={{ fontSize: '0.70rem' }} className="mb-1 py-0 px-2" size="sm" variant="light" onClick={() => setSetClientModal(true)}><i className="fas fa-plus"></i> Add New</Button>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                {
                                                    job.clientId &&
                                                    <div style={{ width: '90%' }}>
                                                        <ClientsSelect isFromEditJobModal={true} value={job.clientId} onChange={(option) => handleChange({ clientId: option.value })} options={clientOptions} setOptions={setClientOptions} setClientRawName={setClientRawName} />
                                                    </div>
                                                }
                                                {job.clientId &&
                                                    <div style={{ width: '10%' }}>
                                                        <div className="d-flex center-xy">
                                                            <i title="view client details" onClick={() => setClientInfoModal(true)} className="fa-solid fa-circle-info text-muted cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-2">
                                    <Form.Label>Pickup type <span className="text-danger">*</span></Form.Label>
                                    <FormSelect value={job.pickupType} onChange={(e) => handleChange({ pickupType: e.target.value })} >
                                        <option value="" disabled>Select Pickup Type</option>
                                        <option value="PICKUP_FROM_HOME">Pickup From Home</option>
                                        <option value="CUSTOMER_DELIVERS">Customer Delivers</option>
                                    </FormSelect>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <DatePicker selected={job.pickupType === "CUSTOMER_DELIVERS" ? (job.deliveredByCustomerTime ? new Date(job.deliveredByCustomerTime) : null) : (job.pickupTime ? new Date(job.pickupTime) : null)}
                                    label={job.pickupType === "CUSTOMER_DELIVERS" ? 'Customer Deliver Time' : 'Pickup Time'}
                                    onChange={(date) => handleChange({ [job.pickupType === "CUSTOMER_DELIVERS" ? 'deliveredByCustomerTime' : 'pickupTime']: date })}
                                    onSelect={(date) => handleSelectDate(job.pickupType === "CUSTOMER_DELIVERS" ? 'deliveredByCustomerTime' : 'pickupTime', date)}
                                    showTimeSelect
                                    timeIntervals={15}
                                    minTime={setHours(setMinutes(new Date(), 0), 5)}
                                    maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                    timeCaption="Time"
                                    dateFormat="dd.MM.yyyy h:mm aa"
                                    className="form-control mb-2"
                                    required={job.pickupType === "PICKUP_FROM_HOME" || job.pickupType === "CUSTOMER_DELIVERS"}
                                />
                            </Col>
                            <Col xs="12">
                                <Button style={{ width: "100%" }} className="btn-xs" variant='light' onClick={() => setIsAdvance(!isAdvance)}><li className={`fa-solid ${isAdvance ? 'fa-angles-up' : 'fa-angles-down'} fa-2xs`}></li></Button>
                            </Col>
                            <Collapse in={isAdvance}>
                                <Col lg="12">
                                    <div className="my-4">
                                        <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                                        <StatusSelector value={job.status} onChange={(status) => handleChange({ status: status })} />
                                    </div>
                                </Col>
                                {/* <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                                        <FormSelect value={job.status} onChange={(e) => handleChange({ status: e.target.value })} >
                                            <option value="" disabled>Select Status</option>
                                            <option value="SCHEDULED">Scheduled</option>
                                            <option value="ASSIGNED">Assigned</option>
                                            <option value="PICKUP_IN_PROGRESS">Pickup in Progress</option>
                                            <option value="PICKUP_COMPLETED">Pickup Completed</option>
                                            <option value="CLEANING">Cleaning</option>
                                            <option value="CLEANING_COMPLETED">Cleaning Completed</option>
                                            <option value="DISPATCH_IN_PROGRESS">Dispatch in Progress</option>
                                            <option value="COMPLETED">Completed</option>
                                        </FormSelect>
                                    </Form.Group>
                                </Col> */}
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <DatePicker selected={job.dispatchTime ? new Date(job.dispatchTime) : null}
                                        label='Dispatch Time' placeholderText=''
                                        onChange={(date) => handleChange({ dispatchTime: date })}
                                        onSelect={(date) => handleSelectDate('dispatchTime', date)}
                                        showTimeSelect
                                        timeIntervals={15}
                                        maxDate={job.completionTime}
                                        minTime={setHours(setMinutes(new Date(), 0), 5)}
                                        maxTime={getDispatchMaxTime(job.completionTime)}
                                        timeCaption="Time"
                                        dateFormat="dd.MM.yyyy h:mm aa"
                                        className="form-control mb-2"
                                        required={job.status === 'DISPATCH_IN_PROGRESS'}
                                    />
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <DatePicker selected={job.completionTime ? new Date(job.completionTime) : null}
                                        label='Expected Delivery' placeholderText=''
                                        onChange={(date) => handleChange({ completionTime: date })}
                                        onSelect={(date) => handleSelectDate('completionTime', date)}
                                        showTimeSelect
                                        timeIntervals={15}
                                        minDate={job.dispatchTime}
                                        minTime={getCompletionMinTime(job.dispatchTime)}
                                        maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                        timeCaption="Time"
                                        dateFormat="dd.MM.yyyy h:mm aa"
                                        className="form-control"
                                    />
                                    {job.status === 'COMPLETED' && job.completionTimeActual &&
                                        <p className="text-end text-muted"><small>Completed at {toLocalDateString(job.completionTimeActual)}</small></p>
                                    }
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Pickup Assignee'
                                            value={job.pickupAssignedToId ?? ''}
                                            assigneesOptions={assigneesOptions}
                                            filterRole={'driver'}
                                            onChange={(option) => handleChange({ pickupAssignedToId: option?.value })}
                                            required={(job.status === 'ASSIGNED' || job.status === 'PICKUP_IN_PROGRESS' ||
                                                job.status === 'PICKUP_COMPLETED') && job.pickupType === 'PICKUP_FROM_HOME'
                                            }
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Cleaning Assignee'
                                            value={job.cleaningAssignedToId ?? ''}
                                            assigneesOptions={assigneesOptions}
                                            filterRole={'cleaner'}
                                            onChange={(option) => handleChange({ cleaningAssignedToId: option?.value })}
                                            required={job.status === 'CLEANING' || job.status === 'CLEANING_COMPLETED'}
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="4">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Delivery Assignee'
                                            value={job.deliveryAssignedToId ?? ''}
                                            assigneesOptions={assigneesOptions}
                                            filterRole={'driver'}
                                            onChange={(option) => handleChange({ deliveryAssignedToId: option?.value })}
                                            required={job.status === 'DISPATCH_IN_PROGRESS' || job.status === 'COMPLETED'}
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            <Collapse in={isAdvance}>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Payment Status</Form.Label>
                                        <div>
                                            <BootstrapSwitchButton
                                                checked={job.paymentStatus === "PAID" ? true : false}
                                                onlabel='Paid'
                                                offlabel='Pending'
                                                onChange={(checked) => handlePaymentStatusChange(checked)}
                                                width={150}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Collapse>
                            {job.paymentStatus === "PAID" &&
                                <>
                                    <Collapse in={isAdvance}>
                                        <Col lg="3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Payment Method <span className="text-danger">*</span></Form.Label>
                                                <FormSelect value={job.paymentMethod ?? ""} onChange={(e) => handleChange({ paymentMethod: e.target.value })} required>
                                                    <option value="" disabled>Select Payment Method</option>
                                                    <option value="CASH">Cash</option>
                                                    <option value="CREDIT_CARD">Credit Card</option>
                                                    <option value="CHECK">Check</option>
                                                </FormSelect>
                                            </Form.Group>
                                        </Col>
                                    </Collapse>
                                    <Collapse in={isAdvance}>
                                        <Col lg="3">
                                            <DatePicker selected={job.paymentTime ? new Date(job.paymentTime) : null}
                                                label='Payment Time' placeholderText=''
                                                onChange={(date) => handleChange({ paymentTime: date })}
                                                onSelect={(date) => handleSelectDate('paymentTime', date)}
                                                showTimeSelect
                                                timeIntervals={15}
                                                minTime={setHours(setMinutes(new Date(), 0), 5)}
                                                maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                                timeCaption="Time"
                                                dateFormat="dd.MM.yyyy h:mm aa"
                                                className="form-control mb-2"
                                                required
                                            />
                                        </Col>
                                    </Collapse>
                                    <Collapse in={isAdvance}>
                                        <Col lg="3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Payment Type <span className="text-danger">*</span></Form.Label>
                                                <FormSelect value={job.paymentType ?? ""} onChange={(e) => handleChange({ paymentType: e.target.value })} required>
                                                    <option value="" disabled>Select Payment Type</option>
                                                    <option value="BEFORE_SERVICE">Before Service</option>
                                                    <option value="DURING_SERVICE">During Service</option>
                                                    <option value="AFTER_SERVICE">After Service</option>
                                                </FormSelect>
                                            </Form.Group>
                                        </Col>
                                    </Collapse>
                                </>
                            }
                            <Collapse in={isAdvance}>
                                <Col lg="12" className="mb-3">
                                    <FormInput as="textarea" label="Notes" value={job.note ?? ""} onChange={(e) => handleChange({ note: e.target.value })} />
                                </Col>
                            </Collapse>

                            <div className="mt-3 text-end">
                                {jobId && isCleaningAndOnwards(job) &&
                                    <>
                                        <div className="d-inline" style={{ position: 'relative' }}>
                                            <span size="sm" variant="outline-secondary" className={`border-0 mx-2 ${isPrinting ? 'no-pointer-events' : 'cursor-pointer'}`} onClick={() => handlePrint()}>
                                                <small><i className={`fa-solid fa-receipt text-muted me-1`}></i> {`${isPrinting || isFetchingSaleInvoice ? 'preparing...' : 'print invoice'}`}</small>
                                            </span>
                                        </div>

                                        <span className="text-muted">|</span>
                                    </>
                                }
                                <span size="sm" variant="outline-secondary" className="border-0 cursor-pointer mx-2" onClick={() => queryClient.invalidateQueries(['available-badges'])}>
                                    <small><i className={`fa-solid fa-arrows-rotate ${isAvailableBadgesFetching ? 'fa-spin' : ''} text-muted me-1`}></i> refresh available badges</small>
                                </span>
                            </div>

                            <div className="mb-2 p-3 my-2" style={{ backgroundColor: '#f8f9fa' }}>
                                {job?.lineItemsTemp?.length > 0 ?
                                    <>
                                        {job?.lineItemsTemp?.length > 0 && job.lineItemsTemp.map((lineItem, index) => (
                                            <div key={`line-item-${index}`}>
                                                <Row>
                                                    <Col xs="12">
                                                        <Row>
                                                            <Col xl="3">
                                                                <Form.Group className="mb-2">
                                                                    <Form.Label>
                                                                        <i style={{ fontSize: '0.75rem' }} onClick={() => handleShowConfirmation(lineItem, index)} className="fa-solid fa-trash text-muted cursor-pointer me-1"></i>
                                                                        {lineItem?.service?.disabled && 
                                                                            <i className="fa-solid fa-triangle-exclamation text-danger" title={'service disabled'}></i>
                                                                        }
                                                                        <strong className="ms-2">Item #{index + 1}</strong>&nbsp;
                                                                        {lineItem.serviceId ? ` - ${lineItem.pricePerUnit} per ${lineItem.unit}` : ''}
                                                                        <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <ServiceSelect serviceId={lineItem?.serviceId} required value={lineItem.serviceId ?? ''} onChange={(option) => handleServiceSelect(option, index)}
                                                                        isDisabled={lineItem?.service?.disabled} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xl="2" className="pe-0">
                                                                <Form.Group className="mb-2">
                                                                    <Form.Label>Badge Number {isCleaningAndOnwards(job) && <span className="text-danger">*</span>}</Form.Label>
                                                                    <InputGroup className="mb-3 input-group-react-select">
                                                                        <BadgeSelect required={isCleaningAndOnwards(job)} value={lineItem.badgeId ?? ''}
                                                                            isLoading={isAvailableBadgesLoading || isLoadingLineItemBadgeIds}
                                                                            options={([...availableBadges, ...lineItemBadges])
                                                                                .filter(x => !selectedBadges.includes(x.value) || x.value === lineItem.badgeId)
                                                                                .sort(function (a, b) { return a.label - b.label })}
                                                                            onChange={(option) => handleLineItemChange({ badgeId: option.value }, index)}
                                                                            isDisabled={lineItem?.service?.disabled} />
                                                                    </InputGroup>

                                                                </Form.Group>
                                                            </Col>
                                                            {lineItem.unit === 'm2' &&
                                                                <>
                                                                    <Col xl={lineItem.unit === 'm2' ? 1 : 2} className="pe-0">
                                                                        <FormInput value={lineItem.width ?? ''} className="mb-2"
                                                                            label="Width"
                                                                            type="number" min={0.01}
                                                                            step="0.01"
                                                                            onChange={(e) => handleLineItemChange({ width: e.target.value }, index)}
                                                                            required={isCleaningCompletedAndOnwards(job)}
                                                                            disabled={lineItem?.service?.disabled}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={lineItem.unit === 'm2' ? 1 : 2} className="pe-0">
                                                                        <FormInput value={lineItem.length ?? ''} className="mb-2"
                                                                            label="Length"
                                                                            type="number" min={0.01}
                                                                            step="0.01"
                                                                            onChange={(e) => handleLineItemChange({ length: e.target.value }, index)}
                                                                            required={isCleaningCompletedAndOnwards(job)}
                                                                            disabled={lineItem?.service?.disabled}
                                                                        />
                                                                    </Col>
                                                                </>
                                                            }
                                                            <Col xl={lineItem.unit === 'm2' ? 1 : 2}>
                                                                <FormInput value={lineItem.quantity} className="mb-2"
                                                                    title={rsdFormat(+lineItem.quantity, false)}
                                                                    label={lineItem.serviceId ? lineItem.unit : "Quantity"}
                                                                    type="number" min={getLineItemMinQuantity(lineItem.unit)} step={lineItem.unit === 'Piece' ? 1 : 0.01}
                                                                    onChange={(e) => handleLineItemChange({ quantity: e.target.value }, index)}
                                                                    disabled={lineItem.unit === 'm2' || lineItem?.service?.disabled}
                                                                    required
                                                                />
                                                            </Col>
                                                            <Col xl={lineItem.unit === 'm2' ? 1 : 2}>
                                                                <FormInput disabled value={lineItem.price} className="mb-2"
                                                                    title={rsdFormat(+lineItem.price, false)}
                                                                    label="Price" type="number" min="0.01" step="0.01"
                                                                    onChange={(e) => handleLineItemChange({ price: e.target.value }, index)}
                                                                    required
                                                                />
                                                            </Col>
                                                            <Col xl="1">
                                                                <FormInput value={lineItem.taxRate} className="mb-2" label="Tax %" type="number" step='0.01' onChange={(e) => handleLineItemChange({ taxRate: e.target.value }, index)}
                                                                    disabled={lineItem?.service?.disabled} />
                                                            </Col>
                                                            <Col xl="2">
                                                                <FormInput disabled value={lineItem.priceWithTax} className="mb-2" label="Total"
                                                                    title={rsdFormat(+lineItem.priceWithTax, false)}
                                                                    type="number" min="0.01" step="0.01" required
                                                                    onChange={(e) => handleLineItemChange({ priceWithTax: e.target.value }, index)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <p><i>No line items found.</i></p>
                                }

                                <div className="text-end my-3">
                                    <Button size="sm" variant="outline-primary" className="px-4" onClick={() => handleAddLineItem()}>Add</Button>
                                </div>

                            </div>

                            <div className="text-end mt-2">
                                <h5 className="text-bold">Total Price: &emsp; {getTotalPrice()}</h5>
                            </div>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            <Button type="button" onClick={() => setDeleteJobConfirmation(true)} className="me-2" variant="outline-secondary">
                                <i className="fa-solid fa-trash text-muted"></i> Delete
                            </Button>
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>

                    </Form>

                    <hr />
                    <div className="mb-3">
                        <span className='cursor-pointer' onClick={() => setShowJobActivities(!showJobActivities)}><i className="fa-solid fa-list-check"></i> {showJobActivities ? 'Hide' : 'Show'} Job Activities</span>

                        <Collapse in={showJobActivities}>
                            <div>
                                {groupedJobActivities.length && groupedJobActivities.map((activitiesByDate, index) => (
                                    <div key={`grouped-activities-${index}`} className="p-3">
                                        <p className="border-bottom text-center">{activityHeaderText(activitiesByDate.createdAtDate)}</p>
                                        {activitiesByDate.activities.length > 0 && activitiesByDate.activities.map((activity, index) => (
                                            <div key={`activity-${index}`}>
                                                <p className="mb-0" ><small>{getActivityActionMessage(activity)}</small></p>
                                                {activity.message.updates && activity.message.updates.map((update, index) => (
                                                    <p className="mb-0 ms-2" key={`activity-${index}-update-${index}`}><small> - {getActivityUpdateMessage(update, activity.message.object)}</small></p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Collapse>
                    </div>

                    {(isFetching || isAvailableBadgesLoading || isLoadingLineItemBadgeIds || isDeletingLineItem || isLoadingAssigneesOptions || isDeletingJob) &&
                        <OverlayLoader />
                    }

                </Modal.Body>

            </Modal>


        </>
    )
}

export default SetTempEditOrderModal