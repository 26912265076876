import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import FormInput from '../../components/forms/formInput';

const EditPasswordModal = ({ 
    display, 
    toggle,
    size,
    }) => {

    const [data, setData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const handleConfirmAction = async (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleChange = (objVal) => {
        setData({...data, ...objVal})
    }

    const init = () => {
        setData({ 
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        })
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        onShow={init}
        size={size}
        backdropClassName="level-2"
        centered
        backdrop="static"
        >
            <Form onSubmit={handleConfirmAction} className="p-2">
                <Modal.Header>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormInput className="mb-2" label='Old Password' onChange={(e) => handleChange({oldPassword: e.target.value})} value={data.oldPassword} placeholder="Enter current password" type="password" required/>
                    
                    <FormInput className="mb-2" label='New Password' onChange={(e) => handleChange({newPassword: e.target.value})} value={data.newPassword} placeholder="Enter new password" type="password" required/>
                    
                     <FormInput className="mb-2" label='Confirm Password' onChange={(e) => handleChange({confirmPassword: e.target.value})} value={data.confirmPassword} placeholder="Re-enter new password" type="password" required/>

                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => toggle(false)}>Cancel</Button>
                    <Button type='submit'>Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>
  )
}

export default EditPasswordModal

