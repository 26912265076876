import { useState } from 'react';
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import DatePicker from '../../components/forms/formDatePicker';

const CashFlowTransactionsFilter = ({ isFiltering, onFilter }) => {

    const [isShow, setIsShow] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const queryClient = useQueryClient()

    const handleFilter = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onFilter([
            startDate ?? '',
            endDate ?? '',
        ])
    }

    const handleReset = () => {
        onFilter(['', ''])
        setStartDate('')
        setEndDate('')
        queryClient.invalidateQueries(['incomes','paginated'])
    }

    return (
        <>
            <span className='cursor-pointer' onClick={() => setIsShow(!isShow)}><i className="fa-solid fa-list-check"></i> {isShow ? 'Hide' : 'Show'} Filter</span>
            <Collapse in={isShow}>
                <Form onSubmit={handleFilter}>
                    <Card className='mt-2'>
                        <Card.Body>
                            <Row>
                                <Col lg="6" className='mb-2'>
                                    <DatePicker dateFormat="MM/yyyy" showMonthYearPicker className='form-control' label='Date From' placeholderText='Filter Date From'
                                        selected={startDate} onChange={setStartDate} maxDate={endDate || undefined}
                                    />
                                </Col>
                                <Col lg="6" className='mb-2'>
                                    <DatePicker dateFormat="MM/yyyy" showMonthYearPicker className='form-control' label='Date To' placeholderText='Filter Date To'
                                        selected={endDate} onChange={setEndDate} minDate={startDate || undefined}
                                    />
                                </Col>
                            </Row>
                            <div className='mt-3 d-flex justify-content-end'>
                                <Button variant="outline-secondary" className='me-2' onClick={handleReset}>Reset</Button>
                                <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Filtering...' : "Filter"}</Button>
                            </div>
                        </Card.Body>
                    </Card>

                </Form>
            </Collapse>
        </>

    )
}

export default CashFlowTransactionsFilter