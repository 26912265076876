import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FormInput from "../../components/forms/formInput";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSelf, updateProfile } from "../../services/users";
import { onMutateSuccess, onQuerySuccess } from "../../utils/queryResponse";
import { useState } from "react";
import EditPasswordModal from "./passwordEdit";

const ProfilePage = () => {

    const [profile, setProfile] = useState()
    const [editPasswordModal, setEditPasswordModal] = useState(false)
    const queryClient = useQueryClient()

    const { data } = useQuery(['profile'], getSelf)
    const { mutate, isLoading } = useMutation(updateProfile, {
        onSuccess: onMutateSuccess((res) => {
            queryClient.invalidateQueries(['profile'])
            localStorage.setItem('cleaning-crm-token', res.token)
        })
    })

    const handleChange = (e) => {
        const val = e.target.value
        const prop = e.target.name
        setProfile({...profile, [prop]: val})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        mutate(profile)
    }

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setProfile(result)
        })
    }, [data])

    return (
        <Row>
            <Col lg="6">
                <Card>
                    <Card.Body>
                        <h4>Profile</h4>

                        <Form onSubmit={handleSubmit}>
                            <FormInput className="mb-2" label="Full Name" name="fullName" value={profile?.fullName ?? ''} onChange={handleChange}/>
                            <FormInput className="mb-2" label="Username" name="username" value={profile?.username ?? ''} onChange={handleChange}/>
                            <FormInput className="mb-2" label="Email" name="email" value={profile?.email ?? ''} onChange={handleChange}/>
                            {/* <a className="d-block w-100 mb-1 cursor-pointer" href='#_' onClick={() => setEditPasswordModal(true)}><small>Change Password</small></a> */}
                            <div className="d-flex justify-content-end">
                                <Button type='submit' disabled={isLoading}>{isLoading ? 'Saving' : 'Save'}</Button>
                            </div>
                        </Form>
                        
                    </Card.Body>
                </Card>
            </Col>
            <EditPasswordModal display={editPasswordModal} toggle={setEditPasswordModal}/>
        </Row>
    )
}

export default ProfilePage;