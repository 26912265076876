export const jobLabel = {
  clientId: 'Client',
  status: 'Status',
  dispatchTime: 'Dispatch Time',
  pickupType: 'Pickup type',
  pickupTime: 'Pickup Time ',
  completionTime: 'Completion Time',
  pickupAssignedToId: 'Pickup Assignee',
  cleaningAssignedToId: 'Cleaning Assignee',
  deliveryAssignedToId: 'Delivery Assignee',
  paymentMethod: 'Payment Method',
  paymentTime: 'Payment Time',
  paymentType: 'Payment Type',
  price: 'Price'
}

export const lineItemLabel = {
  serviceId: 'Service',
  quantity: 'Quantity',
  price: 'Price',
  taxRate: 'Tax Rate',
  taxAmount: 'Tax Amount',
  priceWithTax: 'Total'
}