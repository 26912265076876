import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import NavigationBar from '../components/navigationBar';
import Sidebar from '../components/sidebar';
import { getCurrentUser } from '../services/auth';
import './dashboard.css';

const DashboardTemplate = () => {
    
    const [sidebarToggle, setSidebarToggle] = useState(false)

    const _currentUser = getCurrentUser()

    return (

        _currentUser ? 
        
        <div className="container-fluid">
            <div className="row">

                <Sidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} currentUser={_currentUser} />

                <main role="main" className="col-md-9 pb-5 ms-sm-auto col-lg-10 px-0 bg-lightgrey">

                 
                    <NavigationBar setSidebarToggle={setSidebarToggle} currentUser={_currentUser} />
                

                    <div className="content pt-3 px-4">

                        <Outlet />

                    </div>
                </main>
            </div>
        </div>
        : 
        <>
        <Navigate to="/login" />
        </>
    )
}

export default DashboardTemplate;

