import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import {
    Chart as ChartJS, Legend, ArcElement, Tooltip
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { onQuerySuccess } from '../utils/queryResponse';
import { toLocalDateString, getInitials } from '../utils/helper';
import OverlayLoader from '../components/overlayLoader';
import { getUpcomingJobs, getUpcomingDeliveries, getFinishedJobs, getTotals } from '../services/overview';
import DriverDeliveriesChart from '../components/charts/driverDeliveriesChart';
import SalesChart from '../components/charts/salesChart';
import SetTempEditOrderModal from '../components/modals/orderEditTemp';
import { isUserAllowed } from '../utils/roleHelpers';

ChartJS.register(ArcElement, Tooltip, Legend);

const HomePage = () => {

    const [editJobModal, setEditJobModal] = useState(false)

    const [selectedJobId, setSelectedJobId] = useState('')

    const [filters, setFilters] = useState([
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6),
        new Date(new Date().setHours(23, 59, 59, 999))
    ])
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6))
    const [endDate, setEndDate] = useState(new Date(new Date().setHours(23, 59, 59, 999)))

    const queryOptions = {
        keepPreviousData: true,
        staleTime: 1000 * 60, // 1 minute
        refetchOnMount: true,
    }

    // #region Upcoming Jobs
    const [startDateUJ, setStartDateUJ] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    const [endDateUJ, setEndDateUJ] = useState(new Date(new Date().setHours(23, 59, 59, 999)))
    const [filtersUJ, setFiltersUJ] = useState([startDateUJ, endDateUJ])
    const [upcomingJobs, setUpcomingJobs] = useState({})
    const upcomingJobsDataQuery = useQuery(['overview-upcomingjobs', filtersUJ], () => getUpcomingJobs(...filtersUJ), queryOptions)
    const { data: upcomingJobsData, isFetching: isFetchingUpcomingJobs } = upcomingJobsDataQuery
    useEffect(() => {
        onQuerySuccess(upcomingJobsData, (result) => {
            setUpcomingJobs(result)
        })
    }, [upcomingJobsData])
    // #endregion

    // #region Upcoming Deliveries
    const [startDateUD, setStartDateUD] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    const [endDateUD, setEndDateUD] = useState(new Date(new Date().setHours(23, 59, 59, 999)))
    const [filtersUD, setFiltersUD] = useState([startDateUD, endDateUD])
    const [upcomingDeliveries, setUpcomingDeliveries] = useState({})
    const upcomingDeliveriesDataQuery = useQuery(['overview-upcomingdeliveries', filtersUD], () => getUpcomingDeliveries(...filtersUD), queryOptions)
    const { data: upcomingDeliveriesData, isFetching: isFetchingUpcomingDeliveries } = upcomingDeliveriesDataQuery
    useEffect(() => {
        onQuerySuccess(upcomingDeliveriesData, (result) => {
            setUpcomingDeliveries(result)
        })
    }, [upcomingDeliveriesData])
    // #endregion

    // #region Finished Jobs
    const [startDateFJ, setStartDateFJ] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    const [endDateFJ, setEndDateFJ] = useState(new Date(new Date().setHours(23, 59, 59, 999)))
    const [filtersFJ, setFiltersFJ] = useState([startDateFJ, endDateFJ])
    const [finishedJobs, setFinishedJobs] = useState({})
    const finishedJobsDataQuery = useQuery(['overview-finishedjobs', filtersFJ], () => getFinishedJobs(...filtersFJ), queryOptions)
    const { data: finishedJobsData, isFetching: isFetchingFinishedJobs } = finishedJobsDataQuery
    useEffect(() => {
        onQuerySuccess(finishedJobsData, (result) => {
            setFinishedJobs(result)
        })
    }, [finishedJobsData])
    // #endregion

    const [totals, setTotals] = useState({})
    const totalsQuery = useQuery(['overview-totals'], () => getTotals(), queryOptions)
    const { data: totalsData, isFetching: isFetchingTotals } = totalsQuery
    useEffect(() => {
        onQuerySuccess(totalsData, (result) => {
            setTotals(result)
        })
    }, [totalsData])

    const navigateTime = (type, sd, ed, setSt, setEd, setF, period) => {

        let _startDate = new Date(sd.getTime());
        let _endDate = new Date(ed.getTime());

        _startDate.setDate(_startDate.getDate() + (type === "prev" ? -period : period));
        _endDate.setDate(_endDate.getDate() + (type === "prev" ? -period : period));

        setSt(_startDate)
        setEd(_endDate)
        setF([
            _startDate,
            _endDate
        ])
    }

    const handleSelectJob = (job) => {
        setSelectedJobId(job.id)
        setEditJobModal(true)
    }

    return (
        <>
            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJobId} />

            <Row className="mt-2">
                <Col lg="6">

                    {isUserAllowed() &&
                        <>
                            <Row className="row-eq-height">
                                <Col lg="7" className="mb-3">
                                    <Card className="h-100">
                                        <Card.Body>
                                            {isFetchingTotals ?
                                                <div style={{ height: '100px' }}>
                                                    <OverlayLoader noTextDisplay />
                                                </div>
                                                :
                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                                        <i className="color-secondary fa-brands fa-paypal fa-xl"></i>
                                                        <p style={{ fontSize: ".8rem" }} className="text-muted mb-0">Payment</p>
                                                    </div>

                                                    <Doughnut data={{
                                                        labels: ['Paid', 'Unpaid'],
                                                        datasets: [{
                                                            data: [totals?.paid, totals?.unpaid],
                                                            backgroundColor: [
                                                                '#66cc99',
                                                                '#f1828d'
                                                            ],
                                                        }]
                                                    }} />
                                                </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="5" className="h-100 mb-3">
                                    <Card className="mb-3">
                                        <Card.Body>
                                            {isFetchingTotals ?
                                                <div style={{ height: '100px' }}>
                                                    <OverlayLoader noTextDisplay />
                                                </div>
                                                :
                                                <div>
                                                    <div>
                                                        <i className="color-secondary fa-solid fa-bars-progress fa-4x mb-3"></i>
                                                    </div>
                                                    <p style={{ fontSize: "1rem" }} className="text-muted mb-0">Total Jobs</p>
                                                    <h3 className="color-primary">{totals?.total}</h3>
                                                </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            {isFetchingTotals ?
                                                <div style={{ height: '100px' }}>
                                                    <OverlayLoader noTextDisplay />
                                                </div>
                                                :
                                                <div>
                                                    <div>
                                                        <i className="color-secondary fa-regular fa-circle-check fa-4x mb-3"></i>
                                                    </div>
                                                    <p style={{ fontSize: "1rem" }} className="text-muted mb-0">Completed Jobs</p>
                                                    <h3 className="color-primary">{totals?.completed}</h3>
                                                </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className="row-eq-height mb-3">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <div>
                                        <div className="mb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                            <i className="color-secondary fa-solid fa-truck fa-xl"></i>
                                            <p style={{ fontSize: ".8rem" }} className="text-muted mb-0">Driver Deliveries</p>
                                            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                                <Button onClick={() => navigateTime("prev", startDate, endDate, setStartDate, setEndDate, setFilters, 6)} variant="light" className="px-2 py-0 mx-1" title="previous 7 days">&lt;</Button>
                                                <Button disabled={endDate.setHours(23, 59, 59, 999) >= new Date().setHours(23, 59, 59, 999)} onClick={() => navigateTime("next", startDate, endDate, setStartDate, setEndDate, setFilters, 6)} variant="light" className="px-2 py-0 mx-1" title="next 7 days">&gt;</Button>
                                            </div>
                                        </div>
                                        <DriverDeliveriesChart filters={filters} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {isUserAllowed() &&
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div>
                                            <div className="mb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                                <i className="color-secondary fa-solid fa-dollar-sign fa-xl"></i>
                                                <p style={{ fontSize: ".8rem" }} className="text-muted mb-0">Sales</p>
                                            </div>
                                            <SalesChart />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    }

                </Col>
                <Col lg="6" className="mb-3">

                    <Card className="mb-3">
                        <Card.Body>
                            {isFetchingUpcomingJobs ?
                                <div style={{ height: '250px' }}>
                                    <OverlayLoader noTextDisplay />
                                </div>
                                :
                                <div>
                                    <div className="mb-3 color-secondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                            <i className="fa-regular fa-calendar fa-xl"></i>
                                            <p className="mb-0" style={{ fontSize: '.8rem', fontWeight: '600' }}>Upcoming Jobs</p>
                                        </div>
                                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                            <p className="mb-0" style={{ fontSize: '.7rem' }}>{toLocalDateString(endDateUJ, 'dd.MM.yyyy')}</p>
                                            <Button onClick={() => navigateTime("prev", startDateUJ, endDateUJ, setStartDateUJ, setEndDateUJ, setFiltersUJ, 1)} variant="light" className="px-2 py-0 mx-1" title="Backward 1 Day">&lt;</Button>
                                            <Button onClick={() => navigateTime("next", startDateUJ, endDateUJ, setStartDateUJ, setEndDateUJ, setFiltersUJ, 1)} variant="light" className="px-2 py-0 mx-1" title="Forward 1 Day">&gt;</Button>
                                        </div>
                                    </div>
                                    <Table className="mb-4 overview" style={{ borderTop: '2px solid var(--color-secondary)' }}>
                                        <thead>
                                            <tr className="text-muted">
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Pickup</th>
                                                <th>ETA</th>
                                                <th>Driver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {upcomingJobs && upcomingJobs.length > 0 ?
                                                <>
                                                    {upcomingJobs.map((x, index) => (
                                                        <tr className="cursor-pointer" key={`bill-${index}`} onClick={() => handleSelectJob(x)}>
                                                            <td>{x.name}</td>
                                                            <td>{x.address}</td>
                                                            <td>{x.pickupTime === "To Be Delivered" ? x.pickupTime : toLocalDateString(new Date(x.pickupTime))}</td>
                                                            <td>{x.eta === "Unspecified" ? x.eta : toLocalDateString(new Date(x.eta))}</td>
                                                            {/* <td>{getInitials(x.assigned)}</td> */}
                                                            {
                                                                x.assigned ?
                                                                    <td title={x.assigned} className="py-1" style={{ cursor: 'default' }}><span className="userphoto-placeholder me-2 d-flex align-items-center justify-content-center"><span>{getInitials(x.assigned)}</span></span></td>
                                                                    : <td></td>
                                                            }
                                                        </tr>
                                                    ))}
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={5}><i>No upcoming jobs</i></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Body>
                            {isFetchingUpcomingDeliveries ?
                                <div style={{ height: '250px' }}>
                                    <OverlayLoader noTextDisplay />
                                </div>
                                :
                                <div>
                                    <div className="mb-3 color-secondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                            <i className="fa-regular fa-paper-plane fa-xl"></i>
                                            <p className="mb-0" style={{ fontSize: '.8rem', fontWeight: '600' }}>Upcoming Deliveries</p>
                                        </div>
                                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                            <p className="mb-0" style={{ fontSize: '.7rem' }}>{toLocalDateString(endDateUD, 'dd.MM.yyyy')}</p>
                                            <Button onClick={() => navigateTime("prev", startDateUD, endDateUD, setStartDateUD, setEndDateUD, setFiltersUD, 1)} variant="light" className="px-2 py-0 mx-1" title="Backward 1 Day">&lt;</Button>
                                            <Button onClick={() => navigateTime("next", startDateUD, endDateUD, setStartDateUD, setEndDateUD, setFiltersUD, 1)} variant="light" className="px-2 py-0 mx-1" title="Forward 1 Day">&gt;</Button>
                                        </div>
                                    </div>
                                    <Table className="mb-4 overview" style={{ borderTop: '2px solid var(--color-orange)' }}>
                                        <thead>
                                            <tr className="text-muted">
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Delivery</th>
                                                <th>Driver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {upcomingDeliveries && upcomingDeliveries.length > 0 ?
                                                <>
                                                    {upcomingDeliveries.map((x, index) => (
                                                        <tr className="cursor-pointer" key={`bill-${index}`} onClick={() => handleSelectJob(x)}>
                                                            <td>{x.name}</td>
                                                            <td>{x.address}</td>
                                                            <td>{toLocalDateString(new Date(x.deliveryTime))}</td>
                                                            {
                                                                x.assigned ?
                                                                    <td title={x.assigned} className="py-1" style={{ cursor: 'default' }}><span className="userphoto-placeholder me-2 d-flex align-items-center justify-content-center"><span>{getInitials(x.assigned)}</span></span></td>
                                                                    : <td></td>
                                                            }
                                                        </tr>
                                                    ))}
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={4}><i>No upcoming deliveries</i></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            {isFetchingFinishedJobs ?
                                <div style={{ height: '250px' }}>
                                    <OverlayLoader noTextDisplay />
                                </div>
                                :
                                <div>
                                    <div className="mb-3 color-secondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                            <i className="fa-regular fa-circle-check fa-xl"></i>
                                            <p className="mb-0" style={{ fontSize: '.8rem', fontWeight: '600' }}>Finished Jobs</p>
                                        </div>
                                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                            <p className="mb-0" style={{ fontSize: '.7rem' }}>{toLocalDateString(endDateFJ, 'dd.MM.yyyy')}</p>
                                            <Button onClick={() => navigateTime("prev", startDateFJ, endDateFJ, setStartDateFJ, setEndDateFJ, setFiltersFJ, 1)} variant="light" className="px-2 py-0 mx-1" title="Backward 1 Day">&lt;</Button>
                                            <Button onClick={() => navigateTime("next", startDateFJ, endDateFJ, setStartDateFJ, setEndDateFJ, setFiltersFJ, 1)} variant="light" className="px-2 py-0 mx-1" title="Forward 1 Day">&gt;</Button>
                                        </div>
                                    </div>
                                    <Table className="mb-4 overview" style={{ borderTop: '2px solid var(--color-success)' }}>
                                        <thead>
                                            <tr className="text-muted">
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Completed</th>
                                                <th>Price</th>
                                                <th>Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {finishedJobs && finishedJobs.length > 0 ?
                                                <>
                                                    {finishedJobs.map((x, index) => (
                                                        <tr className="cursor-pointer" key={`bill-${index}`} onClick={() => handleSelectJob(x)}>
                                                            <td>{x.name}</td>
                                                            <td>{x.address}</td>
                                                            <td>{toLocalDateString(x.completedTime)}</td>
                                                            <td>{x.totalPrice}</td>
                                                            <td>{x.payment}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={5}><i>No finished jobs</i></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </>
    )
};

export default HomePage;