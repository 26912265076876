import { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Form, FormSelect, Row } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import DatePicker from '../../../components/forms/formDatePicker';
import AssigneeSelect from '../../../components/selects/assigneeSelect';
import BadgeSelect from '../../../components/selects/badgeSelect';
import ServiceCategorySelect from '../../../components/selects/serviceCategorySelect';
import ServiceSelect from '../../../components/selects/serviceSelect';
import { getBadges } from '../../../services/badges';
import { getUsersAsOptions } from '../../../services/companyUsers';
import { onQuerySuccess } from '../../../utils/queryResponse';
import ClientsSelect from '../../../components/selects/clientsSelect';
import { useSearchParams } from 'react-router-dom';
import FormInput from '../../../components/forms/formInput';

const JobReportsFilter = ({ isFiltering, onFilter }) => {

    const [searchParams] = useSearchParams();

    const [isShow, setIsShow] = useState(searchParams.size ? true : false)

    const queryClient = useQueryClient()
    const [assigneesOptions, setAssigneesOptions] = useState([])
    const [badgesOptions, setBadgesOptions] = useState([])
    const [clientOptions, setClientOptions] = useState([])

    // Filters
    const [status, setStatus] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')
    // const [pickupTimeStartDate, setPickupTimeStartDate] = useState('')
    // const [pickupTimeEndDate, setPickupTimeEndDate] = useState('')
    // const [completionTimeStartDate, setCompletionTimeStartDate] = useState('')
    // const [completionTimeEndDate, setCompletionTimeEndDate] = useState('')
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [pickupAssignedToId, setPickupAssignedToId] = useState(searchParams.get("pickupAssignedToId"))
    const [cleaningAssignedToId, setCleaningAssignedToId] = useState(searchParams.get("cleaningAssignedToId"))
    const [deliveryAssignedToId, setDeliveryAssignedToId] = useState(searchParams.get("deliveryAssignedToId"))
    const [serviceId, setServiceId] = useState(searchParams.get("serviceId"))
    const [serviceCategoryId, setServiceCategoryId] = useState('')
    const [badgeId, setBadgeId] = useState('')
    const [clientId, setClientId] = useState('')
    const [jobNoFrom, setJobNoFrom] = useState('')
    const [jobNoTo, setJobNoTo] = useState('')

    const { data: dbAssigneesOptions, isLoading: isLoadingAssigneesOptions } = useQuery(['users-option'], () => getUsersAsOptions())

    useEffect(() => {
        onQuerySuccess(dbAssigneesOptions, (result) => {
            if (result.length > 0) {
                setAssigneesOptions(result.map(d => ({
                    value: d.id, label: d.fullName
                })))
            }
        })
    }, [dbAssigneesOptions, isShow])

    const { data, isBadgesLoading } = useQuery(['badges'], () => getBadges())

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            if (result.length > 0) {
                setBadgesOptions(result.map(d => ({
                    value: d.id, label: d.badgeNo
                })))
            }
        })
    }, [data])

    const handleFilter = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onFilter([
            status,
            paymentStatus,
            // pickupTimeStartDate,
            // pickupTimeEndDate ? new Date(pickupTimeEndDate.setHours(23, 59, 59, 999)) : '',
            // completionTimeStartDate,
            // completionTimeEndDate ? new Date(completionTimeEndDate.setHours(23, 59, 59, 999)) : '',
            dateFrom,
            dateTo ? new Date(dateTo.setHours(23, 59, 59, 999)) : '',
            pickupAssignedToId,
            cleaningAssignedToId,
            deliveryAssignedToId,
            serviceId,
            serviceCategoryId,
            badgeId,
            clientId,
            jobNoFrom,
            jobNoTo
        ])
    }

    const handleReset = () => {
        onFilter(['', '', '', '', '', '', '', '', '', '', '', '', '', ''])
        setStatus('')
        setPaymentStatus('')
        // setPickupTimeStartDate('')
        // setPickupTimeEndDate('')
        // setCompletionTimeStartDate('')
        // setCompletionTimeEndDate('')
        setDateFrom('')
        setDateTo('')
        setPickupAssignedToId('')
        setCleaningAssignedToId('')
        setDeliveryAssignedToId('')
        setServiceId('')
        setServiceCategoryId('')
        setBadgeId('')
        setClientId('')
        setJobNoFrom('')
        setJobNoTo('')
        queryClient.invalidateQueries(['job-reports'])
    }

    const handleStatusChange = (val) =>{
        setStatus(val)
    }

    return (
        <>
            <span className='cursor-pointer' onClick={() => setIsShow(!isShow)}><i className="fa-solid fa-list-check"></i> {isShow ? 'Hide' : 'Show'} Filter</span>


            <Collapse in={isShow}>
                <Form onSubmit={handleFilter}>
                    <Card className='mt-2'>
                        <Card.Body>
                            <Row>
                                <Col lg="3" className='mb-2'>
                                    <Form.Label>Service</Form.Label>
                                    <ServiceSelect value={serviceId} onChange={(option) => setServiceId(option.value)} />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <ServiceCategorySelect label="Service Category" placeholder="Filter Service Category" value={serviceCategoryId} onChange={(option) => setServiceCategoryId(option.value)} />
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Client</Form.Label>
                                        <ClientsSelect value={clientId} onChange={(option) => setClientId(option.value)} options={clientOptions} setOptions={setClientOptions} />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Badge Number</Form.Label>
                                        <BadgeSelect value={badgeId}
                                            isLoading={isBadgesLoading}
                                            options={badgesOptions.sort(function (a, b) { return a.label - b.label })}
                                            onChange={(option) => setBadgeId(option.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Picked-up By'
                                            value={pickupAssignedToId}
                                            assigneesOptions={assigneesOptions}
                                            onChange={(option) => setPickupAssignedToId(option.value)}
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Cleaned By'
                                            value={cleaningAssignedToId}
                                            assigneesOptions={assigneesOptions}
                                            onChange={(option) => setCleaningAssignedToId(option.value)}
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <AssigneeSelect
                                            label='Delivered By'
                                            value={deliveryAssignedToId}
                                            assigneesOptions={assigneesOptions}
                                            onChange={(option) => setDeliveryAssignedToId(option.value)}
                                            isLoading={isLoadingAssigneesOptions}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Payment Status</Form.Label>
                                        <FormSelect value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)} >
                                            <option value="" disabled>Select Status</option>
                                            <option value="PENDING">Pending</option>
                                            <option value="PAID">Paid</option>
                                        </FormSelect>
                                    </Form.Group>
                                </Col>
                                {/* <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Pickup Start Date'
                                        selected={pickupTimeStartDate} onChange={setPickupTimeStartDate} maxDate={pickupTimeEndDate} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Pickup End Date'
                                        selected={pickupTimeEndDate} onChange={setPickupTimeEndDate} minDate={pickupTimeStartDate} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Completion Start Date'
                                        selected={completionTimeStartDate} onChange={setCompletionTimeStartDate} maxDate={completionTimeEndDate} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Completion End Date'
                                        selected={completionTimeEndDate} onChange={setCompletionTimeEndDate} minDate={completionTimeStartDate} dateFormat="dd.MM.yyyy"
                                    />
                                </Col> */}
                                <Col lg="2" className='mb-2'>
                                    <FormInput label="From" placeholder="Job Number" value={jobNoFrom} onChange={(e) => setJobNoFrom(e.target.value)} />
                                </Col>
                                <Col lg="2" className='mb-2'>
                                    <FormInput label="To" placeholder="Job Number" value={jobNoTo} onChange={(e) => setJobNoTo(e.target.value)} />
                                </Col>
                                <Col lg="2">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Status</Form.Label>
                                        <FormSelect value={status} onChange={(e) => handleStatusChange(e.target.value)} >
                                            <option value="" disabled>Select Status</option>
                                            <option value="SCHEDULED">Scheduled</option>
                                            <option value="ASSIGNED">Assigned</option>
                                            <option value="PICKUP_IN_PROGRESS">Pickup in Progress</option>
                                            <option value="PICKUP_COMPLETED">Pickup Completed</option>
                                            <option value="CLEANING">Cleaning</option>
                                            <option value="CLEANING_COMPLETED">Cleaning Completed</option>
                                            <option value="DISPATCH_IN_PROGRESS">Dispatch in Progress</option>
                                            <option value="COMPLETED">Completed</option>
                                        </FormSelect>
                                    </Form.Group>
                                </Col>
                                {
                                    status &&
                                    <>
                                        <Col lg="3" className='mb-2'>
                                            <DatePicker className='form-control' label='Date From'
                                                selected={dateFrom} onChange={setDateFrom} maxDate={dateTo} dateFormat="dd.MM.yyyy"
                                            />
                                        </Col>
                                        <Col lg="3" className='mb-2'>
                                            <DatePicker className='form-control' label='Date To'
                                                selected={dateTo} onChange={setDateTo} minDate={dateFrom} dateFormat="dd.MM.yyyy"
                                            />
                                        </Col>
                                    </>
                                }
                            </Row>
                            <div className='mt-3 d-flex justify-content-end'>
                                <Button variant="outline-secondary" className='me-2' onClick={handleReset}>Reset</Button>
                                <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Filtering...' : "Filter"}</Button>
                            </div>
                        </Card.Body>
                    </Card>

                </Form>
            </Collapse>
        </>

    )
}

export default JobReportsFilter