import http from './http'
import { getToken } from './auth'

const base = 'Employees'

export async function getEmployees() {

    const { data } = await http.get(`${base}/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getEmployeeById(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createEmployee(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }

    const { data } = await http.post(base, payload, config)
    return data
}

export async function deleteEmployee(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}

export async function updateEmployee(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}
