import { useState } from 'react';
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import DatePicker from '../../../components/forms/formDatePicker';

const JobsPerformanceFilter = ({ isFiltering, onFilter }) => {

    const [isShow, setIsShow] = useState(false)
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const queryClient = useQueryClient()

    const handleFilter = (e) => {
        e.preventDefault()
        e.stopPropagation()

        let _filters = []
        if(dateFrom)
            _filters.push(new Date(dateFrom.setHours(0, 0, 0, 0)) ?? '')
        if(dateTo)
            _filters.push(new Date(dateTo.setHours(23, 59, 59, 999)) ?? '')

        onFilter(_filters)
    }

    const handleReset = () => {
        onFilter(['', ''])
        setDateFrom('')
        setDateTo('')
        queryClient.invalidateQueries(['jobs-performance'])
    }

    return (
        <>
            <span className='cursor-pointer' onClick={() => setIsShow(!isShow)}><i className="fa-solid fa-list-check"></i> {isShow ? 'Hide' : 'Show'} Filter</span>
            <Collapse in={isShow}>
                <Form onSubmit={handleFilter}>
                    <Card className='mt-2'>
                        <Card.Body>
                            <Row>
                                <Col lg="4" className='mb-2'>
                                    <DatePicker className='form-control' label='Date From' placeholderText='Filter Date From'
                                        selected={dateFrom} onChange={setDateFrom} maxDate={dateTo || undefined} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="4" className='mb-2'>
                                    <DatePicker className='form-control' label='Date To' placeholderText='Filter Date To'
                                        selected={dateTo} onChange={setDateTo} minDate={dateFrom || undefined} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                            </Row>
                            <div className='mt-3 d-flex justify-content-end'>
                                <Button variant="outline-secondary" className='me-2' onClick={handleReset}>Reset</Button>
                                <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Filtering...' : "Filter"}</Button>
                            </div>
                        </Card.Body>
                    </Card>

                </Form>
            </Collapse>
        </>

    )
}

export default JobsPerformanceFilter