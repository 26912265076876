import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useMutation, useQuery } from 'react-query';
import ReactToPrint from "react-to-print";
import SortableTable from "reactstrap-sortable-table";
import FormInput from '../../components/forms/formInput';
import OverlayLoader from '../../components/overlayLoader';
import Pages from '../../components/pages';
import { getPaginatedSales, getSaleInvoice, getSalesSummary } from '../../services/sales';
import { debounce, initColumns, rsdFormat, toLocalDateString } from '../../utils/helper';
import { useSheetExport } from "../../utils/queryHooks/sheetExport";
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import SalesFilter from './salesFilter';
import { useReactToPrint } from 'react-to-print';
import SalesInvoicePrint from '../../components/print/salesInvoice';
import { emailInvoice } from '../../services/email';
import { toast } from 'react-toastify';

const SalesPage = () => {

    const [sales, setSales] = useState([])

    const [pageSize, setPageSize] = useState(10)
    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [jobIdToEmail, setJobIdToEmail] = useState()
    const [invoice, setInvoice] = useState()
    const [isPrinting, setIsPrinting] = useState(false)

    const [filters, setFilters] = useState(['', '', '', ''])
    const [searchText, setSearchText] = useState('')
    const [summaryData, setSummaryData] = useState()
    const invoiceRef = useRef()
    const printRef = useRef()
    const printResolveRef = useRef()

    const { exportSheet: exportExcel, isExporting } = useSheetExport("Sales",
        sales,
        ["Job #", "Date", "Service", "Client", "Tax", "Amount"])
    const { exportSheet: exportCSV, isExportingCSV } = useSheetExport("Sales",
        sales,
        ["Job #", "Date", "Service", "Client", "Tax", "Amount"], "csv")

    const { data, isFetching } = useQuery(
        ['sales', 'paginated', pageNumber, pageSize, ...filters, searchText],
        () => getPaginatedSales(pageNumber, pageSize, ...filters, searchText)
    )

    const { data: dbSummary, isFetching: isFetchingSummary } = useQuery(['sales', 'summary'], getSalesSummary)
    const { mutate: email, isLoading: isEmailing } = useMutation(emailInvoice, {
        onSuccess: onMutateSuccess((message) => {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        })
    })

    const setSalesModified = (data) => {
        setSales(data.map(x => {
            x.tax = parseFloat(x.tax)
            x.amount = parseFloat(x.amount)
            return x
        }))
    }

    useEffect(() => {
        onQuerySuccess(data, (result) => {

            setPagination(result)
            setSalesModified(result.data)
        })
    }, [data])

    useEffect(() => {
        onQuerySuccess(dbSummary, (result) => {
            setSummaryData(result)
        })
    }, [dbSummary])

    useEffect(() => {
        if (isPrinting && printResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            printResolveRef.current();
        }
    }, [isPrinting]);

    const handleSetPageSize = (size) => {
        setPageNumber(1)
        setPageSize(size)
    }

    const handleSearchText = debounce((e) => {
        setSearchText(e.target.value)
    })

    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        onBeforeGetContent: () => {

            return new Promise((resolve) => {
                printResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            printResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    const handlePrint = async (sale) => {

        let _sales = sales
        let printIndex = sales.findIndex(x => x.jobNo === sale.jobNo)
        _sales[printIndex].isFetchingInvoice = true
        setSales([..._sales])
        let { message, code } = await getSaleInvoice(sale.jobNo)
        _sales[printIndex].isFetchingInvoice = false
        setSales([..._sales])

        if (code === 200) {
            setInvoice(message)
            printInvoice()
        } else {
            toast.error('Error in getting sale invoice', {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    const handleEmail = (sale) => {
        setJobIdToEmail(sale.jobNo)
        email({ email: sale.email, jobId: sale.jobNo })
    }

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col lg="4" className="mb-3">
                            <Card>
                                <Card.Body>
                                    <p className="mb-0 text-uppercase">THIS WEEK</p>
                                    <h4 className="mb-0 text-muted">{rsdFormat(summaryData?.thisWeek)}</h4>
                                    {isFetchingSummary &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4" className="mb-3">
                            <Card>
                                <Card.Body>
                                    <p className="mb-0 text-uppercase">{new Date().toLocaleString('default', { month: 'long', locale: 'en-US' })} {new Date().getFullYear()}</p>
                                    <h4 className="mb-0 text-muted">{rsdFormat(summaryData?.thisMonth)}</h4>
                                    {isFetchingSummary &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4" className="mb-3">
                            <Card>
                                <Card.Body>
                                    <p className="mb-0 text-uppercase">YEAR {new Date().getFullYear()}</p>
                                    <h4 className="mb-0 text-muted">{rsdFormat(summaryData?.thisYear)}</h4>
                                    {isFetchingSummary &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <SalesFilter onFilter={setFilters} />

                    <Card className="mt-3 mb-4">
                        <Card.Body>
                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Sales</h4>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col lg="6">
                                    <div className="d-flex">
                                        <div className="w-50">
                                            <FormInput type="search" placeholder="Search" size="sm" onChange={handleSearchText} />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <div className="text-right d-flex justify-content-lg-end me-2">
                                            <Form.Control as="select" className="w-auto d-inline mr-2" size="sm" onChange={(e) => handleSetPageSize(e.target.value)} defaultValue="">
                                                <option value="" disabled>Select Page Size</option>
                                                <option value="10">10 per page</option>
                                                <option value="20">20 per page</option>
                                                <option value="50">50 per page</option>
                                                <option value="100">100 per page</option>
                                                <option value="250">250 per page</option>
                                                <option value="500">500 per page</option>
                                            </Form.Control>
                                        </div>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic" disabled={isFetching}>
                                                    Export
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button">
                                                        <ReactToPrint
                                                            trigger={() => <div><a >Print</a></div>}
                                                            content={() => printRef.current}
                                                        />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" disabled={isExportingCSV} onClick={() => exportCSV()}>
                                                        {isExportingCSV ? 'Exporting...' : 'CSV'}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" disabled={isExporting} onClick={() => exportExcel()}>
                                                        {isExporting ? 'Exporting...' : 'Excel'}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {sales.length > 0 ?
                                            <>
                                                <div className="print-wrapper" ref={printRef}>
                                                    <SortableTable
                                                        style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                        responsive
                                                        size="sm"
                                                        data={sales}
                                                        setData={setSales}
                                                        columns={initColumns(sales, ['id', 'email', 'isFetchingInvoice'])}
                                                        columnRender={[
                                                            { column: 'jobNo', render: (value) => `#${value}` },
                                                            { column: 'date', render: (value) => toLocalDateString(value) },
                                                            { column: 'tax', render: (value) => rsdFormat(value) },
                                                            { column: 'amount', render: (value) => rsdFormat(value) }
                                                        ]}
                                                        firstColumnRender={(sale) =>
                                                            <div className='py-1'>
                                                                <div className="d-inline" style={{ position: 'relative' }}>
                                                                    {sale.isFetchingInvoice &&
                                                                        <OverlayLoader noTextDisplay size="25" />
                                                                    }
                                                                    <i className="fa-solid fa-receipt cursor-pointer mx-1" title="print invoice" onClick={() => handlePrint(sale)}></i>
                                                                </div>
                                                                {sale.email &&
                                                                    <div className="d-inline" style={{ position: 'relative' }}>
                                                                        {isEmailing && jobIdToEmail === sale.jobNo &&
                                                                            <OverlayLoader noTextDisplay size="25" />
                                                                        }
                                                                        <i className="fa-solid fa-envelope cursor-pointer mx-1" title="email invoice" onClick={() => handleEmail(sale)}></i>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        withTotalColumns={['tax', 'amount']}
                                                        totalRender={(value) => <strong>{rsdFormat(value)}</strong>}
                                                        addProps={{
                                                            tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                            tData: { className: 'py-2' }
                                                        }}
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no sales found</span>
                                            </>
                                        }

                                        {pagination?.totalPages > 0 &&
                                            <Pages paginationData={pagination} changePage={setPageNumber} pageNumber={pageNumber} />
                                        }

                                        {(isFetching) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div ref={invoiceRef} className="hide-on-screen">
                <SalesInvoicePrint invoice={invoice} />
            </div>

        </>
    );
};

export default SalesPage;