import { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import SortableTable from "reactstrap-sortable-table";
import ShowHideColumnsModal from "../../../components/modals/showHideColumns";
import OverlayLoader from "../../../components/overlayLoader";
import Pages from "../../../components/pages";
import { getJobsOverallTotal, getPaginatedJobReports } from "../../../services/jobs";
import { getInitials, hasValue, rsdFormat, toLocalDateString } from "../../../utils/helper";
import { onQuerySuccess } from "../../../utils/queryResponse";
import { msToHHMM } from "../../../utils/timeHelpers";
import JobReportsFilter from "./jobReportsFilter";
import SetTempEditOrderModal from "../../../components/modals/orderEditTemp";
import { useSearchParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

// const columns = ['id', 'actualDuration', 'cleaningCompletedTime', 'completionOnTime', 'completionTime', 'completionTimeActual', 'pickupTime', 'deliveredByCustomerTime', 'dispatchTime',
//     'expectedDuration', 'note', 'paymentMethod', 'paymentStatus', 'paymentTime', 'paymentType', 'price',
//     { value: 'client', label: 'Client Name' },
//     'status', 'assignees',
//     { value: 'lineItems', label: 'Badges' },
//     'onTime'
// ];

const columns = ['id', { value: 'client', label: 'Client Name' }, 'jobStart', 'status', { value: 'lineItems', label: 'Badges' }, 'assignees', 'paymentStatus', 'price', 'onTime',
    'actualDuration', 'cleaningCompletedTime', 'completionOnTime', 'completionTime', 'completionTimeActual', 'pickupTime', 'deliveredByCustomerTime', 'pickupInProgressTime',
    'pickupCompletedTime', 'cleaningTime', 'dispatchTime', 'dispatchInProgressTime',
    'expectedDuration', 'note', 'paymentMethod', 'paymentTime', 'paymentType', 'pickupType'];

const JobReportsPage = () => {

    const [searchParams] = useSearchParams();

    const [showHideColumnsModal, setShowHideColumnsModal] = useState(false)
    const [editJobModal, setEditJobModal] = useState(false)

    const [reports, setReports] = useState([])
    const [selectedJobId, setSelectedJobId] = useState('')
    const [overallTotal, setOverallTotal] = useState()

    const [shownColumns, setShownColumns] = useState([])
    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [filters, setFilters] = useState([
        '', '', '', '', '', '',
        searchParams.get("pickupAssignedToId"),
        searchParams.get("cleaningAssignedToId"),
        searchParams.get("deliveryAssignedToId"),
        searchParams.get("serviceId"),
        '', '', '', '', ''
    ])

    const printRef = useRef()

    const { data, isFetching } = useQuery(
        ['job-reports', pageNumber, pageSize, ...filters],
        () => getPaginatedJobReports(pageNumber, pageSize, ...filters)
    )

    const { data: jobsOverallTotalData } = useQuery(['jobs-overall-total'], () => getJobsOverallTotal())

    const storageName = 'crm-jobreports-cols'

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setPagination(result)
            setReports(result.data.map(x => {
                x.price = parseFloat(x.price)
                const { price, ...rest } = x;
                return { price, ...rest };
            }))
        })

        setShownColumns(
            JSON.parse(localStorage.getItem(storageName)) ||
            [...columns.slice(0, 9)]
        )

        printRef?.current?.scrollIntoView()
    }, [data])

    useEffect(() => {
        onQuerySuccess(jobsOverallTotalData, (result) => {
            setOverallTotal(result)
        })
    }, [jobsOverallTotalData])

    const handleSetPageSize = (size) => {
        setPageNumber(1)
        setPageSize(size)
    }

    const getBadgeBG = (value) => {

        if (value === "DISPATCH_INP_PROGRESS" || value === "CLEANING")
            return "warning"
        if (value === "COMPLETED")
            return "success"
        if (value === 'PICKUP_IN_PROGRESS' || value === "PICKUP_COMPLETED")
            return "info"

        return "grey"
    }

    const handleEditJob = (job) => {
        setSelectedJobId(job.id)
        setEditJobModal(true)
    }

    const getJobStart = (data) => {
        if (data.pickupType === "PICKUP_FROM_HOME")
            return toLocalDateString(data.pickupTime)
        else
            return toLocalDateString(data.deliveredByCustomerTime)
    }

    return (
        <>
            {columns && shownColumns &&
                <ShowHideColumnsModal display={showHideColumnsModal} toggle={setShowHideColumnsModal}
                    columns={columns} shownColumns={shownColumns} setShownColumns={setShownColumns} storageName={storageName}
                />
            }

            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJobId} />

            <Row>
                <Col>
                    <JobReportsFilter onFilter={setFilters} />

                    <Card className="mt-3 mb-4">
                        <Card.Body>

                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="page-title mb-0">Jobs History</h4>
                                        {pagination && overallTotal && 
                                        <div>
                                            <span>Overall Total: <strong>{rsdFormat(overallTotal)}</strong></span>
                                        </div>
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col lg="6">
                                    <Button size="sm" variant="outline-secondary" className="mr-2" onClick={() => setShowHideColumnsModal(true)}>Show/Hide Columns</Button>
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <div className="text-right d-flex justify-content-lg-end me-2">
                                            <Form.Control as="select" className="w-auto d-inline mr-2" size="sm" onChange={(e) => handleSetPageSize(e.target.value)} defaultValue="">
                                                <option value="" disabled>Select Page Size</option>
                                                <option value="10">10 per page</option>
                                                <option value="20">20 per page</option>
                                                <option value="50">50 per page</option>
                                                <option value="100">100 per page</option>
                                                <option value="250">250 per page</option>
                                                <option value="500">500 per page</option>
                                            </Form.Control>
                                        </div>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic" disabled={isFetching}>
                                                    Export
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button">
                                                        <ReactToPrint
                                                            trigger={() => <div><a >Print</a></div>}
                                                            content={() => printRef.current}
                                                        />
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item as="button" disabled={isExportingCSV} onClick={() => exportCSV()}>
                                                        {isExportingCSV ? 'Exporting...' : 'CSV'}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" disabled={isExporting} onClick={() => exportExcel()}>
                                                        {isExporting ? 'Exporting...' : 'Excel'}
                                                    </Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        {reports.length > 0 ?
                                            <>
                                                <div className="print-wrapper mb-3" ref={printRef}>
                                                    <SortableTable
                                                        style={{ borderTop: '2px solid var(--color-secondary)' }}
                                                        responsive
                                                        size="sm"
                                                        data={reports}
                                                        setData={setReports}
                                                        columns={shownColumns}
                                                        columnRender={[
                                                            { column: 'client', render: (value) => `${value.firstName} ${value.lastName}` },
                                                            {
                                                                column: 'status', render: (value) => (
                                                                    <h6 className="d-inline">
                                                                        <Badge bg={getBadgeBG(value)} className="ps-2 pe-1 me-1 cursor-default">
                                                                            <span className="me-1">{value.replace(/_/g, ' ')}</span>
                                                                        </Badge>
                                                                    </h6>
                                                                )
                                                            },
                                                            {
                                                                column: 'assignees', render: (value, column, columnIndex, data, dataIndex) => (
                                                                    <div className="d-flex">
                                                                        {data['pickupAssignedTo'] && <span title={`Pickup Assigned To: ${data['pickupAssignedTo']?.fullName}`} className="userphoto-placeholder me-2 d-flex align-items-center justify-content-center"><span className="cursor-default">{getInitials(data['pickupAssignedTo']?.fullName)}</span></span>}
                                                                        {data['cleaningAssignedTo'] && <span title={`Cleaning Assigned To: ${data['cleaningAssignedTo']?.fullName}`} className="userphoto-placeholder me-2 d-flex align-items-center justify-content-center"><span className="cursor-default">{getInitials(data['cleaningAssignedTo']?.fullName)}</span></span>}
                                                                        {data['deliveryAssignedTo'] && <span title={`Delivery Assigned To: ${data['deliveryAssignedTo']?.fullName}`} className="userphoto-placeholder me-2 d-flex align-items-center justify-content-center"><span className="cursor-default">{getInitials(data['deliveryAssignedTo']?.fullName)}</span></span>}
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                column: 'jobStart', render: (value, column, columnIndex, data, dataIndex) => (
                                                                    <span>
                                                                        {getJobStart(data)}
                                                                    </span>
                                                                )
                                                            },
                                                            {
                                                                column: 'lineItems', render: (value) =>
                                                                    value.map((lineItem) => (
                                                                        <h6 className="d-inline" key={`badge-${lineItem?.badgeId}`}>
                                                                            <Badge className="ps-2 pe-1 me-1 mb-1" variant="primary">
                                                                                <span className="cursor-pointer me-1">#{lineItem?.badge?.badgeNo}</span>
                                                                            </Badge>
                                                                        </h6>
                                                                    ))

                                                            },
                                                            {
                                                                column: 'onTime', render: (value, column, columnIndex, data, dataIndex) => (
                                                                    <div className="d-flex">
                                                                        {data['status'] === 'COMPLETED' && hasValue(data['completionOnTime']) &&
                                                                            <span title={`${data['completionOnTime'] ? 'On Time' : 'Late'}`}
                                                                                className={`${data['completionOnTime'] ? 'bg-success' : 'bg-grey'} icon-round-placeholder me-2 d-flex align-items-center justify-content-center`}>
                                                                                <i className="fa-solid fa-check"></i>
                                                                            </span>}
                                                                    </div>
                                                                )
                                                            },
                                                            { column: 'actualDuration', render: (value, column, columnIndex, data, dataIndex) => data['status'] === 'COMPLETED' && hasValue(value) && value > 0 ? msToHHMM(value) : '' },
                                                            { column: 'expectedDuration', render: (value) => hasValue(value) && value > 0 ? msToHHMM(value) : '' },
                                                            { column: 'pickupTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'completionTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'completionTimeActual', render: (value) => toLocalDateString(value) },
                                                            { column: 'dispatchTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'deliveredByCustomerTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'pickupInProgressTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'pickupCompletedTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'cleaningTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'cleaningCompletedTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'dispatchInProgressTime', render: (value) => toLocalDateString(value) },
                                                            { column: 'price', render: (value) => rsdFormat(value) },
                                                        ]}
                                                        withTotalColumns={['price']}
                                                        totalRender={(value) => <strong>{rsdFormat(value)}</strong>}
                                                        firstColumnRender={(job) =>
                                                            <div className="mt-1">
                                                                <i className="fa-solid fa-eye fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleEditJob(job)}></i>
                                                            </div>}
                                                        addProps={{
                                                            tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } },
                                                            tData: { className: 'py-2' }
                                                        }}
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                <hr />
                                                <span className="fst-italic">no reports found</span>
                                            </>
                                        }

                                        {pagination?.totalPages > 0 &&
                                            <Pages paginationData={pagination} changePage={setPageNumber} pageNumber={pageNumber} />
                                        }

                                        {(isFetching) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default JobReportsPage;