import http from "./http"
import { getToken } from './auth'

const base = 'email'

export async function emailInvoice(payload) {
  const config = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getToken(),
      }
  }

  const { data } = await http.post(base + '/invoice', payload, config)
  return data
}