import { useState } from 'react';
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import DatePicker from '../../components/forms/formDatePicker';
import EmployeeSelect from '../../components/selects/employeeSelect';
import FormInput from '../../components/forms/formInput';

const PayrollsFilter = ({ isFiltering, onFilter }) => {

    const [isShow, setIsShow] = useState(false)
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [employeeId, setEmployeeId] = useState('')
    const [rateType, setRateType] = useState('')
    const queryClient = useQueryClient()

    const handleFilter = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onFilter([
            dateFrom ? new Date(dateFrom.setHours(0, 0, 0, 0)) : '',
            dateTo ? new Date(dateTo.setHours(23, 59, 59, 999)) : '',
            employeeId ?? '',
            rateType ?? ''
        ])
    }

    const handleReset = () => {
        onFilter(['', '', ''])
        setDateFrom('')
        setDateTo('')
        setEmployeeId('')
        setRateType('')
        queryClient.invalidateQueries(['payrolls', 'paginated'])
    }

    return (
        <>
            <span className='cursor-pointer' onClick={() => setIsShow(!isShow)}><i className="fa-solid fa-list-check"></i> {isShow ? 'Hide' : 'Show'} Filter</span>
            <Collapse in={isShow}>
                <Form onSubmit={handleFilter}>
                    <Card className='mt-2'>
                        <Card.Body>
                            <Row>
                                <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Date From' placeholderText='Filter Date From'
                                        selected={dateFrom} onChange={setDateFrom} maxDate={dateTo || undefined} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="3" className='mb-2'>
                                    <DatePicker className='form-control' label='Date To' placeholderText='Filter Date To'
                                        selected={dateTo} onChange={setDateTo} minDate={dateFrom || undefined} dateFormat="dd.MM.yyyy"
                                    />
                                </Col>
                                <Col lg="3" className="mb-2">
                                    <EmployeeSelect required className="mb-2" value={employeeId} label="Employee" placeholder="Employee" onChange={(option) => setEmployeeId(option.value)} includeAll={true} />
                                </Col>
                                <Col lg="3" className="mb-2">
                                    <FormInput as="select" label="Rate Type" placeholder="Select Rate Type"
                                        value={rateType} onChange={(e) => setRateType(e.target.value)}>
                                        <option value="">All Rate Type</option>
                                        <option value="HOURLY">Hourly</option>
                                        <option value="DAILY">Daily</option>
                                        <option value="FIXED_MONTHLY">Fixed-Monthly</option>
                                    </FormInput>
                                </Col>
                            </Row>
                            <div className='mt-3 d-flex justify-content-end'>
                                <Button variant="outline-secondary" className='me-2' onClick={handleReset}>Reset</Button>
                                <Button type='submit' disabled={isFiltering} >{isFiltering ? 'Filtering...' : "Filter"}</Button>
                            </div>
                        </Card.Body>
                    </Card>

                </Form>
            </Collapse>
        </>

    )
}

export default PayrollsFilter