import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormSelect, Modal, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FormInput from '../../components/forms/formInput';
import OverlayLoader from '../../components/overlayLoader';
import ServiceCategorySelect from '../../components/selects/serviceCategorySelect';
import { createService, getServicesById, updateService } from '../../services/services';
import { trimProps } from '../../utils/helper';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';

const initService = {
    name: '',
    unit: '',
    pricePerUnit: 0,
    serviceCategoryId: '',
    disabled: false
}

const SetServiceModal = ({
    display,
    toggle,
    serviceId,
}) => {

    const [service, setService] = useState({})

    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(serviceId ? updateService : createService, {
        onSuccess: onMutateSuccess((response, statusCode) => {
            queryClient.invalidateQueries(['services'])
            queryClient.invalidateQueries(['jobs'])
            onHide()
        })
    })

    const { data, isFetching } = useQuery(['services', 'details', serviceId], () => serviceId && getServicesById(serviceId), {
        staleTime: 1000 * 60 * 2,
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            const _service = trimProps(result, initService, ['id'])
            setService(_service)
        })
    }, [data])

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        mutate(service)
    }

    const onShow = () => {
        !serviceId && setService(initService)
    }

    const onHide = () => {
        toggle(false)
    }

    const handleChange = (objValue) => {
        const _service = { ...service, ...objValue }
        setService(_service)
    }

    return (
        <>
            <Modal
                show={display}
                onHide={onHide}
                size="md"
                onShow={onShow}
                centered
                backdrop={isLoading ? "static" : true}
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{serviceId ? 'Edit' : 'Add'} Service</h4>

                    <hr />

                    <Form id='set-service' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col xs="12">
                                <FormInput className="form-control mb-2" label="Service Name" value={service.name ?? ''}
                                    onChange={(e) => handleChange({ name: e.target.value })} required
                                />
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-2">
                                    <Form.Label>Unit <span className="text-danger">*</span></Form.Label>
                                    <FormSelect required value={service.unit} onChange={(e) => handleChange({ unit: e.target.value })} >
                                        <option value="" disabled>Select Unit</option>
                                        <option value="m2">m2</option>
                                        <option value="kg">kg</option>
                                        <option value="piece">piece</option>
                                    </FormSelect>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <FormInput type="number" step="0.01" className="form-control mb-2" label="Price per Unit" value={service.pricePerUnit ?? ''}
                                    onChange={(e) => handleChange({ pricePerUnit: parseFloat(e.target.value) })} required
                                />
                            </Col>
                            <Col lg="12">
                                <Form.Group className="mb-2">
                                    <Form.Label>Service Category <span className="text-danger">*</span></Form.Label>
                                    <ServiceCategorySelect required value={service.serviceCategoryId ?? ''} onChange={(option) => handleChange({ serviceCategoryId: option.value })} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mt-2">
                                    <Form.Check label="Disable Service" checked={service.disabled} value={service.disabled} onChange={(e) => handleChange({ disabled: e.target.checked })} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>
                    </Form>

                    {isFetching &&
                        <OverlayLoader />
                    }

                </Modal.Body>
            </Modal>
        </>
    );
};

export default SetServiceModal;