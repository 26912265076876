import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import FormInput from '../../../components/forms/formInput';
import OverlayLoader from '../../../components/overlayLoader';
import { createBadge, updateBadge } from '../../../services/badges';
import { onMutateSuccess } from '../../../utils/queryResponse';

const initBadge = {
    length: 1,
}

const SetBadgeModal = ({
    display,
    toggle,
    selectedBadge,
}) => {

    const [badge, setBadge] = useState(initBadge)

    const onShow = () => {
        !selectedBadge.id ? setBadge(initBadge) : setBadge(selectedBadge)
    }

    const onHide = () => {
        toggle(false)
    }

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(selectedBadge.id ? updateBadge : createBadge, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['badges'])
            queryClient.invalidateQueries(['available-badges'])
            onHide()
        })
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        mutate(badge)
    }

    const handleChange = (objValue) => {
        const newBadge = { ...badge, ...objValue }
        setBadge(newBadge)
    }

    return (
        <>
            <Modal
                show={display}
                onHide={onHide}
                size="md"
                onShow={onShow}
                centered
                backdropClassName="level-2"
                backdrop={isLoading ? "static" : true}
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{selectedBadge.id ? 'Edit Badge' : 'Generate Badges'}</h4>

                    <hr />

                    <Form id='set-badge' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col lg="auto">
                                {selectedBadge.id ?
                                    <FormInput className="form-control mb-2" label="Badge No." type="number" value={badge.badgeNo ?? ''}
                                        onChange={(e) => handleChange({ badgeNo: parseInt(e.target.value) })} required
                                    />
                                    :
                                    <FormInput className="form-control mb-2" label="Length" type="number" value={badge.length ?? ''}
                                        onChange={(e) => handleChange({ length: parseInt(e.target.value) })} required
                                    />
                                }
                            </Col>
                            <Col>
                                <Form.Label>&nbsp;</Form.Label>
                                <div className='d-flex justify-content-end text-end'>
                                    {selectedBadge.id && <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>}
                                    {selectedBadge.id ?
                                        <Button type='submit' disabled={isLoading}>
                                            {isLoading ? 'Editing' : 'Edit'}
                                        </Button>
                                        :
                                        <Button type='submit' disabled={isLoading}>
                                            {isLoading ? 'Generating' : 'Generate'}
                                        </Button>
                                    }
                                </div>
                            </Col>
                        </Row>

                        {isLoading &&
                            <OverlayLoader />
                        }

                    </Form>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default SetBadgeModal