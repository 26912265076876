import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import OverlayLoader from '../../components/overlayLoader';
import { createServiceCategory, getServiceCategoryById, updateServiceCategory } from '../../services/services';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import FormInput from '../../components/forms/formInput';
import { useEffect } from 'react';
import { trimProps } from '../../utils/helper';

const initServiceCategory = { name: '' }

const SetServiceCategoryModal = ({
    display,
    toggle,
    serviceCategoryId,
}) => {

    const [serviceCategory, setServiceCategory] = useState(initServiceCategory)

    const onShow = () => {
        !serviceCategoryId && setServiceCategory(initServiceCategory)
    }

    const onHide = () => {
        toggle(false)
    }

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(serviceCategoryId ? updateServiceCategory : createServiceCategory, {
        onSuccess: onMutateSuccess(() => {
            queryClient.invalidateQueries(['service-categories'])
            onHide()
        })
    })

    const { data, isFetching } = useQuery(['service-categories','details', serviceCategoryId], () => serviceCategoryId && getServiceCategoryById(serviceCategoryId), {
        staleTime: 1000 * 60 * 2,
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            const _serviceCategory = trimProps(result, initServiceCategory, ['id'])
            setServiceCategory(_serviceCategory)
        })
    }, [data])

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        mutate(serviceCategory)
    }

    const handleChange = (objValue) => {
        const newServiceCategory = { ...serviceCategory, ...objValue }
        setServiceCategory(newServiceCategory)
    }

    return (
        <>
            <Modal
                show={display}
                onHide={onHide}
                size="md"
                onShow={onShow}
                centered
                backdropClassName="level-2"
                backdrop={isLoading ? "static" : true}
            >

                <Modal.Body className='pb-1' >
                    <h4 className="text-center mt-3">{serviceCategoryId ? 'Edit' : 'Add'} Service Category</h4>

                    <hr />

                    <Form id='set-service-category' className="mb-4" onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <Row className="mb-3">
                            <Col>
                                <FormInput className="form-control mb-2" label="Category Name" value={serviceCategory.name ?? ''}
                                    onChange={(e) => handleChange({ name: e.target.value })} required
                                />
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            <Button disabled={isLoading} className="me-2" variant="light" onClick={onHide}>Cancel</Button>
                            <Button type='submit' disabled={isLoading}>
                                {isLoading ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>

                        {isFetching &&
                            <OverlayLoader />
                        }

                    </Form>

                </Modal.Body>

            </Modal>

        </>
    );
};

export default SetServiceCategoryModal;