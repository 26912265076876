import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const ConfirmationModal = ({ 
    display, 
    toggle, 
    title = "Confirmation", 
    message = "Are you sure you want to do this?", 
    confirmAction,
    confirmText = 'Yes',
    cancelText = 'No',
    size,
    }) => {

    const handleConfirmAction = () => {
        confirmAction()
        toggle(false)
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        backdropClassName="level-2"
        centered
        size={size}
        >
            <Modal.Body>
                <h4 className="text-center">{title}</h4>
                <p className="text-center mb-0">{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleConfirmAction()}>{confirmText}</Button>
                <Button variant="light" onClick={() => toggle(false)}>{cancelText}</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default ConfirmationModal