import { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { onMutateSuccess } from '../utils/queryResponse';

const ImportSheet = ({ setData, importAction }) => {

    const [file, setFile] = useState()

    const fileInputRef = useRef()

    const { mutate, isLoading: isImporting } = useMutation(importAction, {
        onSuccess: onMutateSuccess((result) => {
         
            const _data = result.map(x => {
                let _date = new Date(x.date)
                return { ...x, date: _date }
            })
            setData([..._data])
        })
    })

    const handleValidateFile = () => {
        const fileExt = file.name.split('.').pop()
        if (!(fileExt == "csv" || fileExt == "xlsx" || fileExt == "xlsm" || fileExt == "xlsb")) {
            toast.error(`Invalid file type: Must be CSV or Excel format`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }
        mutate(file)
    }

    return (

        <Row>
            <Col lg="6">

                <Row className="mb-4">
                    <Col>
                        <div className="d-flex">
                            <Form.Group className="me-2">
                                <input className="form-control" ref={fileInputRef} accept=".csv,.xlsx,.xlsm,.xlsb" type="file" onChange={(e) => setFile(e.target.files[0])} />
                            </Form.Group>
                            <Button disabled={isImporting || !file} onClick={() => handleValidateFile()} >
                                {isImporting ? 'Importing' : 'Import'}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}

export default ImportSheet