import http from "./http"
import { getToken } from './auth'
import { format } from 'date-fns'

const base = 'cash-flow'

export async function getCashFlowAllTimeTotals(projectId) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        params: {
            projectId
        }
    }

    const { data } = await http.get(base + '/all-time-totals', config)
    return data
}

export async function getCashflowSummary() {

    const { data } = await http.get(`${base}/summary`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getCashFlow(period, startDate, endDate) {

    const params = new URLSearchParams()
    params.append('timeZoneOffset', new Date().getTimezoneOffset())
    params.append('startDate', startDate && startDate.toISOString())
    params.append('endDate', endDate && endDate.toISOString())
   
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        params
    }

    const { data } = await http.get(`${base}/${period}`, config)
    return data
}