export const msToHHMM = ms => {
    const minutes = ms > 0 ? Math.floor((ms / 1000 / 60) % 60) : 0
    const hours = ms > 0 ? Math.floor((ms / 1000 / 60 / 60) % 24) : 0
    const days = ms > 0 ? Math.floor(ms / (1000 * 60 * 60 * 24)) : 0

    const hhmm = [
        hours.toString().padStart(2, "0"),
        minutes.toString().padStart(2, "0"),
    ].join(":")

    return `${days}D ${hhmm}`
}