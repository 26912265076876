import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getUsersAsOptions } from '../../services/companyUsers';
import { onQuerySuccess } from '../../utils/queryResponse';
import FormSelect from '../forms/formSelect';
import { filterUsers } from '../../utils/userHelper';

const usersMap = new Map()

const AssigneeSelect = ({ value, isMulti, assigneesOptions, filterRole, isLoading, ...rest  }) => {

    let filtered = filterUsers(assigneesOptions, filterRole) ?? []
    // const [options, setOptions] = useState()

    // const { data, isLoading } = useQuery(['users-option'], () => assigneesOptions ? {} : getUsersAsOptions())

    // useEffect(() => {
    //     onQuerySuccess(data, (result) => {
    //         if (result.length > 0) {
    //             setOptions(
    //                 result.map(d => {
    //                     usersMap.set(d.id, d.fullName)
    //                     return { value: d.id, label: d.fullName }
    //                 })
    //             )
                
    //         }
    //     })
    // }, [data])

    return (
        <FormSelect isMulti={isMulti} {...rest}
            value={
                isMulti ? value?.map(v => ({value: v, label: usersMap.get(v)})) :
                    (filtered.find(opt => opt.value === value) || null)
            }
            options={filtered}
            isLoading={isLoading}
            isClearable
        />
    )
}

export default AssigneeSelect