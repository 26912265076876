import http from "./http"
import { getToken } from './auth'

const base = 'calendar'

export const getCalendarData = async (month, year) => {

    const params = new URLSearchParams()
    params.append('timeZoneOffset', new Date().getTimezoneOffset())
    month  && params.append('month', month)
    year  && params.append('year', year)

    const { data } = await http.get(`${base}/monthly`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}