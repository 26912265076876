import React, { useEffect, useState } from 'react';
import { deleteServiceCategory, getServiceCategories } from '../../services/services';
import { onMutateSuccess, onQuerySuccess } from '../../utils/queryResponse';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Row, Col, Card, Button } from 'react-bootstrap';
import OverlayLoader from '../../components/overlayLoader';
import SortableTable from 'reactstrap-sortable-table';
import SetServiceCategoryModal from './serviceCategoriesSet';
import { toLocalDateString } from '../../utils/helper';
import ConfirmationModal from '../../components/modals/confirmation';

const columns = ['name', 'createdAt']

const ServiceCategoriesPage = () => {

    const [serviceCategoriesModal, setServiceCategoriesModal] = useState()
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [categories, setCategories] = useState([])
    const [serviceCategoryToEdit, setServiceCategoryToEdit] = useState()
    const [serviceCategoryToDelete, setServiceCategoryToDelete] = useState()

    const { data, isFetching } = useQuery('service-categories', getServiceCategories)

    const queryClient = useQueryClient()
    const { mutate, isLoading: isDeleting } = useMutation(deleteServiceCategory, {
        onSuccess: onMutateSuccess((result, id) => {
            queryClient.invalidateQueries(['service-categories'])
        }, ' Successfully deleted a service category ')
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setCategories(result)
        })
    }, [data])

    const handleShowSetServiceCategoryModal = (serviceCategory) => {
        setServiceCategoryToEdit(serviceCategory)
        setServiceCategoriesModal(true)
    }

    const handleShowConfirmation = (serviceCategory) => {
        setServiceCategoryToDelete(serviceCategory)
        setConfirmationModal(true)
    }

    const handleDeleteServiceCategory = () => {
        mutate(serviceCategoryToDelete.id)
    }

    return (
        <>
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Service Category'
                message="Deleting this service category will also delete all the services and job items connected to it. Please make sure to update any necessary information before proceeding. Are you sure you want to delete this service category?" 
                confirmAction={handleDeleteServiceCategory}
            />
            <SetServiceCategoryModal display={serviceCategoriesModal} toggle={setServiceCategoriesModal} serviceCategoryId={serviceCategoryToEdit?.id} />

            <Row>
                <Col>
                    <Card className="mt-3 mb-4">
                        <Card.Body>
                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Service Categories</h4>
                                </Col>
                                <Col className="text-end">
                                    <div>
                                        <Button onClick={() => handleShowSetServiceCategoryModal()}><i className="fa-solid fa-plus"></i> Add</Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', minHeight: '100px' }}>
                                        <SortableTable
                                            style={{ borderTop: '2px solid var(--color-secondary)' }}
                                            responsive
                                            size="sm"
                                            data={categories}
                                            setData={setCategories}
                                            columns={columns}
                                            firstColumnRender={(serviceCategory) => <>
                                                <i className="fa-solid fa-pencil fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleShowSetServiceCategoryModal(serviceCategory)}></i>
                                                <i className="fa-solid fa-trash fa-sm" style={{ cursor: 'pointer' }} onClick={() => handleShowConfirmation(serviceCategory)}></i>
                                            </>}
                                            columnRender={[
                                                { column: 'createdAt', render: (value) => toLocalDateString(new Date(value)) },
                                            ]}
                                            addProps={{ 
                                                tHeading: { className: 'py-2 text-muted', style: { whiteSpace: 'nowrap' } }, 
                                                tData :{ className: 'py-2'} 
                                            }}
                                        />

                                        {(isFetching) &&
                                            <OverlayLoader />
                                        }

                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </>
    );
};

export default ServiceCategoriesPage;