import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { onMutateSuccess, onQuerySuccess } from '../../../utils/queryResponse';
import { deleteBadge, getBadges } from '../../../services/badges';
import OverlayLoader from '../../../components/overlayLoader';
import SetBadgeModal from './badgeSet';
import ConfirmationModal from '../../../components/modals/confirmation';

const BadgesPage = () => {

    const [setBadgeModal, setSetBadgeModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [badges, setBadges] = useState([])
    const [selectedBadge, setSelectedBadge] = useState()

    const { data, isFetching } = useQuery('badges', getBadges)

    const queryClient = useQueryClient()
    const { mutate, isLoading: isDeleting } = useMutation(deleteBadge, {
        onSuccess: onMutateSuccess((result) => {
            queryClient.invalidateQueries(['badges'])
            queryClient.invalidateQueries(['available-badges'])
        })
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setBadges(result)
        })
    }, [data])

    const handleSetBadge = (badge) => {
        setSelectedBadge(badge)
        setSetBadgeModal(true)
    }

    const handleConfirmation = (badge) => {
        setSelectedBadge(badge)
        setConfirmationModal(true)
    }

    const handleDeleteBadge = () => {
        mutate(selectedBadge.id)
        setSelectedBadge()
    }

    return (
        <>
            <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Badge'
                message="Are you sure you want to delete this badge?" confirmAction={handleDeleteBadge} />
            <SetBadgeModal display={setBadgeModal} toggle={setSetBadgeModal} selectedBadge={selectedBadge ?? ''} />

            <Row>
                <Col>

                    <Card className="mt-3 mb-4">
                        <Card.Body>

                            <Row className="pb-3">
                                <Col className="my-auto">
                                    <h4 className="page-title mb-0">Badges</h4>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button onClick={() => handleSetBadge()}><i className="fa-solid fa-plus"></i> Add</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="p-2 border rounded" style={{ position: 'relative' }}>
                                        {badges.length > 0 ?
                                            <>
                                                {badges.map((badge, index) => (
                                                    <h6 className="d-inline" key={`badge-${index}`}>
                                                        <Badge className="ps-4 pe-3 me-1" variant="primary">
                                                            <span onClick={() => handleSetBadge(badge)} className="cursor-pointer me-3">badge #{badge.badgeNo}</span>
                                                            <span onClick={() => handleConfirmation(badge)} className="cursor-pointer"><i className="fas fa-times text-white"></i></span>
                                                        </Badge>
                                                    </h6>
                                                ))}
                                            </>
                                            :
                                            <p className="mb-0"><i>No badges found.</i></p>
                                        }

                                        {(isFetching || isDeleting) &&
                                            <OverlayLoader />
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default BadgesPage;