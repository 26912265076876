import http from './http'
import { getToken } from './auth'

const base = 'notifications'

export async function getPaginatedNotifications(pageNumber, pageSize, startDate, endDate) {

    const { data } = await http.get(`${base}/paginated`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params: {
            pageNumber,
            pageSize,
            startDate: startDate || undefined,
            endDate: endDate || undefined
        }
    })
    return data
}

export async function getLatestNotifications(count) {

    const { data } = await http.get(`${base}/latest`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params: {
            count
        }
    })
    return data
}

export async function getUnreadNotificationsCount() {

    const { data } = await http.get(`${base}/unread-count`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function updateNotification(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}

export async function markAllNotificationsAsRead() {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/all-read', {}, config)
    return data
}

export async function deleteNotification(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}