import React, { Fragment } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { isUserAllowed } from '../utils/roleHelpers';

const RouteContext = ({ children }) => {

    const location = useLocation()

    return (
        isUserAllowed(location.pathname) ? <Fragment>{children}</Fragment> : <Navigate to="/jobs" />
    )
}

export default RouteContext