import { Card, Col, Row, Table } from "react-bootstrap";
import { getJobsPerformance } from "../../../services/jobs";
import { onQuerySuccess } from "../../../utils/queryResponse";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useState } from "react";
import JobsPerformanceFilter from "./jobsPerformanceFilter";
import { msToHHMM } from "../../../utils/timeHelpers";
import { toLocalDateString } from "../../../utils/helper";
import OverlayLoader from "../../../components/overlayLoader";
import SetTempEditOrderModal from "../../../components/modals/orderEditTemp";

const JobsPerformancePage = () => {

    const [editJobModal, setEditJobModal] = useState(false)

    const [jobPerformanceData, setJobPerformanceData] = useState()
    const [selectedJobId, setSelectedJobId] = useState('')

    const [filters, setFilters] = useState(['', ''])

    const { data, isFetching } = useQuery(
        ['jobs-performance', ...filters],
        () => getJobsPerformance(...filters)
    )

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setJobPerformanceData(result)
        })

    }, [data])

    const handleEditJob = (job) => {
        setSelectedJobId(job.id)
        setEditJobModal(true)
    }

    return (
        <>
            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={selectedJobId} />

            <Row>
                <Col>
                    <JobsPerformanceFilter onFilter={setFilters} />

                    <Row className="pb-3 mt-3">
                        <Col className="my-auto">
                            <h4 className="page-title mb-0">Jobs Performance</h4>
                        </Col>
                    </Row>

                    <Row className="row-eq-height">
                        <Col lg="3" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <div>
                                        <p style={{ fontSize: "1rem" }} className="text-muted mb-0">Completed On Time</p>
                                        <h3 className="color-primary">{Math.round(jobPerformanceData?.completedOnTimePercentage) || 0}%</h3>
                                    </div>
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <div>
                                        <p style={{ fontSize: "1rem" }} className="text-muted mb-0">Minimum Duration</p>
                                        <h3 className="color-primary">{msToHHMM(jobPerformanceData?.minDuration)}</h3>
                                    </div>
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <div>
                                        <p style={{ fontSize: "1rem" }} className="text-muted mb-0">Average Duration</p>
                                        <h3 className="color-primary">{msToHHMM(jobPerformanceData?.avgDuration)}</h3>
                                    </div>
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <div>
                                        <p style={{ fontSize: "1rem" }} className="text-muted mb-0">Maximum Duration</p>
                                        <h3 className="color-primary">{msToHHMM(jobPerformanceData?.maxDuration)}</h3>
                                    </div>
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="mb-3">
                            <Card>
                                <Card.Body>
                                    <h5 className="text-muted">Top 5 Longest Jobs</h5>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Job #</th>
                                                <th>Client</th>
                                                <th>Duration</th>
                                                <th>Completed On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {jobPerformanceData?.topMoreTimeDuration.length > 0 && jobPerformanceData?.topMoreTimeDuration.map((x, index) => (
                                                <tr key={`top-more-duration-${index}`}>
                                                    <th><i className="fa-solid fa-eye fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleEditJob(x.job)}></i></th>
                                                    <td>Job #{x.job.id}</td>
                                                    <td>{x.job.client.firstName} {x.job.client.lastName}</td>
                                                    <td>{msToHHMM(x.actualDuration)}</td>
                                                    <td>{toLocalDateString(x.job.completionTimeActual)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6" className="mb-3">
                            <Card>
                                <Card.Body>
                                    <h5 className="text-muted">Top 5 Shortest Jobs</h5>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Job #</th>
                                                <th>Client</th>
                                                <th>Duration</th>
                                                <th>Completed On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {jobPerformanceData?.topLessTimeDuration.length > 0 && jobPerformanceData?.topLessTimeDuration.map((x, index) => (
                                                <tr key={`top-less-duration-${index}`}>
                                                    <th><i className="fa-solid fa-eye fa-sm me-1" style={{ cursor: 'pointer' }} onClick={() => handleEditJob(x.job)}></i></th>
                                                    <td>Job #{x.job.id}</td>
                                                    <td>{x.job.client.firstName} {x.job.client.lastName}</td>
                                                    <td>{msToHHMM(x.actualDuration)}</td>
                                                    <td>{toLocalDateString(x.job.completionTimeActual)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {isFetching &&
                                        <OverlayLoader />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    );
};

export default JobsPerformancePage;