import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SortableTable from "reactstrap-sortable-table";
import ConfirmationModal from "../../components/modals/confirmation";
import OverlayLoader from "../../components/overlayLoader";
import { deleteExpenseType, getExpenseTypes } from "../../services/expenseTypes";
import { initColumns } from "../../utils/helper";
import { onMutateSuccess, onQuerySuccess } from "../../utils/queryResponse";
import SetExpenseTypeModal from "./expenseTypeSet";

const ExpenseTypesPage = () => {

    const [types, setTypes] = useState([])
    const [expenseTypeModal, setExpenseTypeModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [selectedType, setSelectedType] = useState()

    const queryClient = useQueryClient()

    const { data, isFetching } = useQuery(['expense-types'], getExpenseTypes)

    const { mutate, isLoading: isDeleting } = useMutation(deleteExpenseType, {
        onSuccess: onMutateSuccess((result, id) => {
            setTypes( types.filter(type => type.id !== id) )
            queryClient.invalidateQueries(['expense-types'], { refetchActive: false })
        }, ' Successfully deleted an expense type')
    })

    useEffect(() => {
        onQuerySuccess(data, (result) => {
            setTypes(result)
        })
    }, [data])

    const handleSetExpenseTypesModal = (type) => {
        setSelectedType(type)
        setExpenseTypeModal(true)
    }

    const handleShowConfirmationModal = (type) => {
        setSelectedType(type)
        setConfirmationModal(true)
    }

    return (
        <>
        <ConfirmationModal display={confirmationModal} toggle={setConfirmationModal} title='Delete Expense' 
                message="Are you sure you want to delete expense?" confirmAction={() => mutate(selectedType?.id)}
        />
        <SetExpenseTypeModal display={expenseTypeModal} toggle={setExpenseTypeModal} expenseType={selectedType}/>
        <Row>
            <Col>
                <h4 className="page-title d-flex align-items-center">
                    <span className="me-2">Expense Types</span>
                    <small className="action" onClick={() => handleSetExpenseTypesModal()}><i className="fa-solid fa-square-plus"></i></small>
                </h4>

                <Card className="mb-4">
                    <Card.Body>

                        <Row>
                            <Col>
                                <div style={{ position: 'relative', minHeight: '100px' }}>
                                    <SortableTable
                                        responsive
                                        size="sm"
                                        data={types}
                                        setData={setTypes}
                                        columns={initColumns(types, ['id'])}
                                        firstColumnRender={(type) => <>
                                            <Button variant="outline-primary" size="sm" className="d-block p-0 mb-1" style={{ width: '70px' }}
                                                    onClick={() => handleSetExpenseTypesModal(type)}
                                            >Edit</Button>

                                            <Button variant="outline-primary" size="sm" className="d-block p-0" style={{ width: '70px' }}
                                                onClick={() => handleShowConfirmationModal(type)}
                                            >Delete</Button>
                                        </>}
                                        addProps={{ tBodyRow: { className: 'align-middle' } }}
                                    />

                                    { types.length === 0 &&
                                        <span className="fst-italic">no expense types found</span>
                                    }

                                    {(isFetching || isDeleting) &&
                                        <OverlayLoader />
                                    }

                                </div>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>
    );
};

export default ExpenseTypesPage;