import React, { useEffect, useState } from 'react';
import { Card, Col, Form, FormSelect, Row } from "react-bootstrap";
import { useQuery } from 'react-query';
import FormDatePicker from '../../components/forms/formDatePicker';
import { getCashFlow, getCashFlowAllTimeTotals, getMonthlyCashFlow } from '../../services/cashflow';
import { onQuerySuccess } from '../../utils/queryResponse';
import CashFlowCharts from './cashflowCharts';
import CashFlowTable from './cashflowTable';
import { subYears, subMonths } from 'date-fns';

const CashFlowPage = () => {

    const [projectId, setProjectId] = useState('')
    const [chartData, setChartData] = useState()
    const [tableData, setTableData] = useState([])
    const [period, setPeriod] = useState('Monthly')
    const [startDate, setStartDate] = useState(subYears(new Date(), 1))
    const [endDate, setEndDate] = useState(new Date())

    // const { data: totalsData, isLoading: totalsLoading } = useQuery(
    //     ['cash-flow', 'all-time-totals', projectId],
    //     () => getCashFlowAllTimeTotals(projectId),
    //     { staleTime: 0 }
    // )
    // const allTimeTotals = totalsData?.result

    const { data: periodData, isLoading: periodLoading } = useQuery(
        ['cash-flow', period, startDate, endDate],
        () => getCashFlow(period, startDate, endDate),
        { staleTime: 0 }
    )

    const handlePeriodSelect = (e) => {
        const value = e.target.value
        let _startDate = null
        if(value === "Yearly") 
            _startDate = subYears(new Date(), 6)
        else if (value === "Weekly")
            _startDate = subMonths(new Date(), 4)
        else 
            _startDate =   subYears(new Date(), 1)
            
        setStartDate(_startDate)
        setEndDate(new Date())
        setPeriod(value)
    }

    useEffect(() => {
        onQuerySuccess(periodData, (result) => {
            setChartData(result.chart)
            setTableData(result.tabular)
        })
    }, [periodData])

    return (
        <Row>
            <Col>
                <h4 className="page-title d-flex align-items-center mb-3">
                    <span className="me-2">Cash Flow</span>
                </h4>

                <Row className='mt-2 mb-3'>
                    <Col lg='4'>
                        <Form.Group>
                            <Form.Label>Period</Form.Label>
                            <FormSelect value={period} onChange={handlePeriodSelect}>
                                <option>Weekly</option>
                                <option>Monthly</option>
                                <option>Yearly</option>
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col lg='4'>
                        <FormDatePicker
                            className='form-control'
                            label='Date From'
                            placeholderText='dd.MM.yyyy'
                            selected={startDate}
                            onChange={setStartDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            isClearable
                        />
                    </Col>
                    <Col lg='4'>
                        <FormDatePicker
                            className='form-control'
                            label='Date To'
                            placeholderText='dd.MM.yyyy'
                            selected={endDate}
                            onChange={setEndDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            isClearable
                        />
                    </Col>
                </Row>

                <Card className='mb-3'>
                    <Card.Body>
                        <div className='mb-3'>
                            <CashFlowCharts data={chartData} titleText={`${period} Chart`} isLoading={periodLoading} />
                        </div>
                    </Card.Body>
                </Card>

                <Card className='mb-3'>
                    <Card.Body>
                        <CashFlowTable data={tableData} titleText={`${period} Data`} isLoading={periodLoading} />
                    </Card.Body>
                </Card>

            </Col>
        </Row>
    );
};

export default CashFlowPage;