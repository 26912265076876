import http from './http'
import { getToken } from './auth'

const base = 'services'

export async function getServices() {

    const { data } = await http.get(`${base}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getServicesById(id) {
    const { data } = await http.get(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createService(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.post(`${base}`, payload, config)
    return data
}

export async function updateService(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/' + payload.id, payload, config)
    return data
}

export async function deleteService(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/' + id, config)
    return data
}

export async function getServicesReports(startDate, endDate) {

    const params = new URLSearchParams()

    startDate  && params.append('startDate', startDate.toISOString())
    endDate  && params.append('endDate', endDate.toISOString())

    const { data } = await http.get(`${base}/report`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}


export async function getServiceCategories() {

    const { data } = await http.get(`${base}/categories`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function getServiceCategoryById(id) {
    const { data } = await http.get(`${base}/categories/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    })
    return data
}

export async function createServiceCategory(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.post(`${base}/categories`, payload, config)
    return data
}

export async function updateServiceCategory(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.patch(base + '/categories/' + payload.id, payload, config)
    return data
}

export async function deleteServiceCategory(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        }
    }

    const { data } = await http.delete(base + '/categories/' + id, config)
    return data
}