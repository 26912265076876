import http from "./http"
import { getToken } from './auth'

const base = 'reminders'

export const getRemindersMonthly = async (month, year) => {
    const params = new URLSearchParams()
    params.append('timeZoneOffset', new Date().getTimezoneOffset())
    month  && params.append('month', month)
    year  && params.append('year', year)

    const { data } = await http.get(`${base}/monthly`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        params
    })
    return data
}

export const createReminder = async (payload) => {
    const { data } = await http.post(`${base}/`, payload, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}

export const updateReminder = async (payload) => {
    const { data } = await http.patch(`${base}/${payload.id}`, payload, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}

export const deleteReminder = async (id) => {
    const { data } = await http.delete(`${base}/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
    })
    return data
}