import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Select, { components } from 'react-select';
import { getJobsBySearchText } from '../../services/jobs';
import { debounce } from '../../utils/helper';
import { onQuerySuccess } from '../../utils/queryResponse';
import SetTempEditOrderModal from '../modals/orderEditTemp';

const colorStyles = {
    placeholder: defaultStyles => ({
        ...defaultStyles,
        fontSize: '1em',
    }),
    control: defaultStyles => ({
        ...defaultStyles,
        fontSize: '1.15em'
    })
}

const JobsSelect = ({ value, isMulti, onChange, ...rest }) => {

    const [editJobModal, setEditJobModal] = useState(false)

    const [searchText, setSearchText] = useState('')
    const [options, setOptions] = useState([])

    const { data, isLoading } = useQuery(['jobs-search', searchText], () => searchText && getJobsBySearchText(searchText))

    useEffect(() => {
        if (data) {
            onQuerySuccess(data, (result) => {
                if (result.length > 0) {
                    let _options = result.map((d) => ({
                        value: d.id,
                        label: `Job#${d.id}, ${d.firstName} ${d.lastName}, ${d.status} - Badge#${d.badgeNo}`,
                    }));
                    setOptions(_options);
                }
            });
        }
    }, [data, searchText]);

    const handleSearchText = debounce((text) => {
        setSearchText(text)
    })

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span>Nothing found</span>
            </components.NoOptionsMessage>
        )
    }

    const handleSelectJob = (job) => {
        setEditJobModal(true)
    }

    const Option = (props) => {
        const { label, data: { item } } = props
        return (
            <div style={{ position: 'relative' }}>

                <components.Option {...props} >
                    <div onClick={() => handleSelectJob(item)} className="cursor-pointer d-flex align-items-center">
                        <div className="px-2 pt-1">
                            <p className="mb-0">{label}</p>
                        </div>
                    </div>
                </components.Option>

            </div>
        )
    };

    return (
        <>
            <SetTempEditOrderModal display={editJobModal} toggle={setEditJobModal} jobId={value} />

            <Select
                value={value}
                options={options}
                isLoading={isLoading}
                onInputChange={handleSearchText}
                onChange={onChange}
                blurInputOnSelect
                components={{
                    DropdownIndicator: () =>
                        <div className="d-inline rounded-0 right-rounded bg-transparent px-3">
                            <i className="fa-solid fa-magnifying-glass text-muted"></i>
                        </div>,
                    IndicatorSeparator: () => null,
                    NoOptionsMessage,
                    Option
                }}
                styles={colorStyles}
                placeholder="Search Jobs by Badge or Job Number"
                required
                {...rest} />
        </>
    )
}

export default JobsSelect